import React, {useEffect, useState} from 'react';
import styles from './Header.module.css';
import userIcon from '../../common/icons/userIcon.svg';
import bell from '../../common/icons/Bell.svg';
import logout from '../../common/icons/LogoutIcon.svg';
import {IconButton, Menu, MenuItem} from "@mui/material";
import {useLocation, useNavigate} from 'react-router-dom';
import CustomModalContent from "../CustomModalChanger/CustomModal";
import {useModal} from "../../hook/useModal";
import {useDispatch, useSelector} from "react-redux";
import {setFormValidity} from "../../service/reducers/ModalCloseStatusSlice";
import axios from 'axios';
import {clearPrivileges} from "../../service/reducers/PrivelegiesSlice";
import {clearMe, fetchMeData} from "../../service/reducers/MeSlice";
import {clearUsersData} from "../../service/reducers/UsersDataSlice";
import {clearOrganization} from "../../service/reducers/organizationInfoSlice";
import {clearOkpdUserData} from "../../service/reducers/userOkpdListSlice";
import {resetOkpdCode} from "../../service/reducers/selectedComboOkpd";
import {resetActiveSettigngsTab} from "../../service/reducers/SettingsTabsSlice";
import {clearCompanyUserData} from "../../service/reducers/soloCompanySlice";
import {clearGroupsData} from "../../service/reducers/userGroupsSlice";
const CommonHeader = ({ title, children }) => {
    // eslint-disable-next-line
    const location = useLocation();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const { setIsModalVisible, setModalContent, setIsCentered} = useModal();
    const dispatch = useDispatch()
    const meData = useSelector((state) => state.meData.meData);
    const [avatarUrl, setAvatarUrl] = useState(localStorage.getItem('avatar'));
    const hasAvatar = Boolean(avatarUrl);
    const initials = meData.label ? meData.label.substring(0, 2).toUpperCase() : '';

    useEffect(() => {
        dispatch(fetchMeData())
    }, [dispatch]);

    useEffect(() => {
        if (meData && meData.avatar_url) {
            const storedAvatarUrl = localStorage.getItem('avatarUrl');
            if (storedAvatarUrl !== meData.avatar_url) {
                fetchAndStoreImage(meData.avatar_url);
            }
        }
    }, [meData]);

    useEffect(() => {
        setAvatarUrl(localStorage.getItem('avatarUrl'));
        // eslint-disable-next-line
    }, [localStorage.getItem('avatarUrl')]);

    const fetchAndStoreImage = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                localStorage.setItem('avatar', reader.result);
                localStorage.setItem('avatarUrl', imageUrl);
                setAvatarUrl(reader.result);
            };
        } catch (error) {
            console.error('Ошибка при загрузке аватарки:', error);
        }
    };
    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLk = () => {
        handleClose();
        navigate('/personal');
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleConfirmLogout = async () => {
        try {
            const response = await axios.get('https://backend.demo.agatha-tender.ru/personal-area/logout', {
                withCredentials: true
            });

            if (response.status === 200) {
                localStorage.removeItem('token');
                localStorage.removeItem('avatar');
                localStorage.removeItem('avatarUrl');
                localStorage.removeItem('dateForPickers');
                dispatch(clearPrivileges());
                dispatch(clearMe());
                dispatch(clearUsersData());
                dispatch(clearOrganization());
                dispatch(resetOkpdCode());
                dispatch(clearOkpdUserData())
                dispatch(resetActiveSettigngsTab())
                dispatch(clearGroupsData())
                dispatch(clearCompanyUserData())
                navigate('/');
                setIsCentered(false);
                setIsModalVisible(false);
            }
        } catch (error) {
            console.error('Ошибка при выходе из системы:', error);
        }
    };

    const handleOpenLogoutModal = () => {
        handleClose();
        dispatch(setFormValidity(true));
        setModalContent(
            <CustomModalContent
                title="Внимание!"
                content="Вы точно хотите выйти?"
                buttons={[
                    {
                        text: "Да",
                        onClick: handleConfirmLogout,
                    },
                    {
                        text: "Отмена",
                        onClick: () => setIsModalVisible(false),
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
        setIsCentered(true);
        setIsModalVisible(true);
    };


    return (
        <div className={styles.header}>
            <div className={styles.headerLeft}>
                <div className={styles.title}>{title}</div>
                {children}
            </div>
            <div className={styles.headerRight}>
               {/* {isDashboard && <SearchComponent/>}*/}
                <IconButton
                    onClick={handleMenu}
                    style={{
                        width: '40px',
                        height: '40px',
                        marginRight: '25px',
                        display: 'flex',
                        padding: 0,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {hasAvatar ? (
                        <img src={avatarUrl}
                             alt="Иконка профиля"
                             style={{
                                 maxWidth: '100%',
                                 maxHeight: '100%'
                             }}/>
                    ) : (
                        <div>
                            {initials}
                        </div>
                    )}
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    disableScrollLock={true}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        sx: {
                            bgcolor: '#F7FAFC',
                            borderRadius: '6px',
                            '& .MuiMenuItem-root': {
                                fontFamily: 'Golos Regular',
                                color: '#8D96B2',
                                fontSize: '14px',
                                '&:hover': {
                                    bgcolor: '#B2CEFF',
                                }
                            },
                            '&:focus': {
                                borderColor: '#4A3DFD',
                            },
                        }
                    }}
                >
                    <MenuItem onClick={handleLk}>
                        <div className={styles.menuItemContainer}>
                            <img src={userIcon} alt='useIcon'/>
                            Личный кабинет
                        </div>
                    </MenuItem>
                    <MenuItem onClick={handleLk}>
                        <div className={styles.menuItemContainer}>
                            <img src={bell} alt='bellIcon'/>
                            Уведомления
                        </div>
                    </MenuItem>
                    <MenuItem onClick={handleOpenLogoutModal}>
                        <div className={styles.menuItemContainer}>
                            <img src={logout} alt='logoutIcon'/>
                            Выйти
                        </div>
                    </MenuItem>
                </Menu>
            </div>
        </div>
    );
};

export default CommonHeader;
