import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    okpdComboData: '',
};

const okpdComboSelect = createSlice({
    name: 'okpdComboSelect',
    initialState,
    reducers: {
        setOkpdCode: (state, action) => {
            state.okpdComboData = action.payload;
        },
        resetOkpdCode: (state, action) => {
            state.okpdComboData = '';
        },
    }
});

export const { setOkpdCode, resetOkpdCode } = okpdComboSelect.actions;

export default okpdComboSelect.reducer;
