import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    currentSegmentName: [],
    zoomableName: [],
    resetZoomable: '',
};

const segmentNameSlice = createSlice({
    name: 'segmentName',
    initialState,
    reducers: {
        addResetZoomble: (state, action) => {
            state.resetZoomable = action.payload;
        },
        addZoomableName: (state, action) => {
            state.zoomableName = [action.payload];
        },
        setCurrentSegmentName: (state, action) => {
            state.currentSegmentName = [action.payload];
        },
        resetCurrentSegmentName: (state, action) => {
            state.currentSegmentName = state.currentSegmentName.filter(name => name !== action.payload);
        },
        resetSegmentNameStore: () => initialState
    },
});

export const {addResetZoomble, addZoomableName,resetSegmentNameStore, setCurrentSegmentName, resetCurrentSegmentName } = segmentNameSlice.actions;
export default segmentNameSlice.reducer;
