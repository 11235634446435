import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchOkpd} from "../../api/api";

const initialState = {
    selectedOkpd: [],
    odkpData: [],
    loading: 'idle',
    error: null,
    stackedChartShortName: [],
};

export const fetchIspOkpdData = createAsyncThunk(
    'ispOkpd/fetchIspOkpdData',
    async (options) => {
        const response = await fetchOkpd(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null || response.data.result.nodes === null) {
            return [];
        }
        return response.data.result;
    }
);

const ispOkpdChartSlice = createSlice({
    name: 'ispOkpd',
    initialState,
    reducers: {
        addStackedShortName: (state, action) => {
            state.stackedChartShortName = [action.payload];
        },
        setSelectedProductStackedShort: (state, action) => {
            const productName = action.payload;
            if (state.selectedOkpd.includes(productName)) {
                state.selectedOkpd = state.selectedOkpd.filter(name => name !== productName);
            } else {
                state.selectedOkpd.push(productName);
            }
        },
        clearSelectedProduct: (state) => {
            state.selectedOkpd = [];
        },
        resetStackedShort: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIspOkpdData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchIspOkpdData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.odkpData = action.payload;
                }
            })
            .addCase(fetchIspOkpdData.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.error.message;
            })
    }
});


export const {addStackedShortName,resetStackedShort, setSelectedProductStackedShort, clearSelectedProduct, resetStore } = ispOkpdChartSlice.actions;
export default ispOkpdChartSlice.reducer;
