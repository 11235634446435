import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchGeo} from "../../../api/api";

const initialState = {
    GeoData: [],
    loading: 'idle',
    error: null,
};

export const fetchGeoData = createAsyncThunk(
    'geo/fetchGeoData',
    async (options) => {
        options.newOKPDCode = options.selectedProduct.map(item => item.productId);
        const response = await fetchGeo(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const geoChartSlice = createSlice({
    name: 'geoChart',
    initialState,
    reducers: {
        resetGeoData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeoData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchGeoData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.GeoData = action.payload;
            })
            .addCase(fetchGeoData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});
export const { resetGeoData } = geoChartSlice.actions;
export default geoChartSlice.reducer;
