import React, {useEffect, useState} from 'react';
import styles from './SearchCard.module.css';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import {Participants} from "../../pages/SearchPage/SearchResult/PossibleParticipiants/Partisipiants";
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import {useModal} from "../../hook/useModal";
import {PurchaseAllInfo} from "../../pages/SearchPage/AllPurchaseInfo/PurchaseInfoCard";
import {formatDate} from "../../utils/convertDates";
const SearchCard = ({ data }) => {
    const [liked, setLiked] = useState(false);
    const [snackbarQueue, setSnackbarQueue] = useState([]);
    const [currentSnackbar, setCurrentSnackbar] = useState(null);
    const { setIsModalVisible, setModalContent,setIsFullScreen,setIsFullScreenAll } = useModal();

    useEffect(() => {
        if (snackbarQueue.length && !currentSnackbar) {
            setCurrentSnackbar(snackbarQueue[0]);
            setSnackbarQueue(prev => prev.slice(1));
        } else if (!snackbarQueue.length && currentSnackbar) {
            setTimeout(() => setCurrentSnackbar(null), 2100);
        }
    }, [snackbarQueue, currentSnackbar]);
    const toggleLike = () => {
        setLiked(!liked);
        const message = !liked ? `${data.title} успешно добавленна в избранное` : `${data.title} успешно убрана из избранного`;
        setSnackbarQueue(prev => [...prev, message]);
    };

    const handleCloseSnackbar = () => {
        setCurrentSnackbar(null);
    };

    function TransitionRight(props) {
        return <Slide {...props} direction="left" />;
    }

    function TransitionLeft(props) {
        return <Slide {...props} direction="right" />;
    }

    const handleSearchItemClick = (id) => {
        setModalContent(
            <PurchaseAllInfo
                key={Date.now()}
                cardId={id}
                data={data}
                onClose={() => {
                    setIsModalVisible(false);
                    setIsFullScreen(false);
                    setIsFullScreenAll(false);
                }}
            />
        );
        setIsFullScreenAll(true);
        setIsFullScreen(true);
        setIsModalVisible(true);
    }

    return (
        <div className={styles.card}>
            <div className={styles.header}>
                <div>
                    <span className={styles.titleOrder}>Подача заявок до </span>
                    <span className={styles.date}>{formatDate(data.date)}</span>
                </div>
                <span>Начальная цена</span>
            </div>
            <div className={styles.titleString}>
                <h3 className={styles.title} onClick={() => handleSearchItemClick(data.id)}>{data.title}</h3>
                <span className={styles.price}>
                    {new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 2 }).format(data.price)} ₽
                </span>
            </div>
            <span className={styles.description}>{data.customer}</span>
            <div className={styles.numberSt}>
                <span className={styles.date}>{data.id}</span>
                <span className={styles.date}>{data.type}</span>
                <span className={styles.date}>{data.gov}</span>
            </div>
            <div className={styles.footer}>
                <Participants employers={data.employers} />
                <div className={styles.buttonContFooter}>
                    <button className={styles.likeButton} onClick={toggleLike}>
                        {liked ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
                    </button>
                    <button className={styles.detailsButton} onClick={() => handleSearchItemClick(data.id)}>Подробнее</button>
                </div>
            </div>
            {currentSnackbar && (
                <Snackbar
                    open={!!currentSnackbar}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbar}
                    TransitionComponent={snackbarQueue.length % 2 === 0 ? TransitionRight : TransitionLeft}
                    message={currentSnackbar}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: liked ? 'rgba(76, 175, 80, 0.7)' : 'rgba(255, 0, 0, 0.7)', // Зеленый для лайка, красный для анлайка
                            color: '#fff',
                            fontFamily: 'Golos Regular',
                        },
                    }}
                />
            )}
        </div>
    );
};

export default SearchCard;
