import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchSoloLine} from "../../../api/api";

const initialState = {
    SoloLineData: [],
    showCount: 'sum',
    loading: 'idle',
    error: null,
};

export const fetchSoloLineData = createAsyncThunk(
    'lines/fetchLineData',
    async (options) => {
        options.newOKPDCode = options.selectedProduct.map(item => item.productId);
        const response = await fetchSoloLine(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const lineChartSlice = createSlice({
    name: 'lineChart',
    initialState,
    reducers: {
        setShowCount: (state, action) => {
            state.showCount = action.payload;
        },
        resetLineChartData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSoloLineData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchSoloLineData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.SoloLineData = action.payload;
            })
            .addCase(fetchSoloLineData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});
export const { setShowCount,resetLineChartData } = lineChartSlice.actions;

export default lineChartSlice.reducer;
