import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeLegends: {},
    clickedDiagrams: []
};


const legendSlice = createSlice({
    name: 'legend',
    initialState,
    reducers: {
        setActiveLegendItem: (state, action) => {
            const { diagramId, activeItem } = action.payload;
            if (!state.activeLegends[diagramId]) {
                state.activeLegends[diagramId] = [];
            }
            const index = state.activeLegends[diagramId].indexOf(activeItem);
            if (index > -1) {
                state.activeLegends[diagramId].splice(index, 1);
            } else {
                state.activeLegends[diagramId].push(activeItem);
            }
        },
        clearActiveItemsForDiagram: (state, action) => {
            const { diagramId } = action.payload;
            state.activeLegends[diagramId] = [];
        },
        addClickedDiagram: (state, action) => {
            state.clickedDiagrams.push(action.payload);
        },
        removeActiveLegendItem: (state, action) => {
            const { diagramId, activeItem } = action.payload;
            const index = state.activeLegends[diagramId].indexOf(activeItem);
            if (index > -1) {
                state.activeLegends[diagramId].splice(index, 1);
            }
        },
        removeClickedDiagram: (state, action) => {
            const index = state.clickedDiagrams.indexOf(action.payload);
            if (index > -1) {
                state.clickedDiagrams.splice(index, 1);
            }
        },
        resetLegendClicks: () => initialState
    }
});

export const {clearActiveItemsForDiagram, resetLegendClicks, removeActiveLegendItem, setActiveLegendItem, addClickedDiagram, removeClickedDiagram } = legendSlice.actions;
export default legendSlice.reducer;
