
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    currentStep: 1,
};

const wizardSlice = createSlice({
    name: 'wizard',
    initialState,
    reducers: {
        setStep: (state, action) => {
            state.currentStep = action.payload;
        },
        resetStep: (state) => {
            state.currentStep = 1;
        },
    },
});

export const { setStep, resetStep } = wizardSlice.actions;
export default wizardSlice.reducer;
