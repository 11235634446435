import React, { createContext, useContext, useState } from 'react';

const SearchModalContext = createContext();

export const SearchModalProvider = ({ children }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isCentered, setIsCentered] = useState(false);
    const [isFullCentered, setIsFullCentered] = useState(false);
    const [isFullScreenAll, setIsFullScreenAll] = useState(false);
    return (
        <SearchModalContext.Provider value={{
            isFullScreenAll, setIsFullScreenAll, isModalVisible, setIsModalVisible, isFullCentered,setIsFullCentered, modalContent, setModalContent, isFullScreen, setIsFullScreen, isCentered, setIsCentered
        }}>
            {children}
        </SearchModalContext.Provider>
    );
};

export const useSearchModal = () => useContext(SearchModalContext);
