import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedMonth: [],
    currentActiveButton: 'month',
    selectedTimePeriods: [],
    barLineV1Name: [],
};

const barWithLineSegmentSlice = createSlice({
    name: 'barWithLineSegment',
    initialState,
    reducers: {
        addBarLineV1Name: (state, action) => {
            state.barLineV1Name = [action.payload];
        },
        toggleSelectedMonthsGroup: (state, action) => {
            const months = action.payload.months;
            const allMonthsSelected = months.every(month => state.selectedMonth.includes(month));
            const timePeriod = action.payload.timePeriod;

            if (allMonthsSelected) {
                state.selectedMonth = state.selectedMonth.filter(month => !months.includes(month));
                if (timePeriod) {
                    state.selectedTimePeriods = state.selectedTimePeriods.filter(period => period !== timePeriod);
                }
            } else {
                months.forEach(month => {
                    if (!state.selectedMonth.includes(month)) {
                        state.selectedMonth.push(month);
                    }
                });
                if (timePeriod) {
                    state.selectedTimePeriods.push(timePeriod);
                }
            }
        },
        removeSpecificMonth: (state, action) => {
            state.selectedMonth = state.selectedMonth.filter(
                month => !action.payload.includes(month)
            );
        },
        clearSelectedMonth: (state) => {
            state.selectedMonth = [];
        },
        updateSelectedTimePeriods: (state, action) => {
            state.selectedTimePeriods = action.payload;
        },
        clearSelectedPeriod: (state) => {
            state.selectedTimePeriods = [];
        },
        setCurrentActiveButton: (state, action) => {
            state.currentActiveButton = action.payload;
        },
        resetMonthStore: () => initialState
    },
});

export const {
    addBarLineV1Name,
    clearSelectedPeriod,
    updateSelectedTimePeriods,
    setCurrentActiveButton,
    removeSpecificMonth,
    clearSelectedMonth,
    toggleSelectedMonthsGroup,
    resetMonthStore
} = barWithLineSegmentSlice.actions;

export default barWithLineSegmentSlice.reducer;
