import React, { useState, useEffect } from "react";
import styles from "../../pages/DashPage/HeaderD/HeaderD.module.css";
import testGif from "../../common/gifs/test.gif";

export const IconMenu = ({ icon, activeIcon, name, active, toggleMenu }) => {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const hideTooltip = () => setTooltipVisible(false);

    useEffect(() => {
        const tooltipShown = localStorage.getItem('tooltipShown');
        const shownStatus = tooltipShown ? JSON.parse(tooltipShown)[name] : false;
        if (shownStatus) {
            setTooltipVisible(false);
        }
    }, [name]);

    const showTooltip = () => {
        const tooltipShown = localStorage.getItem('tooltipShown');
        const shownStatus = tooltipShown ? JSON.parse(tooltipShown)[name] : false;
        if (!shownStatus) {
            if (!tooltipShown) {
                localStorage.setItem('tooltipShown', JSON.stringify({ [name]: true }));
            } else {
                let tooltipShownParsed = JSON.parse(tooltipShown);
                tooltipShownParsed[name] = true;
                localStorage.setItem('tooltipShown', JSON.stringify(tooltipShownParsed));
            }
            setTooltipVisible(true);
        }
    };
    const updateCursorPosition = (event) => {
        const tooltipWidth = 500;
        const tooltipHeight = 100;
        const screenMargin = 10;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;

        let x = event.clientX + tooltipWidth + screenMargin > screenWidth
            ? event.clientX - tooltipWidth
            : event.clientX;

        let y = event.clientY + tooltipHeight + screenMargin > screenHeight
            ? event.clientY - tooltipHeight
            : event.clientY + screenMargin;

        setCursorPosition({ x, y });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (tooltipVisible) {
                hideTooltip();
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [tooltipVisible]);

    return (
        <div
            className={active ? styles.activeIconContainer : styles.iconContainer}
            onClick={(event) => toggleMenu(name, event)}
            onMouseEnter={name === 'zoom' ? showTooltip : null}
            onMouseLeave={name === 'zoom' ? hideTooltip : null}
            onMouseMove={name === 'zoom' ? updateCursorPosition : null}
        >
            <img
                src={active && activeIcon ? activeIcon : icon}
                alt={name}
                width="24"
                height="24"
            />
            {tooltipVisible && name === 'zoom' && (
                <div
                    className={styles.tooltip}
                    style={{
                        position: 'fixed',
                        left: `${cursorPosition.x}px`,
                        top: `${cursorPosition.y + 10}px`,
                        transform: 'translateX(-50%) translateY(-100%)',
                        width: '500px',
                        height: 'auto',
                        zIndex: 9999,
                        borderRadius: '1em',
                    }}
                >
                    <img src={testGif} alt="Zoom" style={{ width: '500px', height: 'auto', borderRadius: '1em'}} />
                </div>
            )}
        </div>
    );
};
