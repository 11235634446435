import React from 'react'
import Logo from '../../../../common/icoSmall.svg'
import styles from './HeaderT.module.css'
const HeaderT = () => {
    const handleLogoClick = () => {
        window.history.pushState({}, '', '/')
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    return (
        <>
            <header className={`${styles.header}`}>
                <div onClick={handleLogoClick} className={styles.logo}>
                    <img className={styles.agathaLogo} src={Logo} alt="AgathaTender" />
                    <div className={styles.headerTitle}>
                        <div className={styles.headerTitleMain}>
                            <span className={styles.agatha}>Agatha</span>
                            <span className={styles.tender}>Tender</span>
                        </div>
                        <div className={styles.headerSubtitle}>
                            Новый взгляд на привычные данные
                        </div>
                    </div>
                </div>
            </header>
        </>

    )
}

export default React.memo(HeaderT)
