import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {fetchIndicatorData} from "../../../service/reducers/IndicatorChartSlice";
import {formatNumberWithDecimal} from "../../../utils/countFunc";
import styles from "../TestMapD/GeoChart.module.css";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
import tooltipNames from "../../../utils/tooltipTitles.json";
import styleTooltip from "../TestMapD/GeoChart.module.css";

const IndicatorDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { width, height } = useResizeObserver({ ref });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { IndicatorData, loading } = useSelector((state) => state.indicator);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);

    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const top = useSelector((state) => state.activitySlice);
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const headerWithTwoButtons = {
        title: 'Индикатор исполнения контрактов',
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };

    useEffect(() => {
        const requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            filterOkpd,
            trimCode,
            selectedSegments,
            selectedMonth,
            activeTab,
            selectedOkpd,
            contractTrimCode,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            RangeDT,
            selectedCountryLine,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }

        dispatch(fetchIndicatorData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,filterOkpd,dateChanger,selectedProduct, activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedOkpd, contractTrimCode, selectedDonutSegmetsV1, selectedContractMonth, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);


    useEffect(() => {
        if (loading === 'successful' && width && height && IndicatorData && IndicatorData.nodes) {
            createIndicator(IndicatorData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, IndicatorData]);

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });
    const onMouseMove = (event, d, part) => {
        const currentValue = IndicatorData.nodes.find((node) => node.label === 'current').value;
        const plannedValue = IndicatorData.nodes.find((node) => node.label === 'planned').value;
        let textObject;

        if (part === 'fulfilled') {
            const tooltipConfig = tooltipNames.Indicator.Tabs[activeTab];
            textObject = [
                { label: tooltipConfig.label, value: `${formatCurrency(currentValue)} из ${formatCurrency(plannedValue)}` },
            ];
        } else {
            const unfulfilledValue = plannedValue - currentValue;
            textObject = [
                { label: "Не исполнено", value: formatCurrency(unfulfilledValue) }
            ];
        }

        const tooltipSize = calculateTooltipSize(textObject);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: textObject
        });
    };
    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const createIndicator = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3
            .select(ref.current)
            .append('svg')
            .attr('width', width)
            .attr('height', height);

        const planned = data.nodes.find((node) => node.label === 'planned').value;
        const current = data.nodes.find((node) => node.label === 'current').value;
        const scaleFactor = Math.min(width / 2, height / 1.3);
        const effectiveRadius = scaleFactor;
        const initialAngle = -Math.PI / 2;
        const finalAngle = (-Math.PI / 2) + (Math.PI * current / planned);

        const arcGenerator = d3.arc()
            .innerRadius(effectiveRadius * 0.8)
            .outerRadius(effectiveRadius)
            .startAngle(-Math.PI / 2)
            .endAngle(Math.PI / 2);

        const g = svg.append('g')
            .attr('transform', `translate(${width / 2},${height / 2 + scaleFactor * 0.5})`);

        g.append('path')
            .attr('d', arcGenerator())
            .attr('fill', '#EDF1F5')
            .on("mousemove", (event, d) => onMouseMove(event, d, 'unfulfilled'))
            .on("mouseout", onMouseOut);

        const path = g.append('path')
            .attr('d', arcGenerator.endAngle(initialAngle))
            .attr('fill', '#4B72FB')
            .attr('opacity', 1)
            .on("mousemove", (event, d) => onMouseMove(event, d, 'fulfilled'))
            .on("mouseout", onMouseOut);

        path.transition()
            .duration(800)
            .ease(d3.easeLinear)
            .attrTween('d', function() {
                const interpolate = d3.interpolate(initialAngle, finalAngle);
                return function(t) {
                    return arcGenerator.endAngle(interpolate(t))();
                };
            });
        g.append('text')
            .attr('x', 0)
            .attr('y', -scaleFactor * 0.2)
            .attr("fill", "var(--text-color, #FFF)")
            .attr('text-anchor', 'middle')
            .style("font-family", "Golos Regular")
            .attr('dominant-baseline', 'central')
            .attr('font-size', `${Math.min(width, height) * 0.1}px`)
            .text(formatNumberWithDecimal(current));

        g.append('text')
            .attr('x', -effectiveRadius / 1.1)
            .attr('y', scaleFactor * 0.1)
            .attr('text-anchor', 'middle')
            .style("font-family", "Golos Regular")
            .attr('dominant-baseline', 'central')
            .attr('font-size', `${Math.min(width, height) * 0.05}px`)
            .style("fill", "#8D96B2")
            .text('0');

        g.append('text')
            .attr('x', effectiveRadius / 1.2)
            .attr('y', scaleFactor * 0.1)
            .attr('text-anchor', 'middle')
            .style("font-family", "Golos Regular")
            .attr('dominant-baseline', 'central')
            .attr('font-size', `${Math.min(width, height) * 0.05}px`)
            .style("fill", "#8D96B2")
            .text(formatNumberWithDecimal(planned));
    };

    return (
        <div className={`${style.container} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <HeaderDiagram
                            {...headerWithTwoButtons}
                            onZoomClick={onZoomClick}
                        />
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styles.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}: </strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default IndicatorDiagramm;
