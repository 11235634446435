import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSettingsTab: 'Учетные Записи',
};

const settingsTabsSlice = createSlice({
    name: 'settingsTabs',
    initialState,
    reducers: {
        setActiveSettingsTab: (state, action) => {
            state.activeSettingsTab = action.payload;
        },
        resetActiveSettigngsTab: (state, action) => {
            state.activeSettingsTab =  'Учетные Записи';
        }
    }
});

export const {resetActiveSettigngsTab, setActiveSettingsTab } = settingsTabsSlice.actions;
export default settingsTabsSlice.reducer;
