import React, {useEffect} from 'react';
import style from './Tariffs.module.css';
import {fetchOrganizationData} from "../../../service/reducers/organizationInfoSlice";
import {useDispatch, useSelector} from "react-redux";

const Tariffs = () => {
    const dispatch = useDispatch();
    const orgData = useSelector((state) => state.organizationInfoData.organizationData);
    const bColoros = ['#A2C4FF','#DBC3FE', '#FFF0F1', '#F4F6F9'];
    const tariffDetails = [
        { name: 'Учетных записей', value: '17' },
        { name: 'Организаций', value: '5' },
        { name: 'Подразделений', value: '1346' },
        { name: 'ОКПД', value: '175' },
    ];
    const tariffName = orgData.tariff_name || 'Нет данных';
    const expirationDate = orgData.expiration ? new Date(orgData.expiration).toLocaleDateString() : 'Нет данных';


    useEffect(() => {
        dispatch(fetchOrganizationData())
    }, [dispatch]);

    return (
        <div className={style.container}>
            <div className={style.tariffHeader}>
                <span>Мой тариф</span>
                <div className={style.changeTariffButton}>Сменить тариф</div>
            </div>
            <div className={style.tariffName}>{tariffName}</div>
            <div className={style.expirationDate}>Срок действия до {expirationDate}</div>
            <div className={style.tariffDetails}>
                {tariffDetails.map((detail, index) => (
                    <div
                        key={detail.name}
                        className={style.detailItem}
                        style={{ backgroundColor: bColoros[index % bColoros.length] }}
                    >
                        <span className={style.detailName}>{detail.name}</span>
                        <span className={style.detailValue}>{detail.value}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tariffs;
