import React, { useRef, useState, useEffect } from 'react';
import styles from './HeaderD.module.css';
import {useDispatch, useSelector} from "react-redux";
import {toggleInsideActive} from "../../../service/reducers/StackedBarChart/ProductCodeChartSlice";
import style from "../LineBarChart/LineBarChart.module.css";
import {toggleBrushActive} from "../../../service/reducers/ManyLinesChart/ManyLineChartSlice";
import {IconMenu} from "../../../components/IconMenu/IconMenu";
import {toggleBrushActiveMonth} from "../../../service/reducers/ManyLinesMonthSlice";

const HeaderDiagram = ({ activeMode, title, icons, onZoomClick,diagramType, handleMenuItemClick,hasMoreData, loadMoreItems, hasMoreSan, loadMoreSan, hasMoreBubble, loadMoreBubble,legendTotal,dynamicValue,diagramId}) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [activeIconName, setActiveIconName] = useState(null);
    const menuRef = useRef(null);
    const dispatch = useDispatch();
    const isInsideActive = useSelector((state) => state.productCode.isInsideActive);
    const isBrushActive = useSelector((state) => state.manyLines.isBrushActive);
    const isBrushActive2 = useSelector((state) => state.manyLineChartMonthSlice.isBrushActiveMonth);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setMenuVisible(false);
            setActiveIconName(null);
        }
    };
    const handleScroll = () => {
        setMenuVisible(false);
        setActiveIconName(null);
    };

    const localHandleMenuItemClick = (mode) => {
        setMenuVisible(false);
        setActiveIconName(null);
        handleMenuItemClick(mode);
    };

    useEffect(() => {
        if (menuVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('wheel', handleScroll);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('wheel', handleScroll);
        };
    }, [menuVisible]);

    const toggleMenu = (iconName, event) => {
        if (iconName === 'zoom') {
            onZoomClick();
            return;
        }
        if (iconName === 'inside') {
            if (diagramId === 'Stacked') {
                dispatch(toggleInsideActive());
            } else if (diagramId === 'Brush') {
                dispatch(toggleBrushActive())
            }
            else if (diagramId === 'Brush2') {
                dispatch(toggleBrushActiveMonth())
            }
            return;
        }

        if (iconName === 'menu' || iconName === 'change') {
            const iconContainer = event.currentTarget;
            const { left, bottom } = iconContainer.getBoundingClientRect();
            const menuWidth = menuRef.current ? menuRef.current.offsetWidth : 200;
            setMenuPosition({ top: bottom + 5, left: left - menuWidth + iconContainer.offsetWidth });
            setMenuVisible(!menuVisible);
            setActiveIconName(menuVisible ? null : iconName);
        }
        else {
            setActiveIconName(iconName === activeIconName ? null : iconName);
        }
    };
    const renderMenuContent = () => {
        const buttonStyle = (mode) => ({
            color: activeMode === mode ? '#3564E6' : '#8D96B2'
        });

        switch (activeIconName) {
            case 'menu':
                return (
                    <div className={styles.menuItems}>
                        <div>Инфо</div>
                        <div>Скачать</div>
                    </div>
                );
            case 'change':
                if (diagramType === 'geo' || diagramType === 'treeMap' || diagramType === 'sunKey' || diagramType === 'roleIsp') {
                    return (
                        <div className={styles.menuItems}>
                            <div onClick={() => { localHandleMenuItemClick('customer?'); }} style={buttonStyle('cust')}>По Исполнителям</div>
                            <div onClick={() => { localHandleMenuItemClick('supplier?'); }} style={buttonStyle('org')}>По Заказчикам</div>
                        </div>
                    );
                } else {
                    return (
                        <div className={styles.menuItems}>
                            <div onClick={() => { localHandleMenuItemClick('sum'); }} style={buttonStyle('sum')}>От суммы</div>
                            <div onClick={() => { localHandleMenuItemClick('count'); }} style={buttonStyle('count')}>От количества</div>
                        </div>
                    );
                }
            default:
                return null;
        }
    };



    return (
        <div className={styles.headerD}>
            <div className={styles.title}>{title}</div>
            <div className={styles.icons}>
                {legendTotal && (
                    <div className={`${style.legendAddition}`}
                         >{legendTotal}</div>
                )}
                {hasMoreBubble && (
                    <div className={`${style.button}`}
                         onClick={loadMoreBubble}>+100</div>
                )}
                {hasMoreData && (
                    <div className={`${style.button}`}
                         onClick={loadMoreItems}>{`+${dynamicValue}`}</div>
                )}
                {hasMoreSan && (
                    <div className={`${style.button}`}
                         onClick={loadMoreSan}>+15</div>
                )}
                {icons.map((item, index) => (
                    <IconMenu
                        key={index}
                        icon={item.icon}
                        activeIcon={item.activeIcon}
                        name={item.name}
                        active={
                            item.name === activeIconName ||
                            (item.name === 'inside' && (
                                (diagramId === 'Stacked' && isInsideActive) ||
                                (diagramId === 'Brush' && isBrushActive) ||
                                (diagramId === 'Brush2' && isBrushActive2)
                            ))
                        }
                        toggleMenu={toggleMenu}
                    />
                ))}
                <div
                    ref={menuRef}
                    className={menuVisible ? styles.menuVisible : styles.menuHidden}
                    style={{ top: menuPosition.top, left: menuPosition.left }}
                >
                    {renderMenuContent()}
                </div>
            </div>
        </div>
    );
};

export default HeaderDiagram;
