import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import {activeColors} from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {fetchActivityData} from "../../../service/reducers/ActivityChart/ActivityChartSlice";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import styles from "../LineBarChart/LineBarChart.module.css";
import tooltipStyle from "../TestMapD/GeoChart.module.css";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
const Activity = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const tooltipRef = useRef(null);
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const { ActivityData, loading } = useSelector((state) => state.activityChart);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const activeLines = useSelector((state) => state.activitySlice.activeLines);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);

    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const top = useSelector((state) => state.activitySlice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const headerWithTwoButtons = {
        title: "Стуктура закупок по ограничениям и преимуществам",
        icons: [
            { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    };

    useEffect(() => {
            const requestData = {
                relatedINNs,
                selectedProduct,
                activeRegions,
                pieState,
                trimCode,
                selectedSegments,
                filterOkpd,
                selectedCountryLine,
                selectedMonth,
                activeTab,
                contractTrimCode,
                selectedOkpd,
                selectedDonutSegmetsV1,
                RangeDT,
                selectedContractMonth,
                ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
            };

            dispatch(fetchActivityData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterOkpd,dateChanger,selectedCountryLine,selectedProduct,activeRegions,pieState,trimCode,selectedSegments,selectedMonth,relatedINNs,contractTrimCode,selectedOkpd,selectedDonutSegmetsV1,selectedContractMonth,searchOrgINNINNs,searchSuppINNINNINNs,slidePoz]);


    useEffect(() => {
        if (loading === 'successful' && width && height && ActivityData) {
            const sortedNodes = ActivityData.nodes.map(node => ({
                ...node,
                totalCount: node.extra.reduce((sum, { value }) => sum + value, 0)
            })).sort((a, b) => b.totalCount - a.totalCount);

            const sortedData = {
                ...ActivityData,
                nodes: sortedNodes
            };

            createActivityChart(sortedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, ActivityData, activeLines]);


    const hasActiveSegments = Object.values(top).some((value) => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return false;
    });

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const onMouseMove = (event, d) => {
        const tooltipContent = [
            { label: 'Label', value: d.label },
            { label: 'Value', value: d.value},
            { label: 'Count', value: d.extra[0].value }
        ];
        const tooltipSize = calculateTooltipSize(tooltipContent);

        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipContent
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const createActivityChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height);

        const margin = { top: 20, right: 20, bottom: 20, left: 20 };
        const w = width - margin.left - margin.right;
        const h = height - margin.top - margin.bottom;

        const maxRadius = Math.min(w, h) / 2;
        const categoryCount = data.nodes.length;
        const color = d3.scaleOrdinal(activeColors);

        const g = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`);
        const MIN_PERCENTAGE = 0.1;
        const MIN_BAND_WIDTH = 1;

        data.nodes.forEach((node, index) => {
            const lineSpacing = 2;
            const numberOfLines = data.nodes.length;
            const totalSpacing = (numberOfLines - 1) * lineSpacing;
            const totalBandWidth = maxRadius - totalSpacing;
            const dynamicBandWidth = Math.max(totalBandWidth / numberOfLines, MIN_BAND_WIDTH);
            const extraCount = node.extra?.[0]?.value || 0;
            const outerRadius = maxRadius - (index * (dynamicBandWidth + lineSpacing));
            const innerRadius = outerRadius - dynamicBandWidth;
            const middleRadius = innerRadius + dynamicBandWidth / 2;
            const percentage = Math.max(node.value / data.total, MIN_PERCENTAGE);
            let fill_color = color(categoryCount - index - 1);
            const isAnyLineActive = Object.values(activeLines).some(val => val);

            if (node.value === 0) {
                fill_color = "#EDF1F5";
            }

            const arc = d3.arc()
                .innerRadius(innerRadius)
                .outerRadius(outerRadius)
                .startAngle(0)
                .endAngle(0)
                .cornerRadius(5)

            const path = g.append("path")
                .attr("d", arc())
                .attr("fill", fill_color)
                .attr("stroke", "black")
                .attr("stroke-width", "0.2")
                .data([node])
                .attr("stroke-linejoin", "round")
                .attr("opacity", isAnyLineActive ? (activeLines[node.label] ? 1 : 0.5) : 1)
                .on("mousemove", function(event, d) {
                    onMouseMove(event, d);
                })
                .on("mouseout", onMouseOut);

            const textAngle = 0;

            g.append("text")
                .attr("x", middleRadius * Math.sin(textAngle) - 2)
                .attr("y", -middleRadius * Math.cos(textAngle))
                .attr("dy", "0.35em")
                .attr("text-anchor", "end")
                .style("fill", "#000")
                .style("font-family", "Golos Regular")
                .style("font-size", `${dynamicBandWidth -8}px`)
                .text(extraCount)
                .attr("opacity", 0)
                .transition()
                .duration(1000)
                .attr("opacity", 1);

            path.transition()
                .duration(1000)
                .attrTween("d", function(d) {
                    const interpolate = d3.interpolate(0, 2 * Math.PI * percentage);
                    return function(t) {
                        arc.endAngle(interpolate(t));
                        return arc();
                    };
                });
        });
    };


    const translationMap = {
        "Requirements": "Требования",
        "Restrictions": "Ограничения",
        "Advantages": "Преимущества"
    };

    const translatedNodes = ActivityData?.nodes?.map(node => ({
        ...node,
        label: translationMap[node.label] || node.label
    })) || [];


    return (
        <div className={`${style.container} ${hasActiveSegments ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithTwoButtons}
                    onZoomClick={onZoomClick}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={"Стуктура закупок по ограничениям и преимуществам"} data={translatedNodes} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={activeColors} />
                    </div>
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
            {tooltip.text && (
                <div
                    ref={tooltipRef}
                    className={tooltipStyle.tooltip}
                    style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                >
                    {tooltip.text.map(item => (
                        <div key={item.label}>
                            <strong>{item.label}</strong>: {item.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Activity;
