import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchRespRole} from "../../../api/api";

const initialState = {
    RespRoleData: [],
    loading: 'idle',
    error: null,
    showCountRespRole: 'sum',
    roleMode: "",
};
export const fetchDonutRoleData = createAsyncThunk(
    'respRole/fetchRespRoleData',
    async (args) => {
        const newOKPDCode = args.selectedProduct.map(item => item.productId);
        const options = {...args, newOKPDCode};
        const response = await fetchRespRole(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const donutRoleChartSlice = createSlice({
    name: 'donutRoleChart',
    initialState,
    reducers: {
        setshowCountRespRole: (state, action) => {
            state.showCountRespRole = action.payload;
        },
        setRoleMode: (state, action) => {
            state.roleMode = action.payload;
        },
        resetRoleDonutData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDonutRoleData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchDonutRoleData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.RespRoleData = action.payload;
                }
            })
            .addCase(fetchDonutRoleData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const {setRoleMode, setshowCountRespRole ,resetRoleDonutData} = donutRoleChartSlice.actions;

export default donutRoleChartSlice.reducer;
