import React, {useEffect} from 'react';
import SearchCard from "../../../components/SearchCards/SearchCard";
import styles from './SearchResult.module.css'
import sortIco from '../../../common/icons/FilterAllIcon.svg'
import {useDispatch, useSelector} from "react-redux";
import {fetchSearchZData} from "../../../service/reducers/SearchZSlice";
import {CustomLoader} from "../../TestPages/LoaderComponent/CustomLoader";
const SearchResults = () => {
    const loading = useSelector(state => state.searchZSlice.searchDataloading);
    const queryParams = useSelector(state => state.searchFiltersSlice.searchParams);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchSearchZData(queryParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const searchData = useSelector(state => state.searchZSlice.searchData);
    return (
        <div className={styles.cardContainer}>
            {loading === 'pending' ? (
                <CustomLoader />
            ) : (
                <>
                    <div className={styles.headerSearch}>
                        <div className={styles.headerTitle}>
                            Результаты поиска
                        </div>
                        <div className={styles.subHeader}>
                            более 48 000 000 контрактов
                        </div>
                        <img className={styles.sortIco} src={sortIco} alt="Sort"/>
                    </div>
                    <div>
                        {searchData?.map((result, index) => (
                            <SearchCard key={index} data={result} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default SearchResults;
