import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';
/*
import logger from 'redux-logger';
*/

const authMiddleware = () => (next) => (action) => {
    if (action.type.endsWith('rejected')) {
        if (action.payload && action.payload.message === 'unauthorized') {
            localStorage.removeItem('token');
        }
    }
    return next(action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authMiddleware),
    /* middleware: (getDefaultMiddleware) =>
         process.env.REACT_APP_NODE_ENV !== 'production'
             ? getDefaultMiddleware().concat(logger)
             : getDefaultMiddleware(),*/
});
