export function formatCurrency(value) {
    const integerPart = Math.floor(value).toLocaleString();
    const cents = (value % 1).toFixed(2).slice(2);
    return `${integerPart}.${cents} руб.`;
}

export function formatShortCurrency(value) {
    const length = String(Math.floor(value)).length;

    if (length > 12) {
        return (value / 1e12).toFixed(2);
    } else if (length > 9) {
        return (value / 1e9).toFixed(2);
    } else if (length > 6) {
        return (value / 1e6).toFixed(2);
    } else if (length > 3) {
        return (value / 1e3).toFixed(2);
    } else {
        return value.toFixed(1);
    }
}


export function getCurrencyUnit(value) {
    const length = String(Math.floor(value)).length;
    if (length > 12) {
        return "трлн";
    } else if (length > 9) {
        return "млрд";
    } else if (length > 6) {
        return "млн";
    } else if (length > 3) {
        return "тыс";
    } else {
        return "";
    }
}





