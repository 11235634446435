import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import styles from "../OkpdTree/RecurseTree/Tree.module.css";
import {useDispatch} from "react-redux";
import {removeParam, setParam} from "../../service/reducers/FilterSearchSlice";

export const CustomCheckbox = styled(Checkbox)(() => ({
    color: '#D0D4DA',
    '&.Mui-checked': {
        color: '#4A3DFD',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
    },
}));

const AreaItem = ({ data }) => {
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const handleCheckboxChange = () => {
        const newIsChecked = !isChecked;
        setIsChecked(newIsChecked);
        if (newIsChecked) {
            dispatch(setParam({ key: 'areas', value: data.name }));
        } else {
            dispatch(removeParam({ key: 'areas', value: data.name }));
        }
    };

    return (
        <li className={styles.treeNode}>
            <div className={styles.treeNodeHeader}>
                <CustomCheckbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
                {data.name}
            </div>
        </li>
    );
};

export default AreaItem;
