import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from './GeoChart.module.css';
import { ReactComponent as MapSvg } from '../../../utils/GeoSvg.svg';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import {useDispatch, useSelector} from "react-redux";
import {addGeoName, setMode, toggleRegion} from "../../../service/reducers/GeoChart/GeoChartRegionSlice";
import {fetchGeoData} from "../../../service/reducers/GeoChart/GeoChartSlice";
import styleTooltip from "./GeoChart.module.css";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {formatNumberWithDecimal} from "../../../utils/countFunc";
import * as d3 from 'd3';
import {cancelAllPendingRequests} from "../../../api/api";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
import Legend from "../../../components/DiagrammLegend/Legend";
import {setActiveLegendItem} from "../../../service/reducers/legendItemsClick";
import regionData from "../../../utils/geoNamesMap.json"
import tooltipNames from "../../../utils/tooltipTitles.json"

function interpolateColor(color1, color2, factor, nonlinear = false) {
    const r1 = parseInt(color1.substring(1, 3), 16);
    const g1 = parseInt(color1.substring(3, 5), 16);
    const b1 = parseInt(color1.substring(5, 7), 16);

    const r2 = parseInt(color2.substring(1, 3), 16);
    const g2 = parseInt(color2.substring(3, 5), 16);
    const b2 = parseInt(color2.substring(5, 7), 16);

    const adjustedFactor = nonlinear ? Math.sqrt(factor) : factor;

    const r = Math.round(r1 + adjustedFactor * (r2 - r1));
    const g = Math.round(g1 + adjustedFactor * (g2 - g1));
    const b = Math.round(b1 + adjustedFactor * (b2 - b1));

    return `#${("0" + r.toString(16)).slice(-2)}${("0" + g.toString(16)).slice(-2)}${("0" + b.toString(16)).slice(-2)}`;
}

const numSegments = 89;
const colorStart = '#CDDFFE';
const colorEnd = '#4F43F9';
const gradientColors = Array.from({ length: numSegments }, (_, i) => {
    return interpolateColor(colorStart, colorEnd, i / (numSegments - 1));
});

const ColorScale = ({ min, max, heigth }) => {
    const segmentHeight = Math.floor(heigth / gradientColors.length);
    return (
        <div className={styles.colorScaleContainer}>
            <div>{formatNumberWithDecimal(max)}</div>
            {[...gradientColors].reverse().map((color, index) => (
                <div
                    key={index}
                    className={styles.colorBox}
                    style={{ backgroundColor: color, height: `${segmentHeight}px` }}
                />
            ))}
            <div
                style={{ visibility: min === max ? 'hidden' : 'visible' }}
            >
                {formatNumberWithDecimal(min)}
            </div>
        </div>
    );
};

export const GeoChart = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const [paths, setPaths] = useState(null);
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { GeoData } = useSelector((state) => state.geoSlice);
    const viewBox = useSelector((state) => state.region.viewBox);
    const min = GeoData.length > 0 ? Math.min(...GeoData.map(d => d.value)) : 0;
    const max = GeoData.length > 0 ? Math.max(...GeoData.map(d => d.value)) : 0;
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);

    const mode = useSelector((state) => state.region.mode);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const geoChartRef = useRef(null);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const shouldShowChangeButton = (selectedOrganization.type === 'okpd' || filterOkpd.length > 0) && activeTab!== 'Извещения';
    let headerTitle;
    if ((selectedOrganization.type === 'company_customer' || slidePoz === 'supplier') && activeTab !== 'Извещения') {
        headerTitle = 'Анализ географии исполнителей';
    } else if ((selectedOrganization.type === 'company_suppliers' || slidePoz === 'customer') && activeTab !== 'Извещения')  {
        headerTitle = 'Анализ географии заказчиков';
    } else if (shouldShowChangeButton && mode === '') {
        headerTitle = 'Анализ географии исполнителей';
    } else {
        switch (mode) {
            case 'cust':
                headerTitle = 'Анализ географии исполнителей';
                break;
            case 'org':
                headerTitle = 'Анализ географии заказчиков';
                break;
            default:
                if (activeTab === 'Исполнение') {
                    headerTitle = 'Анализ географии заказчиков';
                } else {
                    headerTitle = activeTab === 'Извещения'
                        ? 'Анализ географии заказчиков'
                        : 'Анализ географии исполнителей';
                }
        }
    }

    const headerWithThreeButtons = {
        title: headerTitle,
        icons: [
            ...shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : [],
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 },
        ]
    };
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const top = useSelector((state) => state.activitySlice);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const [colorBoxHeight, setColorBoxHeight] = useState(0);
    const zoomRef = useRef();
    const [cityNamesMap, setCityNamesMap] = useState({});
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedTreeMapLabels = useMemo(() => {
        return selectedSegments.map(segment => segment.label);
    }, [selectedSegments]);

    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    useEffect(() => {
        const map = regionData.reduce((acc, { ISO, region }) => {
            acc[ISO] = region;
            return acc;
        }, {});
        setCityNamesMap(map);
    }, []);

    const setupZoom = () => {
        const svg = d3.select(geoChartRef.current);
        const width = +svg.attr('width') || svg.node().getBoundingClientRect().width;
        const height = +svg.attr('height') || svg.node().getBoundingClientRect().height;

        const zoom = d3.zoom()
            .scaleExtent([1, 8])
            .translateExtent([[0, 0], [width, height * 1.5]])
            .on('zoom', (event) => {
                svg.select('g').attr('transform', event.transform);
            });

        svg.call(zoom);
        zoomRef.current = zoom;
    };

    useEffect(() => {
        setupZoom();
    }, []);

    useEffect(() => {
        const resetZoom = () => {
            const svg = d3.select(geoChartRef.current);
            svg.call(zoomRef.current.transform, d3.zoomIdentity);
        };

        resetZoom();
    }, [GeoData]);

    useEffect(() => {
        dispatch(addGeoName(headerTitle));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useEffect(() => {
        if (geoChartRef.current) {
            const containerHeight = geoChartRef.current.offsetHeight;
            const newColorBoxHeight = Math.floor(containerHeight * 0.6);
            setColorBoxHeight(newColorBoxHeight);
        }
    }, [geoChartRef]);

    useEffect(() => {
        let requestData = {
            relatedINNs,
            selectedProduct,
            pieState,
            topBody,
            trimCode,
            selectedTreeMapLabels,
            selectedMonth,
            activeTab,
            filterOkpd,
            contractTrimCode,
            selectedOkpd,
            RangeDT,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            selectedCountryLine,
            selectedKbrSegments,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        if (shouldShowChangeButton) {
            const isCustomer = mode === '' || mode === 'cust';
            requestData = { ...requestData, isCustomer };
        }

        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedTreeMapLabels = selectedTreeMapLabels;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedCustSegments = selectedTreeMapLabels;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }

        dispatch(fetchGeoData(requestData));
        if (paths) {
            paths.forEach((path) => {
                path.style.fill = "#f0f0f0";
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,selectedKbrSegments,filterOkpd,dateChanger,selectedProduct,mode, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, contractTrimCode, selectedOkpd, selectedDonutSegmetsV1, selectedContractMonth, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);

    useEffect(() => {
        if (paths) {
            paths.forEach((path) => {
                const regionCode = path.getAttribute('data-code');
                if (activeRegions.length === 0) {
                    path.style.opacity = '1';
                } else {
                    if (activeRegions.includes(regionCode)) {
                        path.style.opacity = '1';
                    } else {
                        path.style.opacity = '0.3';
                    }
                }
            });
        }
    }, [paths, activeRegions]);

    useEffect(() => {
        if (GeoData.length === 0) return;

        if (!paths) {
            const svgPaths = geoChartRef.current.querySelectorAll("path");
            setPaths(Array.from(svgPaths));
        }

        if (paths && GeoData.length > 0) {
            const logScale = d3.scaleLog()
                .domain([min + 1, max])
                .range([0, numSegments - 1]);

            paths.forEach((path) => {
                const regionCode = path.getAttribute('data-code');
                const regionData = GeoData.find((data) => data.label === regionCode);
                if (regionData) {
                    const regionValue = regionData.value;
                    const colorIndex = Math.round(logScale(regionValue));
                    const color = gradientColors[colorIndex];
                    path.style.fill = color;
                } else {
                    path.style.fill = "#f0f0f0";
                }

                path.addEventListener('mousemove', handleMouseMove);
                path.addEventListener('mouseout', handleMouseOut);
                path.addEventListener('click', handleClick);
            });

            return () => {
                paths.forEach((path) => {
                    path.removeEventListener('mousemove', handleMouseMove);
                    path.removeEventListener('mouseout', handleMouseOut);
                    path.removeEventListener('click', handleClick);
                });
            };

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [GeoData, paths, min, max]);

    const handleMouseMove = (event) => {
        let x = event.pageX + 10;
        let y = event.pageY + 10;
        const regionCode = event.target.getAttribute('data-code');
        const regionData = GeoData.find((data) => data.label === regionCode);
        const regionValue = regionData ? regionData.value : 'N/A';

        if (!regionData) {
            setTooltip({ x: 0, y: 0, text: '' });
            return;
        }
        const tooltipConfig = tooltipNames.GeoMap.Tabs[activeTab];
        setTooltip({
            x,
            y,
            text: [
                { label: tooltipConfig.region, value: event.target.getAttribute('data-title') },
                { label: tooltipConfig.value, value: formatCurrency(regionValue) },
            ]
        });
    };


    const handleMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const handleClick = (event) => {
        cancelAllPendingRequests();
        const regionCode = event.target.getAttribute('data-code');
        const regionData = GeoData.find((data) => data.label === regionCode);
        if (regionData) {
            dispatch(toggleRegion(regionCode));
            const regionName = cityNamesMap[regionCode] || regionCode;
            dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: regionName }));
            dispatch(addGeoName(headerTitle));
        }
    };

    const getColorForRegion = (value) => {
        const logScale = d3.scaleLog()
            .domain([min + 1, max])
            .range([0, numSegments - 1]);
        const colorIndex = Math.round(logScale(value));
        return gradientColors[colorIndex];
    };

    const majorCitiesDataAndColors = GeoData
        .filter(d => Object.keys(cityNamesMap).includes(d.label))
        .map(city => {
            return {
                label: cityNamesMap[city.label],
                color: getColorForRegion(city.value),
                value: city.value
            };
        })
        .sort((a, b) => b.value - a.value);

    const handleLegendItemClick = (label) => {
        const regionCode = Object.keys(cityNamesMap).find(key => cityNamesMap[key] === label);
        if (regionCode) {
            dispatch(toggleRegion(regionCode));
            dispatch(addGeoName(headerTitle));
        }
    };

    return (
        <div className={`${style.container} ${activeRegions.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: '85%' } : {}}>
            {tooltip.text && (
                <div
                    ref={tooltipRef}
                    className={styleTooltip.tooltip}
                    style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                >
                    {tooltip.text.map(item => (
                        <div key={item.label}>
                            <strong className={styleTooltip.labelName}>{item.label}: </strong>{JSON.parse(`"${(item.value)}"`)}
                        </div>
                    ))}
                </div>
            )}
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    activeMode={mode || 'cust'}
                    onZoomClick={onZoomClick}
                    handleMenuItemClick={(mode) => {
                        if (mode === 'customer?') {
                            dispatch(setMode('cust'));
                        } else if (mode === 'supplier?') {
                            dispatch(setMode('org'));
                        }
                        else {
                            dispatch(setMode(""))
                        }
                    }}
                    diagramType="geo"
                />
            </div>
            <div className={style.header}>
                <Legend
                    diagramId={headerTitle}
                    data={majorCitiesDataAndColors}
                    dynamicRadius={75}
                    activeColors={majorCitiesDataAndColors.map(city => city.color)}
                    onLegendItemClick={handleLegendItemClick}
                />
            </div>
            <div ref={geoChartRef} className={styles.mapAndScaleContainer} style={zoomedDiagram ? { height: "60vh" } : {}}>
                <MapSvg
                    className={styles['rf-map']}
                    viewBox={viewBox}
                    preserveAspectRatio="xMidYMid meet"
                    style={{ width: '90%', height: '90%' }}
                />
                <ColorScale min={min} max={max} heigth={colorBoxHeight} />
            </div>
        </div>
    );
};
