    import { createSlice } from '@reduxjs/toolkit';

    const initialState = {
        Advantages: [],
        Restrictions: [],
        Requirements: [],
        activeLines: {
            Advantages: false,
            Restrictions: false,
            Requirements: false,
        },
    };

    const activityDChartSlice = createSlice({
        name: 'activityChart',
        initialState,
        reducers: {
            toggleSegment: (state, action) => {
                const segmentLabel = action.payload.label;
                if (state[segmentLabel].length === 0) {
                    state[segmentLabel].push("1");
                } else {
                    state[segmentLabel] = [];
                }
            },
            toggleActiveLine: (state, action) => {
                const label = action.payload.label;
                state.activeLines[label] = !state.activeLines[label];
            },
            resetActivityChart: (state) => {
                return initialState;
            },
        },
    });

    export const { toggleSegment, toggleActiveLine,resetActivityChart } = activityDChartSlice.actions;
    export default activityDChartSlice.reducer;
