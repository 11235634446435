import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bubbleSelectedSegments: [],
    bubbelChartName: [],
};

const bubbleSegmentSlice = createSlice({
    name: 'bubbleSegmentSlice',
    initialState,
    reducers: {
        addBubbleChartName: (state, action) => {
            state.bubbelChartName = [action.payload];
        },
        toggleSegment: (state, action) => {
            const { regNum } = action.payload; // Destructuring to get regNum
            const index = state.bubbleSelectedSegments.findIndex(segment => segment.regNum === regNum);
            if (index === -1) {
                // Push the whole object if not found
                state.bubbleSelectedSegments.push(action.payload);
            } else {
                // Remove the object if already exists
                state.bubbleSelectedSegments.splice(index, 1);
            }
        },
        removeBubble: (state, action) => {
            const regNum = action.payload;
            const index = state.bubbleSelectedSegments.findIndex(segment => segment.regNum === regNum);
            if (index !== -1) {
                state.bubbleSelectedSegments.splice(index, 1);
            }
        },
        resetBubbleChart: (state) => {
            state.bubbleSelectedSegments = [];
        },
    },
});

export const {addBubbleChartName, toggleSegment, removeBubble, resetBubbleChart } = bubbleSegmentSlice.actions;
export default bubbleSegmentSlice.reducer;
