import React from 'react'
import style from './TextAreaCutom.module.css'
const TextAreaField = ({ placeholder, name, value, onChange, className, maxLength, onBlur, isError }) => {
    const errorClass = isError ? style.inputError : ''
    const combinedClassName = `${className} ${errorClass}`
    const handleTextareaChange = (event) => {
        if (maxLength && event.target.value.length > maxLength) {
            event.preventDefault()
            return
        }
        onChange(event)
    }

    return (
        <textarea
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={handleTextareaChange}
            className={combinedClassName}
            maxLength={maxLength}
            onBlur={onBlur}
        ></textarea>
    )
}

export default TextAreaField
