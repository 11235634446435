import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    'Извещения': {
        diagrams: [{ type: 'Summary', gridArea: 'col1' },
            { type: 'PieChart', gridArea: 'col2' },
            { type: 'TopDiagramm', gridArea: 'col3' },
            { type: 'StackedBarChart', gridArea: 'col4' },
            { type: 'SunBurst', gridArea: 'col5' },
            { type: 'SoloLineD', gridArea: 'col6' },
            { type: 'GeoChart', gridArea: 'col7' },
            { type: 'BarLineChart', gridArea: 'col8' },
            { type: 'ManyLineD', gridArea: 'col9' },
            { type: 'RoleDiagramm', gridArea: 'col10' },
            { type: 'TreeMapDiagramm', gridArea: 'col11' }],
        gridTemplateAreas: `
                  "col1 col2 col3"
                  "col4 col4 col5"
                  "col6 col7 col7"
                  "col8 col8 col9"
                  "col10 col11 col11"`,
    },
    'Контракты': {
        diagrams: [{ type: 'Summary', gridArea: 'col1' },
            { type: 'PieChart', gridArea: 'col8' },
            { type: 'SunBurst', gridArea: 'col4' },
            { type: 'ContractStackedBarChart', gridArea: 'col2' },
            { type: 'TopDiagramm', gridArea: 'col6' },
            { type: 'GeoChart', gridArea: 'col3' },
            { type: 'BarLineChart', gridArea: 'col7' },
            { type: 'TreeMapDiagramm', gridArea: 'col9' },
            { type: 'RoleDiagramm', gridArea: 'col11' },
            { type: 'ContractPayMonth', gridArea: 'col10' }],
        gridTemplateAreas: `
                  "col1 col2 col2"
                  "col3 col3 col4"
                  "col6 col7 col7"
                  "col8 col9 col9"
                  "col10 col10 col11"`,
    },
    'Исполнение': {
        diagrams: [{ type: 'Summary', gridArea: 'col1' },
            { type: 'BarLineChart', gridArea: 'col2' },
            { type: 'Indicator', gridArea: 'col5' },
            { type: 'PieChart', gridArea: 'col3' },
            { type: 'RoleDiagramm', gridArea: 'col4' },
            { type: 'ispOkpd', gridArea: 'col6' },
            { type: 'GeoChart', gridArea: 'col7' },
            { type: 'SunBurst', gridArea: 'col8' },
            { type: 'SunKey', gridArea: 'col9' },
            { type: 'ContractStackedBarChart', gridArea: 'col10' },
            { type: 'Scatter', gridArea: 'col11' },
        ],
        gridTemplateAreas: `
                  "col1 col2 col2"
                  "col3 col4 col5"
                  "col6 col7 col7"
                  "col8 col9 col9"
                  "col10 col10 col11"`,
    },
};

const diagramConfigSlice = createSlice({
    name: 'diagramConfig',
    initialState,
    reducers: {
        swapDiagrams: (state, action) => {
            const { fromType, toType, tabName } = action.payload;
            const fromDiagram = state[tabName].diagrams.find(d => d.type === fromType);
            const toDiagram = state[tabName].diagrams.find(d => d.type === toType);
            if (!fromDiagram || !toDiagram) return;
            [fromDiagram.gridArea, toDiagram.gridArea] = [toDiagram.gridArea, fromDiagram.gridArea];
        }
    }
});


export const { swapDiagrams } = diagramConfigSlice.actions;

export default diagramConfigSlice.reducer;
