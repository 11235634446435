import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetInfoStatistic} from "../../api/api";

const initialState = {
    InfoData: [],
    loading: 'idle',
    error: null,
};

export const fetchInfoData = createAsyncThunk(
    'info/fetchInfoData',
    async (options) => {
        options.newOKPDCode = options.selectedProduct.map(item => item.productId);
        const response = await fetInfoStatistic(options);
        return response.data.result;
    }
);

const infoSlice = createSlice({
    name: 'infoData',
    initialState,
    reducers: {
        resetInfoData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInfoData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchInfoData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.InfoData = action.payload;
            })
            .addCase(fetchInfoData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetInfoData } = infoSlice.actions;

export default infoSlice.reducer;
