import styles from '../../SearchSettingsMain/SettingsContainer.module.css'
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import React, {forwardRef} from "react";
import calendar from '../../../../common/icons/calendarIcon.svg'

const CustomInputMask = forwardRef(({ value, onClick, onChange, placeholder }, ref) => (
    <InputMask
        mask="99.99.9999"
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        onClick={onClick}
        className={styles.customDatePickerInput}
    >
        {(inputProps) => <input ref={ref} {...inputProps} />}
    </InputMask>
));
export const DatePickerWrapper = ({ label, date, setDate, pickerRef, openDatePicker, minDate }) => (
    <div className={styles.pickerElement}>
        <span className={styles.datePickerLabel}>{label}</span>
        <div className={styles.customDatePickerWrapper}>
            <DatePicker
                ref={pickerRef}
                selected={date}
                onChange={setDate}
                placeholderText="ДД.ММ.ГГГГ"
                dateFormat="dd.MM.yyyy"
                customInput={<CustomInputMask />}
                popperPlacement="bottom-end"
                minDate={minDate}
                closeOnScroll={true}
                className={styles.customDatePickerInput}
            />
            <img
                src={calendar}
                alt="calendar"
                className={styles.customDatePickerIcon}
                onClick={() => openDatePicker(pickerRef)}
            />
        </div>
    </div>
);
