
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchUsers} from "../../api/api";

const usersDataInitialState = {
    usersData: [],
    isLoadingUsers: false,
    usersError: null,
};

export const fetchUsersData = createAsyncThunk(
    'userData/fetchUsersData',
    async () => {
        const response = await fetchUsers();
        return response.data;
    }
);

const usersData = createSlice({
    name: 'usersData',
    initialState: usersDataInitialState,
    reducers: {
        clearUsersData: (state) => {
            state.usersData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersData.pending, (state) => {
                state.isLoadingUsers = 'pending';
            })
            .addCase(fetchUsersData.fulfilled, (state, action) => {
                state.isLoadingUsers = 'successful';
                state.usersData = action.payload;
            })
            .addCase(fetchUsersData.rejected, (state, action) => {
                state.isLoadingUsers = 'failed';
                state.usersError = action.error.message;
            })
    },
});

export const { clearUsersData } = usersData.actions;

export default usersData.reducer;
