
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchOrganization} from "../../api/api";

const usersDataInitialState = {
    organizationData: [],
    isLoadingOrgData: false,
    orgInfoError: null,
};

export const fetchOrganizationData = createAsyncThunk(
    'organizationInfoData/fetchOrganizationData',
    async () => {
        const response = await fetchOrganization();
        return response.data;
    }
);

const organizationInfoData = createSlice({
    name: 'organizationInfoData',
    initialState: usersDataInitialState,
    reducers: {
        clearOrganization: (state) => {
            state.meData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrganizationData.pending, (state) => {
                state.isLoadingOrgData = 'pending';
            })
            .addCase(fetchOrganizationData.fulfilled, (state, action) => {
                state.isLoadingOrgData = 'successful';
                state.organizationData = action.payload;
            })
            .addCase(fetchOrganizationData.rejected, (state, action) => {
                state.isLoadingOrgData = 'failed';
                state.orgInfoError = action.error.message;
            })
    },
});

export const { clearOrganization } = organizationInfoData.actions;

export default organizationInfoData.reducer;
