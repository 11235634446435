import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeRegions: [],
    geoName: [],
    viewBox: `0 0 ${800} ${500}`,
    mode: "",
};

const geoChartRegionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setViewBox: (state, action) => {
            state.viewBox = action.payload;
        },
        setActiveRegions: (state, action) => {
            state.activeRegions = action.payload;
        },
        toggleRegion: (state, action) => {
            const regionCode = action.payload;
            if (state.activeRegions.includes(regionCode)) {
                state.activeRegions = state.activeRegions.filter(code => code !== regionCode);
            } else {
                state.activeRegions.push(regionCode);
            }
        },
        addGeoName: (state, action) => {
            state.geoName = [action.payload];
        },
        clearActiveRegions: (state) => {
            state.activeRegions = [];
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
    },
});

export const {setMode,setViewBox,addGeoName, setActiveRegions, toggleRegion, clearActiveRegions } = geoChartRegionSlice.actions;
export default geoChartRegionSlice.reducer;
