import React from 'react';
import './Spinner.css';

const Spinner = () => {
    return (
        <div className="spinner">
            <svg className="donut" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="45" className="donut-hole" />
                <circle cx="50" cy="50" r="45" className="donut-segment" transform="rotate(-90 50 50)" />
            </svg>
        </div>
    );
};

export default Spinner;
