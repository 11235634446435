import * as d3 from 'd3';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import style from "../../TesTreeD/TreeMapDiagramm.module.css";
import styles from "../LineBarChart.module.css";
import tooltipStyle from "../../TestMapD/GeoChart.module.css"
import HeaderDiagram from "../../HeaderD/HeaderDiagram";
import icons from "../../../../common/icons/icons";
import {useDispatch, useSelector} from "react-redux";
import {fetchBarLineData} from "../../../../service/reducers/PublishChart/BarWithLineChartSlice";
import Spinner from "../../../TestPages/Spinner";
import useResizeObserver from "use-resize-observer";
import {
    addBarLineV1Name,
    clearSelectedMonth, clearSelectedPeriod, setCurrentActiveButton,
    toggleSelectedMonthsGroup
} from "../../../../service/reducers/PublishChart/BarWithLineChartMonthSlice";
import {formatNumber} from "../../../../utils/countFunc";
import {
    clearContractSelectedMonth
} from "../../../../service/reducers/PublishChart/Contracts/ContractBarWithLineChartMonthSlice";
import {useVirtualTooltipSize} from "../../../../hook/useVirtualTooltipSize";
import {cancelAllPendingRequests} from "../../../../api/api";
import {convertRangeDateToStartDTFormat} from "../../../../utils/convertRangeDate";
import tooltipNames from "../../../../utils/tooltipTitles.json"
import styleTooltip from "../../TestMapD/GeoChart.module.css";
import {formatCurrency} from "../../../../utils/rubbleFunc";

export const BarLineChart = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const { width, height } = useResizeObserver({ ref });
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const tooltipRef = useRef(null);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);
    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const relatedINNs = useSelector(state => state.organization.relatedINNs);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);

    const { BarLineData, loading } = useSelector((state) => state.barWithLine);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const { currentActiveButton } = useSelector((state) => state.barLineChartMonth)
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const top = useSelector((state) => state.activitySlice);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const { selectedSegments } = useSelector((state) => state.treeMapSlice);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedTreeMapLabels = useMemo(() => {
        return selectedSegments.map(segment => segment.label);
    }, [selectedSegments]);

    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);

    let headerTitle;
    switch (activeTab) {
        case 'Извещения':
            headerTitle = 'График размещения закупок';
            break;
        case 'Контракты':
            headerTitle = 'График заключения контрактов';
            break;
        case 'Исполнение':
            headerTitle = 'График исполнения этапов и контрактов';
            break;
        default:
            headerTitle = 'Неизвестная вкладка';
    }
    const headerWithThreeButtons = useMemo(() => ({
        title: headerTitle,
        icons: [
            { name: 'zoom',  icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20 },
            { name: 'menu', icon: icons.menu, width: 20, height: 20 }
        ]
    }), [headerTitle,zoomedDiagram]);

    useEffect(() => {
        const requestData = {
            relatedINNs,
            selectedProduct,
            pieState,
            activeRegions,
            topBody,
            selectedOkpd,
            trimCode,
            filterOkpd,
            selectedTreeMapLabels,
            RangeDT,
            contractTrimCode,
            activeTab,
            selectedDonutSegmetsV1,
            selectedContractMonth,
            selectedCountryLine,
            selectedKbrSegments,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };
        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedTreeMapLabels = selectedTreeMapLabels;
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedCustSegments = selectedTreeMapLabels;
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }
        dispatch(fetchBarLineData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,selectedKbrSegments,contractTrimCode,filterOkpd,dateChanger,selectedOkpd,selectedProduct, activeRegions, top, pieState, trimCode, selectedSegments, relatedINNs, selectedDonutSegmetsV1, selectedContractMonth, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);

    useEffect(() => {
        if (BarLineData && width && height && loading === 'successful') {
            const groupedData = groupDataByTime(BarLineData, currentActiveButton);
                drawChart(groupedData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [BarLineData, currentActiveButton, width, height, selectedMonth]);


    const setGroupAndClearSelection = useCallback((newGroupType) => {
        dispatch(setCurrentActiveButton(newGroupType));
        if (selectedMonth && selectedMonth.length > 0) {
            dispatch(clearSelectedMonth());
            dispatch(clearSelectedPeriod());
        }
    }, [dispatch, selectedMonth]);
    const groupDataByTime = (data, groupType) => {
        let groupedData = [];
        let currentGroup = null;
        let sum = 0;
        let countItems = 0;
        let arrayOfMonths = [];
        let arrayOfExtras = [];

        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const itemDate = new Date(item.month);
            const itemYear = itemDate.getFullYear();
            let groupKey;

            if (groupType === 'month') {
                groupKey = `${itemYear}-${itemDate.getMonth()}`;
            }
            else if (groupType === 'quarter') {
                const quarter = Math.floor(itemDate.getMonth() / 3) + 1;
                groupKey = `${quarter}КВ-${itemYear}`;
            } else if (groupType === 'half-year') {
                groupKey = ((itemDate.getMonth() < 6) ? 'П1-' : 'П2-') + itemYear;
            } else {
                groupKey = String(itemYear);
            }

            if (currentGroup !== groupKey) {
                if (currentGroup !== null) {
                    groupedData.push({
                        label: currentGroup,
                        month: data[i-1].month,
                        summary: sum,
                        count: countItems,
                        months: [...arrayOfMonths],
                        extras: [...arrayOfExtras]
                    });
                }
                arrayOfMonths = [];
                arrayOfExtras = [];
                currentGroup = groupKey;
                sum = 0;
                countItems = 0;
            }
            arrayOfExtras.push(item.extra);
            arrayOfMonths.push(data[i].month);
            sum += data[i].summary;
            countItems += data[i].count;
        }

        if (currentGroup !== null) {
            groupedData.push({
                label: currentGroup,
                month: data[data.length - 1].month,
                summary: sum,
                count: countItems,
                months: [...arrayOfMonths],
                extras: [...arrayOfExtras]
            });
        }
        return groupedData;
    };

    const formatDateByGroupType = (dateString, groupType) => {
        const date = new Date(dateString);
        switch (groupType) {
            case 'month':
                return date.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' });
            case 'quarter':
                const quarter = Math.floor(date.getMonth() / 3) + 1;
                return `${quarter} Квартал ${date.getFullYear()} г.`;
            case 'half-year':
                const half = date.getMonth() < 6 ? '1 полугодие' : '2 полугодие';
                return `${half} ${date.getFullYear()} г.`;
            case 'year':
                const year = date.getFullYear();
                return `${year} г.`;
            default:
                return date.toLocaleDateString('ru-RU', { month: 'long', year: 'numeric' });
        }
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });

    const tooltipConfig = tooltipNames.BarLinesV1.Tabs[activeTab];
    const onMouseMoveBar = (event, d) => {
        const tooltipData = [
            { label: tooltipConfig.placement, value: formatDateByGroupType(d.month, currentActiveButton) },
            { label: tooltipConfig.totalAmount, value: formatCurrency(d.summary) + ' руб.' }
        ];

        showTooltip(event, tooltipData);
    };

    const onMouseMoveLine = (event, d) => {
        const tooltipData = [
            { label: tooltipConfig.placement, value: formatDateByGroupType(d.month, currentActiveButton) },
            { label: tooltipConfig.purchaseCount, value: d.count + ' шт.' }
        ];

        showTooltip(event, tooltipData);
    };

    const showTooltip = (event, tooltipData) => {
        const tooltipSize = calculateTooltipSize(tooltipData);
        let x = event.pageX + 10;
        let y = event.pageY + 10;

        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }

        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({
            x,
            y,
            text: tooltipData
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };

    const findClosestBar = (event, data, xScale) => {
        const [x] = d3.pointer(event);
        let closestData = null;
        let minDiff = Infinity;

        data.forEach(d => {
            const barX = xScale(new Date(d.month)) + xScale.bandwidth() / 2;
            const diff = Math.abs(barX - x);
            if (diff < minDiff) {
                minDiff = diff;
                closestData = d;
            }
        });

        return closestData;
    };

    const drawChart = (data) => {
        if (!data || !ref.current) return;
        d3.select(ref.current).select("svg").remove();
        const margin = { top: 10, right: 50, bottom: 100, left: 55 };
        const width = ref.current.clientWidth;
        const height = ref.current.clientHeight;

        const x = d3.scaleBand()
            .domain(data.map(d => new Date(d.month)))
            .rangeRound([margin.left, width - margin.right])
            .padding(0.2);
        const y1 = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.summary)])
            .rangeRound([height - margin.bottom, margin.top]);
        const maxY = d3.max(data, d => d.summary);
        const tickValues = [maxY / 4, maxY / 2, 3 * maxY / 4, maxY];

        const maxCount = d3.max(data, d => d.count);
        const tickValuesY2 = [
            Math.floor(maxCount / 4),
            Math.floor(maxCount / 2),
            Math.floor(3 * maxCount / 4),
            Math.floor(maxCount)
        ];

        const y2 = d3.scaleLinear()
            .domain([0, d3.max(data, d => d.count)])
            .rangeRound([height - margin.bottom, margin.top]);

        const svg = d3.select(ref.current)
            .append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height]);

        let lastYear = null;
        const xAxis = g => g
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).tickSizeOuter(0))
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .selectAll(".tick")
            .each(function(d, i) {
                d3.select(this).select("text").remove();
                const correspondingData = data.find(item => new Date(item.month).getTime() === new Date(d).getTime());
                if (correspondingData) {
                    const date = new Date(correspondingData.month);
                    const currentYear = date.getFullYear();
                    if (currentActiveButton === 'month') {
                        const month = date.toLocaleString('ru', { month: 'short' }).replace('.', '');
                        d3.select(this).append("text")
                            .attr("dx", "-2em")
                            .attr("dy", "0.3em")
                            .style("text-anchor", "middle")
                            .attr("transform", "rotate(-90)")
                            .style("fill", "#8D96B2")
                            .style("font-family", "Golos Regular")
                            .text(month);
                    } else if (currentActiveButton === 'quarter') {
                        const quarter = Math.floor(date.getMonth() / 3) + 1;
                        d3.select(this).append("text")
                            .attr("dx", "-2em")
                            .attr("dy", "0.3em")
                            .style("text-anchor", "middle")
                            .attr("transform", "rotate(-90)")
                            .style("font-family", "Golos Regular")
                            .style("fill", "#8D96B2")
                            .text(`КВ${quarter}`);
                    } else if (currentActiveButton === 'half-year') {
                        const half = date.getMonth() < 6 ? '1П' : '2П';
                        d3.select(this).append("text")
                            .attr("dx", "-2em")
                            .attr("dy", "0.3em")
                            .style("text-anchor", "middle")
                            .attr("transform", "rotate(-90)")
                            .style("font-family", "Golos Regular")
                            .style("fill", "#8D96B2")
                            .text(half);
                    }
                    if (lastYear !== currentYear) {
                        d3.select(this).append("text")
                            .attr("dy", "4.5em")
                            .style("text-anchor", "middle")
                            .style("fill", "#8D96B2")
                            .style("font-family", "Golos Regular")
                            .style("font-size", `${Math.min(12, width / 30)}px`)
                            .text(currentYear);
                        lastYear = currentYear;
                    }
                }
            });

        const y1Axis = g => g
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y1)
                .tickValues(tickValues)
                .tickFormat(formatNumber)
            )
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick text").style("fill", "#8D96B2"))
            .call(g => g.selectAll(".tick text").style("font-family", "Golos Regular"));

        const format = d3.format(".0f");
        const y2Axis = g => g
            .attr("transform", `translate(${width - margin.right},0)`)
            .call(d3.axisRight(y2)
                .tickValues(tickValuesY2)
                .tickFormat(format)
            )
            .call(g => g.select(".domain").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick line").style("stroke", "#EDF1F5"))
            .call(g => g.selectAll(".tick text").style("fill", "#8D96B2"))
            .call(g => g.selectAll(".tick text").style("font-family", "Golos Regular"));

        const line = d3.line()
            .curve(d3.curveMonotoneX)
            .x(d => x(new Date(d.month)) + x.bandwidth() / 2)
            .y(d => y2(d.count));

        const initialOpacity = selectedMonth.length === 0 ? 1 : d => selectedMonth.includes(d.month) ? 1 : 0.3;

        const roundedRectangle = (x, y, width, height, maxRadius) => {
            const radius = Math.min(maxRadius, height / 2);
            return `M${x + radius},${y}
            L${x + width - radius},${y}
            Q${x + width},${y} ${x + width},${y + radius}
            L${x + width},${y + height}
            L${x},${y + height}
            L${x},${y + radius}
            Q${x},${y} ${x + radius},${y}
            Z`;
        };

        const bars = svg.append("g")
            .selectAll("path")
            .data(data)
            .join("path")
            .attr("d", d => roundedRectangle(
                x(new Date(d.month)),
                y1(d.summary),
                x.bandwidth(),
                y1(0) - y1(d.summary),
                5
            ))
            .attr("fill", "#649DFF")
            .attr("opacity", initialOpacity)
            .on("click", function(event, d) {
                cancelAllPendingRequests()
                dispatch(toggleSelectedMonthsGroup({ months: d.months, timePeriod: d.label }));
                dispatch(addBarLineV1Name(headerTitle))
                dispatch(clearContractSelectedMonth());
                onMouseOut()
            })
        bars.on("mousemove", function(event, d) {
            onMouseMoveBar(event, d);
        }).on("mouseout", onMouseOut);

        bars.transition()
            .duration(1000)
            .attr("y", d => y1(d.summary))
            .attr("height", d => y1(0) - y1(d.summary));

        const path = svg.append("path")
            .attr("fill", "none")
            .attr("stroke", "#4B72FB")
            .attr("stroke-miterlimit", 1)
            .attr("stroke-width", 2)
            .attr("d", line(data))

        path.on("click", function(event) {
            const closestData = findClosestBar(event, data, x);
            cancelAllPendingRequests()
            dispatch(toggleSelectedMonthsGroup({ months: closestData.months, timePeriod: closestData.label }));
            dispatch(addBarLineV1Name(headerTitle))
            dispatch(clearContractSelectedMonth());
            onMouseOut()
        });

        path.on("mousemove", function(event, d) {
            const closestData = findClosestBar(event, data, x, y2);
            onMouseMoveLine(event, closestData);
        }).on("mouseout", onMouseOut);

        const totalLength = path.node().getTotalLength();

        path
            .attr("stroke-dasharray", totalLength + " " + totalLength)
            .attr("stroke-dashoffset", totalLength)
            .transition()
            .duration(2000)
            .attr("stroke-dashoffset", 0);

        svg.append("g").call(xAxis);
        svg.append("g").call(y1Axis);
        svg.append("g").call(y2Axis);

        const circlesGroup = svg.append("g");
        const updateCircles = () => {
            const selectedData = data.filter(d => selectedMonth.includes(d.month));
            const circles = circlesGroup.selectAll("circle")
                .data(selectedData, d => d.month);

            circles.enter()
                .append("circle")
                .attr("r", 0)
                .attr("fill", "#FFF")
                .attr("stroke", "#4B72FB")
                .attr("stroke-width", "1.5")
                .attr("cx", d => x(new Date(d.month)) + x.bandwidth() / 2)
                .attr("cy", d => y2(d.count))
                .on("mousemove", function(event, d) {
                    onMouseMoveLine(event, d);
                })
                .on("mouseout", onMouseOut)
                .transition()
                .delay(1200)
                .duration(500)
                .attr("r", 4)

            circles
                .transition()
                .duration(300)
                .attr("cx", d => x(new Date(d.month)) + x.bandwidth() / 2)
                .attr("cy", d => y2(d.count));

            circles.exit()
                .transition()
                .duration(300)
                .attr("r", 0)
                .remove();
        };
        updateCircles();
    }

    return (
        <div className={`${style.container} ${selectedMonth.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "700px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                        <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                        <div className={styles.buttonContainter}>
                            <div
                                className={`${styles.button} ${currentActiveButton === 'month' ? styles.buttonSelected : ''}`}
                                onClick={() => setGroupAndClearSelection('month')}
                            >
                                Месяц
                            </div>
                            <div
                                className={`${styles.button} ${currentActiveButton === 'quarter' ? styles.buttonSelected : ''}`}
                                onClick={() => setGroupAndClearSelection('quarter')}
                            >
                                Квартал
                            </div>
                            <div
                                className={`${styles.button} ${currentActiveButton === 'half-year' ? styles.buttonSelected : ''}`}
                                onClick={() => setGroupAndClearSelection('half-year')}
                            >
                                Полгода
                            </div>
                            <div
                                className={`${styles.button} ${currentActiveButton === 'year' ? styles.buttonSelected : ''}`}
                                onClick={() => setGroupAndClearSelection('year')}
                            >
                                Год
                            </div>
                        </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={tooltipStyle.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}: </strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    );

};
