import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchPlacingMonth} from "../../api/api";

const initialState = {
    manyLineMonthData: [],
    showCount: 'sum',
    loading: 'idle',
    error: null,
    isBrushActiveMonth: false,
};

export const fetchManyLineMonthData = createAsyncThunk(
    'manylinesMonth/fetchManyLineMonthData',
    async (options) => {
        options.newOKPDCode = options.selectedProduct.map(item => item.productId);
        const response = await fetchPlacingMonth(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const lineChartSlice = createSlice({
    name: 'manylinesMonth',
    initialState,
    reducers: {
        setShowCountManyLines: (state, action) => {
            state.showCount = action.payload;
        },
        resetLineChartData: (state) => {
            return initialState;
        },
        toggleBrushActiveMonth: (state) => {
            state.isBrushActiveMonth = !state.isBrushActiveMonth;
        },
        resetBrushActiveMonth: (state) => {
            state.isBrushActiveMonth = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchManyLineMonthData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchManyLineMonthData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.manyLineMonthData = action.payload;
            })
            .addCase(fetchManyLineMonthData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});
export const { resetBrushActiveMonth, toggleBrushActiveMonth, setShowCountManyLines,resetLineChartData } = lineChartSlice.actions;

export default lineChartSlice.reducer;
