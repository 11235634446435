import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isMenuOpen: false,
    isLoadingMenu: false,
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.isMenuOpen = !state.isMenuOpen;
        },
        setLoadingMenu: (state, action) => {
            state.isLoadingMenu = action.payload;
        }
    }
});

export const { toggleMenu ,setLoadingMenu} = menuSlice.actions;
export default menuSlice.reducer;
