import React, {useState} from 'react';
import style from '../OkpdTree/OkpdTree.module.css'
import locationsData from '../../utils/geoNamesMap.json'
import LocationItem from "./LocationItem";
import {IconButton, TextField} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const LocationsList = () => {
    // eslint-disable-next-line
    const [filter, setFilter] = useState('');
    const filteredData = filter
        ? locationsData.filter(item => item.region.toLowerCase().includes(filter.toLowerCase()))
        : locationsData;

    const handleClear = () => {
        setFilter('');
    };

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    return (
        <div className={`${style.areaContainer} ${style.okpdContainer}`}>
            <div>
                <TextField
                    InputProps={{
                        endAdornment: (
                            filter && (
                                <IconButton
                                    onClick={handleClear}
                                    style={{ padding: '5px' }}
                                >
                                    <ClearIcon style={{ fontSize: '20px', color: '#8D96B2' }} />
                                </IconButton>
                            )
                        ),
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: '6px',
                            backgroundColor: '#F7FAFC',
                            '& fieldset': {
                                borderColor: '#8D96B2',
                            },
                            '&:hover fieldset': {
                                borderColor: '#C8A1FF',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: '#4A3DFD',
                            },
                            '& .MuiInputBase-input': {
                                padding: '8px',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                        '& .MuiInputBase-input': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                        '& .MuiFormHelperText-root': {
                            fontFamily: 'Golos Regular',
                            fontSize: '14px',
                        },
                    }}
                    className={style.formControl}
                    placeholder="Введите название города/области/региона"
                    value={filter}
                    onChange={handleFilterChange}
                />
            </div>
            <div className={style.scrollableList}>
                <ul>
                    {filteredData.map(location => (
                        <LocationItem key={location.region} data={location} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default LocationsList;
