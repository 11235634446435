import React from "react";
import styles from "../../components/Layout/Layout.module.css";
import CommonHeader from "../../components/Header/ComonHeader";
import HeadRatingContent from "./HeadRatingContent/HeadRatingContent";
import TestTable from "./CleverTable/TestTable";

// eslint-disable-next-line
const mockDataArray = [
    {
        id: 1,
        name: 'Федеральное государственное унитарное предприятие "Радиотрансляционная сеть Санкт-Петербурга"',
        sumCompleted: '300 млн. ₽',
        sumIsp: '200 млн. ₽',
        sumRejected: '50 млн. ₽',
        countGK: 1000,
        countGKIsp: 800,
        countGKPending: 100,
        countGKRejected: 100,
        countCustomer: 899,
        amountPenalty: '3 млн. ₽',
        amountPennies: '3 млн. ₽',
        countISO: 31,
        ndsAmount: '1 млн. ₽',
        amountSubCustomer: '300 млн. ₽',
        executability: '99%',
        trustworthiness: '99%',
        versatility: 1000,
        locality: 300,
        eteRating: '10.0',
        smmToxity: 96,
        mainIndicators: '+5',
        averageDecline: '10%'
    },
    {
        id: 2,
        name: 'Общество с ограниченной ответственностью "Строительные решения"',
        sumCompleted: '500 млн. ₽',
        sumIsp: '350 млн. ₽',
        sumRejected: '25 млн. ₽',
        countGK: 1500,
        countGKIsp: 1200,
        countGKPending: 200,
        countGKRejected: 100,
        countCustomer: 780,
        amountPenalty: '2 млн. ₽',
        amountPennies: '1 млн. ₽',
        countISO: 15,
        ndsAmount: '1.5 млн. ₽',
        amountSubCustomer: '250 млн. ₽',
        executability: '97%',
        trustworthiness: '95%',
        versatility: 800,
        locality: 200,
        eteRating: '9.5',
        smmToxity: 90,
        mainIndicators: '+3',
        averageDecline: '7%'
    },
    {
        id: 3,
        name: 'Государственное предприятие "Технопарк"',
        sumCompleted: '150 млн. ₽',
        sumIsp: '100 млн. ₽',
        sumRejected: '10 млн. ₽',
        countGK: 500,
        countGKIsp: 450,
        countGKPending: 50,
        countGKRejected: 0,
        countCustomer: 350,
        amountPenalty: '1 млн. ₽',
        amountPennies: '500 тыс. ₽',
        countISO: 10,
        ndsAmount: '500 тыс. ₽',
        amountSubCustomer: '150 млн. ₽',
        executability: '98%',
        trustworthiness: '96%',
        versatility: 600,
        locality: 150,
        eteRating: '9.8',
        smmToxity: 89,
        mainIndicators: '+6',
        averageDecline: '5%'
    },
    {
        id: 4,
        name: 'Закрытое акционерное общество "Промышленные решения"',
        sumCompleted: '400 млн. ₽',
        sumIsp: '250 млн. ₽',
        sumRejected: '30 млн. ₽',
        countGK: 850,
        countGKIsp: 700,
        countGKPending: 100,
        countGKRejected: 50,
        countCustomer: 670,
        amountPenalty: '2.5 млн. ₽',
        amountPennies: '2 млн. ₽',
        countISO: 25,
        ndsAmount: '2 млн. ₽',
        amountSubCustomer: '400 млн. ₽',
        executability: '96%',
        trustworthiness: '98%',
        versatility: 750,
        locality: 220,
        eteRating: '9.7',
        smmToxity: 93,
        mainIndicators: '+4',
        averageDecline: '8%'
    },
    {
        id: 5,
        name: 'Индивидуальный предприниматель "Иванов Иван Иванович"',
        sumCompleted: '100 млн. ₽',
        sumIsp: '80 млн. ₽',
        sumRejected: '5 млн. ₽',
        countGK: 300,
        countGKIsp: 290,
        countGKPending: 10,
        countGKRejected: 0,
        countCustomer: 280,
        amountPenalty: '800 тыс. ₽',
        amountPennies: '200 тыс. ₽',
        countISO: 5,
        ndsAmount: '300 тыс. ₽',
        amountSubCustomer: '100 млн. ₽',
        executability: '100%',
        trustworthiness: '97%',
        versatility: 500,
        locality: 100,
        eteRating: '9.9',
        smmToxity: 80,
        mainIndicators: '+7',
        averageDecline: '3%'
    },
    {
        id: 6,
        name: 'Индивидуальный предприниматель "Иванов Иван Иванович"',
        sumCompleted: '100 млн. ₽',
        sumIsp: '80 млн. ₽',
        sumRejected: '5 млн. ₽',
        countGK: 300,
        countGKIsp: 290,
        countGKPending: 10,
        countGKRejected: 0,
        countCustomer: 280,
        amountPenalty: '800 тыс. ₽',
        amountPennies: '200 тыс. ₽',
        countISO: 5,
        ndsAmount: '300 тыс. ₽',
        amountSubCustomer: '100 млн. ₽',
        executability: '100%',
        trustworthiness: '97%',
        versatility: 500,
        locality: 100,
        eteRating: '9.9',
        smmToxity: 80,
        mainIndicators: '+7',
        averageDecline: '3%'
    },
    {
        id: 7,
        name: 'Индивидуальный предприниматель "Иванов Иван Иванович"',
        sumCompleted: '100 млн. ₽',
        sumIsp: '80 млн. ₽',
        sumRejected: '5 млн. ₽',
        countGK: 300,
        countGKIsp: 290,
        countGKPending: 10,
        countGKRejected: 0,
        countCustomer: 280,
        amountPenalty: '800 тыс. ₽',
        amountPennies: '200 тыс. ₽',
        countISO: 5,
        ndsAmount: '300 тыс. ₽',
        amountSubCustomer: '100 млн. ₽',
        executability: '100%',
        trustworthiness: '97%',
        versatility: 500,
        locality: 100,
        eteRating: '9.9',
        smmToxity: 80,
        mainIndicators: '+7',
        averageDecline: '3%'
    },
    {
        id: 8,
        name: 'Индивидуальный предприниматель "Иванов Иван Иванович"',
        sumCompleted: '100 млн. ₽',
        sumIsp: '80 млн. ₽',
        sumRejected: '5 млн. ₽',
        countGK: 300,
        countGKIsp: 290,
        countGKPending: 10,
        countGKRejected: 0,
        countCustomer: 280,
        amountPenalty: '800 тыс. ₽',
        amountPennies: '200 тыс. ₽',
        countISO: 5,
        ndsAmount: '300 тыс. ₽',
        amountSubCustomer: '100 млн. ₽',
        executability: '100%',
        trustworthiness: '97%',
        versatility: 500,
        locality: 100,
        eteRating: '9.9',
        smmToxity: 80,
        mainIndicators: '+7',
        averageDecline: '3%'
    },
    {
        id: 9,
        name: 'Индивидуальный предприниматель "Иванов Иван Иванович"',
        sumCompleted: '100 млн. ₽',
        sumIsp: '80 млн. ₽',
        sumRejected: '5 млн. ₽',
        countGK: 300,
        countGKIsp: 290,
        countGKPending: 10,
        countGKRejected: 0,
        countCustomer: 280,
        amountPenalty: '800 тыс. ₽',
        amountPennies: '200 тыс. ₽',
        countISO: 5,
        ndsAmount: '300 тыс. ₽',
        amountSubCustomer: '100 млн. ₽',
        executability: '100%',
        trustworthiness: '97%',
        versatility: 500,
        locality: 100,
        eteRating: '9.9',
        smmToxity: 80,
        mainIndicators: '+7',
        averageDecline: '3%'
    },
    {
        id: 10,
        name: 'Индивидуальный предприниматель "Иванов Иван Иванович"',
        sumCompleted: '100 млн. ₽',
        sumIsp: '80 млн. ₽',
        sumRejected: '5 млн. ₽',
        countGK: 300,
        countGKIsp: 290,
        countGKPending: 10,
        countGKRejected: 0,
        countCustomer: 280,
        amountPenalty: '800 тыс. ₽',
        amountPennies: '200 тыс. ₽',
        countISO: 5,
        ndsAmount: '300 тыс. ₽',
        amountSubCustomer: '100 млн. ₽',
        executability: '100%',
        trustworthiness: '97%',
        versatility: 500,
        locality: 100,
        eteRating: '9.9',
        smmToxity: 80,
        mainIndicators: '+7',
        averageDecline: '3%'
    }
];
// eslint-disable-next-line
const columnHeaders = [
    { label: "Наименование" },
    { label: "Сумма заключено" },
    { label: "Сумма на исполнении" },
    { label: "Сумма расторгнуто" },
    { label: "Кол-во ГК заключено" },
    { label: "Кол-во ГК исполнено" },
    { label: "Кол-во на исполнении" },
    { label: "Кол-во расторгнуто" },
    { label: "Кол-во заказчиков" },
    { label: "Сумма штрафов" },
    { label: "Сумма пенни" },
    { label: "Кол-в регионов" },
    { label: "НДС уплаченный" },
    { label: "Оплачено суб-подрядчикам" },
    { label: "Исполняемость" },
    { label: "Благонадежность " },
    { label: "Многопрофильность" },
    { label: "Локальность" },
    { label: "Значение единицы сквозного рейтинга" },
    { label: "Токсичность в СМИ" },
    { label: "Динамика основных показателей" },
    { label: "Среднее снижении" }
];
export const TestRatings = () => {
    return(
        <div className={styles.mainContainer}>
            <CommonHeader title="Рейтинги"/>
            <HeadRatingContent/>
            <TestTable data={mockDataArray} columnHeaders={columnHeaders}/>
        </div>
    )
};
