import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { fetchUserPrivileges } from '../service/reducers/PrivelegiesSlice';
import { getDateForPickers } from '../api/api';

const AuthCheck = ({ setIsAuthChecked }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const autoLogin = async () => {
        try {
            const response = await axios.post('https://auth.demo.agatha-tender.ru/login', {
                username: 'demo@agatha-hub.ru',
                password: 'sSrpWM4oUX-x',
            }, { withCredentials: true });

            const tokenString = response.data.token;
            localStorage.setItem('token', tokenString);

            await dispatch(fetchUserPrivileges(tokenString));
            const dateForPickersResponse = await getDateForPickers();
            const sortedDateForPickersResponse = dateForPickersResponse.sort((a, b) => {
                if (a.type === 'start_date' && b.type === 'end_date') {
                    return -1;
                } else if (a.type === 'end_date' && b.type === 'start_date') {
                    return 1;
                }
                return 0;
            });
            localStorage.setItem('dateForPickers', JSON.stringify(sortedDateForPickersResponse));

            navigate('/content/dashboard');
        } catch (error) {
            console.error('Ошибка авторизации:', error);
            navigate('/');
        } finally {
            setIsAuthChecked(true);
        }
    };

    useEffect(() => {
        autoLogin();
        // eslint-disable-next-line
    }, [navigate, dispatch]);

    return null;
};

export default AuthCheck;
