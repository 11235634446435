import style from "../Accounts/Accounts.module.css";
import plus from "../../../../../common/icons/HiPlus.svg";
import React, {useEffect} from "react";
import {deleteUserOkpd} from "../../../../../api/api";
import {setFormValidity} from "../../../../../service/reducers/ModalCloseStatusSlice";
import CustomModalContent from "../../../../CustomModalChanger/CustomModal";
import {useModal} from "../../../../../hook/useModal";
import {useDispatch, useSelector} from "react-redux";
import OkpdTree from "../../../../OkpdTree/OkpdTree";
import ConfirmOkpd from "../../../../OkpdTree/ConfirmOkpd/confirmOkpd";
import {resetOkpdSelecteData} from "../../../../../service/reducers/okpdTreeSelected";
import {fetchOkpdUserData} from "../../../../../service/reducers/userOkpdListSlice";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import trash from "../../../../../common/icons/trashIcon.svg";
const OkpdList = () => {
    const { setIsModalVisible, setModalContent, setIsCentered, setIsFullCentered } = useModal();
    const dispatch = useDispatch();
    const okpdListData = useSelector((state) => state.okpdUserData.okpdUserData);

    useEffect(() => {
        dispatch(fetchOkpdUserData());
    }, [dispatch]);
    const handleAddGroupClick = () => {
        dispatch(setFormValidity(true));
        setModalContent(
            <CustomModalContent
                title="Добавление нового ОКПД"
                formComponent={
                    <OkpdTree/>
                }
                buttons={[
                    {
                        text: "Добавить",
                        onClick: handleConfirmOkpd
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                    setIsFullCentered(false);
                    dispatch(fetchOkpdUserData());
                }}
            />
        );
        setIsFullCentered(true)
        setIsCentered(true);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsCentered(false);
        setIsModalVisible(false);
        dispatch(resetOkpdSelecteData());
        dispatch(fetchOkpdUserData());
    };
    const handleConfirmOkpd = () => {
        dispatch(setFormValidity(true));
        setModalContent(
            <CustomModalContent
                title="Подтвердите добавление ОКПД"
                formComponent={
                    <ConfirmOkpd onAllRequestsComplete={handleCloseModal}/>
                }
                buttons={[
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                    dispatch(resetOkpdSelecteData())
                    dispatch(fetchOkpdUserData());
                }}
            />
        );
        setIsFullCentered(false)
        setIsCentered(true);
        setIsModalVisible(true);
    };

    const handleDeleteOkpd = (okpdCode, okpdName, event) => {
        event.stopPropagation();
        const deleteButtonStyle = {
            backgroundColor: 'red',
        };
        dispatch(setFormValidity(true));

        const confirmDeleteAndClose = () => {
            confirmDeleteOkpd(okpdCode)
            setIsCentered(false);
            setIsModalVisible(false);
        };
        const cancelAndClose = () => {
            setIsCentered(false);
            setIsModalVisible(false);
        };
        setModalContent(
            <CustomModalContent
                title="Подтвердите ваши действия!"
                content={`Вы точно хотите удалить ОКПД: ${okpdName} ?`}
                buttons={[
                    {
                        text: "Да",
                        onClick: confirmDeleteAndClose,
                        style: deleteButtonStyle
                    },
                    {
                        text: "Отмена",
                        onClick: cancelAndClose
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );

        setIsCentered(true);
        setIsModalVisible(true);
    };

    const confirmDeleteOkpd = async (okpdCode) => {
        try {
            await deleteUserOkpd(okpdCode);
            setIsModalVisible(false);
            dispatch(fetchOkpdUserData());
        } catch (error) {
            console.error('Ошибка при удалении ОКПД:', error);
        }
    };


    return (
        <>
            <div className={style.availableContainer}>
                <div className={style.plusContainer} onClick={handleAddGroupClick}>
                    <img className={style.plusIco} src={plus} alt="Добавить"/>
                    <span>
                        Добавить
                    </span>
                </div>
            </div>
            {okpdListData && okpdListData.length > 0 ? (
                <div className={style.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={`${style.tableCellText} ${style.column1}`}>Код ОКПД</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.column2}`}>Название ОКПД</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.column3}`}>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {okpdListData && okpdListData.length > 0 && okpdListData.map((okpd, index) => (
                                <TableRow key={index}>
                                    <TableCell className={style.tableCellText}>{okpd.okpd_code}</TableCell>
                                    <TableCell className={style.tableCellText}>{okpd.okpd_name}</TableCell>
                                    <TableCell>
                                        <div className={`${style.icoContainer} ${style.soloIco}`}>
                                            <div onClick={(e) => handleDeleteOkpd(okpd.okpd_code, okpd.okpd_name, e)}>
                                                <img className={style.icoTest} src={trash} alt="trashIco" />
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div className={style.centerContent}>
                    У вас еще нет добавленных ОКПД
                </div>
            )}
        </>
    )
}

export default OkpdList
