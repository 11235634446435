import React, {useEffect, useRef, useState} from 'react';
import styles from './confirmOkpd.module.css';
import btnStyle from "../../CustomModalChanger/CustomModal.module.css";
import { useSelector } from "react-redux";
import {addOkpdNotInGroup} from "../../../api/api";
import CircularProgress from '@mui/material/CircularProgress';

const ConfirmOkpd = ({ onAllRequestsComplete }) => {
    const [isSending, setIsSending] = useState(false);
    const selectedData = useSelector((state) => state.okpdSelectedDataSlice.selectedData);
    const okdpDataRef = useRef(selectedData);

    useEffect(() => {
        okdpDataRef.current = selectedData;
    }, [selectedData]);

    const dataArray = okdpDataRef.current ? Object.entries(okdpDataRef.current) : [];

    const handleSave = async () => {
        setIsSending(true);
        for (const [key] of dataArray) {
            try {
                await addOkpdNotInGroup({ okpd: key });
            } catch (error) {
                setIsSending(false);
                return;
            }
        }
        setIsSending(false);
        onAllRequestsComplete();
    };

    return (
        <div className={styles.confirmContainer}>
            <div>
                {dataArray.length > 0 ? (
                    dataArray.map(([key, value], index) => (
                        <div key={index}>
                            <span>{key}. {value}</span>
                        </div>
                    ))
                ) : (
                    <p>Нет выбранных элементов</p>
                )}
            </div>
            <button
                onClick={handleSave}
                disabled={isSending}
                className={`${btnStyle.customModalButton} ${btnStyle.singleButton} ${btnStyle.centerButton} ${isSending ? btnStyle.disabledButton : ''}`}
            >
                Сохранить
            </button>
            {isSending && (
                <div className={btnStyle.sendingContainer}>
                    <CircularProgress size={20} />
                    <span>Данные отправляются...</span>
                </div>
            )}
        </div>
    );
};

export default ConfirmOkpd;
