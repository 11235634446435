import React, {useEffect, useState} from 'react';
import localStorageService from "../../service/localStorage/localStorageService";
import {TreeNode} from "./RecurseTree/testTree";
import style from './OkpdTree.module.css'
import styles from '../../pages/DashPage/LineBarChart/LineBarChart.module.css'
import {useSelector} from "react-redux";
import {IconButton, TextField} from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

const OkpdTree = ({ hideLimit = false, unlimitedSelection = false }) => {
    const okpdData = localStorageService.getItem('okpdData') || [];
    const meData = useSelector((state) => state.meData.meData);
    const okpdListData = useSelector((state) => state.okpdUserData.okpdUserData);
    const limitOkpd = meData.limit_okpd || 0;
    const [selectedCount, setSelectedCount] = useState(0);
    const availableLimit = limitOkpd - (okpdListData ? okpdListData.length : 0);
    const [filter, setFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    useEffect(() => {
        let filtered = okpdData;
        if (filter) {
            filtered = filterData(okpdData, filter);
        }
        setFilteredData(filtered);
        // eslint-disable-next-line
    }, [filter, okpdData]);

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    const filterData = (data, filter) => {
        return data.filter(item => {
            const codeMatch = item.okpd_code.toLowerCase().includes(filter.toLowerCase());
            const nameMatch = item.okpd_name.toLowerCase().includes(filter.toLowerCase());

            if (item.children && item.children.length > 0) {
                item.children = filterData(item.children, filter);
            }

            return codeMatch || nameMatch || (item.children && item.children.length > 0);
        });
    };

    const handleSelectChange = (isSelected) => {
        if (isSelected) {
            if (selectedCount < availableLimit) {
                setSelectedCount(prevCount => prevCount + 1);
            }
        } else {
            setSelectedCount(prevCount => prevCount - 1);
        }
    };

    const handleClear = () => {
        setFilter('');
    };

    return (
        <div className={style.okpdContainer}>
            <div className={style.counter}>
                <div>
                    <TextField
                        InputProps={{
                            endAdornment: (
                                filter && (
                                    <IconButton
                                        onClick={handleClear}
                                        style={{ padding: '5px' }}
                                    >
                                        <ClearIcon style={{ fontSize: '20px', color: '#8D96B2' }} />
                                    </IconButton>
                                )
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                borderRadius: '6px',
                                backgroundColor: '#F7FAFC',
                                '& fieldset': {
                                    borderColor: '#8D96B2',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#C8A1FF',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#4A3DFD',
                                },
                                '& .MuiInputBase-input': {
                                    padding: '8px',
                                },
                            },
                            '& .MuiInputLabel-root': {
                                fontFamily: 'Golos Regular',
                                fontSize: '14px',
                            },
                            '& .MuiInputBase-input': {
                                fontFamily: 'Golos Regular',
                                fontSize: '14px',
                            },
                            '& .MuiFormHelperText-root': {
                                fontFamily: 'Golos Regular',
                                fontSize: '14px',
                            },
                        }}
                        className={style.formControl}
                        placeholder="Введите полностью или часть наименования товара, работы, услуги или код ОКПД2"
                        value={filter}
                        onChange={handleFilterChange}
                    />
                </div>
                {!hideLimit && (
                    <div className={`${styles.legendAddition}`}>Вы выбрали {selectedCount}/{availableLimit} ОКПД</div>
                )}
            </div>
            <div className={style.scrollableList}>
                <ul>
                    {filteredData.map(item => (
                        <TreeNode
                            key={item.okpd_code}
                            data={item}
                            onSelectChange={handleSelectChange}
                            limitReached={!unlimitedSelection && selectedCount >= availableLimit}
                            filter={filter}
                            highlightText={filter}
                            hideLimit={hideLimit}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default OkpdTree;
