export const findOkpdNameByCode = (okpdCode, okpdData) => {
    for (const item of okpdData) {
        if (item.okpd_code === okpdCode) {
            return item.okpd_name;
        }
        if (item.children) {
            const foundName = findOkpdNameByCode(okpdCode, item.children);
            if (foundName) return foundName;
        }
    }
    return null;
};

export const findProductOKPDNameByCode = (okpdCode, okpdData) => {
    for (const item of okpdData) {
        if (item.NewOKPDCode === okpdCode) {
            return item.ProductOKPDName;
        }
    }
    return null;
};
