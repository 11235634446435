import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedOrganization: "",
    relatedINNs: "",
    searchOrgINNINNs: [],
    searchSuppINNINNINNs: [],
    groups: [],
    selectedValue: "",
    selectedSuggestion: {},
    selectedComboItem: {},
};

const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setSelectedComboItem: (state, action) => {
            state.selectedComboItem = action.payload;
        },
        setSelectedOrganization: (state, action) => {
            state.selectedOrganization = action.payload;
        },
        setSelectedSuggestion: (state, action) => {
            state.selectedSuggestion = action.payload;
        },
        setRelatedINNs: (state, action) => {
            state.relatedINNs = action.payload;
        },
        setSearchOrgINNINNs: (state, action) => {
            state.searchOrgINNINNs = action.payload;
        },
        setSearchSuppINNINNINNs: (state, action) => {
            state.searchSuppINNINNINNs = action.payload;
        },
        setGroupsRed: (state, action) => {
            state.groups = action.payload;
        },
        setSelectedValue: (state, action) => {
            state.selectedValue = action.payload;
        },
        resetSelectedValue: (state) => {
            state.selectedValue = '';
        },
        resetSearchOrgINNINNs: (state) => {
            state.searchOrgINNINNs = [];
        },
        resetSearchSuppINNINNINNs: (state) => {
            state.searchSuppINNINNINNs = [];
        },
        resetSelectedOrganization: (state) => {
            state.selectedOrganization = "";
        },
        resetRelatedINNs: (state) => {
            state.relatedINNs = "";
        },
        resetSelectedSuggestion: (state) => {
            state.selectedSuggestion = {};
        },
        resetSelectedComboItem: (state) => {
            state.selectedComboItem = {};
        }

    }
});

export const {resetSelectedComboItem, setSelectedComboItem, resetSelectedSuggestion,setSelectedSuggestion,setSelectedValue,resetSelectedValue,setGroupsRed,resetRelatedINNs,resetSelectedOrganization,resetSearchSuppINNINNINNs, resetSearchOrgINNINNs, setSearchSuppINNINNINNs,setSearchOrgINNINNs,setSelectedOrganization, setRelatedINNs } = organizationSlice.actions;
export default organizationSlice.reducer;
