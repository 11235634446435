import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchContractOkpd} from '../../../../api/api.js';

const initialState = {
    selectedOkpd: [],
    odkpData: [],
    loading: 'idle',
    error: null,
    isInsideActive: false,
    trimCode: [],
    contractOkpdName: [],
};

export const fetchContractOkpdData = createAsyncThunk(
    'contractOkpd/fetchContractOkpdData',
    async (options) => {
        const response = await fetchContractOkpd(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const contractOkpdChartSlice = createSlice({
    name: 'contractOkpd',
    initialState,
    reducers: {
        addContractStackedName: (state, action) => {
            state.contractOkpdName = [action.payload];
        },
        setSelectedProduct: (state, action) => {
            const productName = action.payload;
            if (state.selectedOkpd.includes(productName)) {
                state.selectedOkpd = state.selectedOkpd.filter(name => name !== productName);
            } else {
                state.selectedOkpd.push(productName);
            }
        },
            clearSelectedContractProduct: (state) => {
                state.selectedOkpd = [];
            },
        resetStore: (state) => {
            const { isInsideActive } = state;
            return { ...initialState, isInsideActive };
        },
        toggleInsideActive: (state) => {
            state.isInsideActive = !state.isInsideActive;
        },
        resetInsideActive: (state) => {
            state.isInsideActive = false;
        },
        setContractTrimCode: (state, action) => {
            state.trimCode = action.payload;
        },
        clearContractTrimCode: (state) => {
            state.trimCode = [];
        },
        resetContractOkpd: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContractOkpdData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchContractOkpdData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.odkpData = action.payload;
                }
            })
            .addCase(fetchContractOkpdData.rejected, (state, action) => {
                state.loading = 'idle';
                state.error = action.error.message;
            })
    }
});


export const {addContractStackedName,resetContractOkpd, setContractTrimCode, clearContractTrimCode, setSelectedProduct, clearSelectedContractProduct } = contractOkpdChartSlice.actions;
export default contractOkpdChartSlice.reducer;
