import React, {useState} from "react";
import PieChart from "../PieD/PieTestD";
import StackedBarChart from "../TestStackedD/TestStackedBarChart";
import { GeoChart } from "../TestMapD/GeoChart";
import {BarLineChart} from "../LineBarChart/Notice/LineBarChart";
import Activity from "../ActivityDiagram/Activity";
import Summary from "../SummaryD/Summary";
import ManyLineD from "../ManyLineChart/ManyLineChart";
import TreeMapDiagramm from "../TesTreeD/TreeDiagramm";
import TopDiagramm from "../DonutD/TopChart";
import RoleDiagramm from "../DonutD/ResponsableRoleChart";
import {useDispatch, useSelector} from "react-redux";
import DraggableDiagram from "./DraggableDiagram";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import {swapDiagrams} from "../../../service/reducers/DiagrammConfig/DiagramConfigSlice";
import ContractStackedBarChart from "../ContractStackedChart/StackedLineChart";
import {ContractBarLineChart} from "../LineBarChart/Contracts/ContractLineBarChartV1";
import IndicatorDiagramm from "../IndicatorDiagram/indicatorDiagram";
import SunDiagramm from "../SunkeyChart/SunKeyChart";
import BubbleDiagramm from "../TestBubbleD/BubbleDiagramm";
import SunBurstDiagramm from "../SunBurstChart/SunBurstChart";
import IspStackedBarChart from "../ContractStackedChart/StackedLIneShort";
import {Helmet} from "react-helmet";
import {updatePageTitle} from "../../../utils/getTitleName";
import ManyLineMonthD from "../ManyLineKMonthChart/ManyLineMonthChart";

const DContainer = () => {
    const dispatch = useDispatch();
    const [zoomedDiagram, setZoomedDiagram] = useState(null);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const { diagrams, gridTemplateAreas } = useSelector((state) => state.dConfig[activeTab]);

    const handleZoomClick = (type) => {
        setZoomedDiagram((prevType) => (prevType === type ? null : type));
    };

    const componentsMap = {
        'Summary': Summary,
        'PieChart': PieChart,
        'TopDiagramm': TopDiagramm,
        'StackedBarChart': StackedBarChart,
        'Activity': Activity,
        'SoloLineD': ManyLineMonthD,
        'GeoChart': GeoChart,
        'BarLineChart': BarLineChart,
        'ManyLineD': ManyLineD,
        'RoleDiagramm': RoleDiagramm,
        'TreeMapDiagramm': TreeMapDiagramm,
        'ContractStackedBarChart': ContractStackedBarChart,
        'ContractPayMonth': ContractBarLineChart,
        'Indicator': IndicatorDiagramm,
        'SunKey': SunDiagramm,
        'Scatter': BubbleDiagramm,
        'SunBurst': SunBurstDiagramm,
        'ispOkpd' : IspStackedBarChart,
    };
    const renderDiagrams = () => {
        if (zoomedDiagram) {
            const Component = componentsMap[zoomedDiagram];
            if (Component) {
                return (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateRows: '1fr',
                            width: '100%',
                        }}
                    >
                        <Component
                            onZoomClick={() => handleZoomClick(zoomedDiagram)}
                            zoomedDiagram={zoomedDiagram}
                        />
                    </div>
                );
            }
        }

        return (
            <div
                style={{
                    display: 'grid',
                    gridTemplateAreas: gridTemplateAreas,
                    gridTemplateColumns: 'minmax(80px, 1fr) minmax(80px, 1fr) minmax(80px, 1fr)',
                    gridTemplateRows: '1fr 1fr 1fr',
                    gap: '10px',
                    width: '100%',
                }}
            >
                {diagrams.map(({ type, gridArea }) => {
                    const Component = componentsMap[type];
                    return (
                        <div key={type} style={{ gridArea }}>
                            <DraggableDiagram
                                type={type}
                                Component={Component}
                                onZoomClick={handleZoomClick}
                                moveDiagram={moveDiagram}
                            />
                        </div>
                    );
                })}
            </div>
        );
    };
    const moveDiagram = (fromType, toType) => {
        dispatch(swapDiagrams({ fromType, toType, tabName: activeTab }));
    };

   /* const exportPDF = async () => {
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'px',
            format: 'a3'
        });
        const charts = document.querySelectorAll('.my-svg-diagram');

        for (const chart of charts) {
            const canvas = await html2canvas(chart);
            const imgData = canvas.toDataURL('image/png');

            let width, height;
            if (chart.classList.contains('small-chart')) {
                width = 320;
                height = 344;
            } else if (chart.classList.contains('large-chart')) {
                width = 650;
                height = 344;
            }

            pdf.addImage(imgData, 'PNG', 10, 10, width, height);

            if (chart !== charts[charts.length - 1]) {
                pdf.addPage();
            }
        }


        pdf.save("diagrams.pdf");
    };*/


    return (
        <div>
            <Helmet>
                <title>{updatePageTitle(`Дашборд (${activeTab})`)}</title>
            </Helmet>
            <DndProvider backend={HTML5Backend}>
                {renderDiagrams()}
            </DndProvider>
        </div>
    );
};

export default DContainer;
