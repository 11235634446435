import React from 'react';
import { useSelector } from "react-redux";
import style from '../Tarrifs/Tariffs.module.css';
import PersonalSettingsTab from "./SettingsTab/PersonalSettingsTab";
import Account from "./SettingsTabData/Accounts/Accounts";
import Groups from "./SettingsTabData/Groups/Groups";
import OkpdList from "./SettingsTabData/OkpdList/OkpdList";
import InnList from "./SettingsTabData/InnList/InnList";
import Notifications from "./SettingsTabData/Notifications/Notifications";
import styles from './PersonalSettings.module.css'
import {selectPrivileges} from "../../../service/reducers/PrivelegiesSlice";

const components = {
    'Учетные Записи': Account,
    'Группы': Groups,
    'ОКПД': OkpdList,
    'Компании': InnList,
    'Уведомления': Notifications
};

const tabPrivileges = {
    'Учетные Записи': 'users.view',
    'Группы': 'groups.view'
};

const PersonalSettingsContainer = () => {
    const privileges = useSelector(selectPrivileges);
    const activeSettingsTab = useSelector((state) => state.settingsTabs.activeSettingsTab);

    const availableComponents = Object.keys(components).reduce((acc, key) => {
        if (!tabPrivileges[key] || privileges.includes(tabPrivileges[key])) {
            acc[key] = components[key];
        }
        return acc;
    }, {});

    const ActiveComponent = availableComponents[activeSettingsTab] || null;

    return (
        <div className={style.containerList}>
            <PersonalSettingsTab availableTabs={Object.keys(availableComponents)} />
            <div className={styles.tabDataContainer}>
                {ActiveComponent && <ActiveComponent />}
            </div>
        </div>
    );
}

export default PersonalSettingsContainer;
