
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    data: {}
};

export const formDataSlice = createSlice({
    name: 'formData',
    initialState,
    reducers: {
        setFormData: (state, action) => {
            state.data = action.payload;
        },
        resetFormData: (state, action) => {
            state.data = {}
        }
    },
});

export const selectFormData = (state) => state.formDataSlice.data;

export const { resetFormData, setFormData } = formDataSlice.actions;
export default formDataSlice.reducer;
