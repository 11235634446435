
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getUserGroups} from "../../api/api";

const groupsDataInitialState = {
    groupsData: [],
    isLoadingGroupsData: false,
    groupsDataError: null,
};

export const fetchGroupsData = createAsyncThunk(
    'groupsData/fetchGroupsData',
    async () => {
        const response = await getUserGroups();
        return response;
    }
);

const groupsData = createSlice({
    name: 'groupsData',
    initialState: groupsDataInitialState,
    reducers: {
        clearGroupsData: (state) => {
            state.groupsData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroupsData.pending, (state) => {
                state.isLoadingGroupsData = 'pending';
            })
            .addCase(fetchGroupsData.fulfilled, (state, action) => {
                state.isLoadingGroupsData = 'successful';
                state.groupsData = action.payload;
            })
            .addCase(fetchGroupsData.rejected, (state, action) => {
                state.isLoadingGroupsData = 'failed';
                state.groupsDataError = action.error.message;
            })
    },
});

export const { clearGroupsData } = groupsData.actions;

export default groupsData.reducer;
