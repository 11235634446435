import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchActivity} from "../../../api/api";

const initialState = {
    ActivityData: [],
    loading: 'idle',
    error: null,
};

export const fetchActivityData = createAsyncThunk(
    'activity/fetchActivityData',
    async (args) => {
        const newOKPDCode = args.selectedProduct.map(item => item.productId);
        const options = {...args, newOKPDCode};
        const response = await fetchActivity(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);


const activityChartSlice = createSlice({
    name: 'activityChart',
    initialState,
    reducers: {
        resetActivityData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActivityData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchActivityData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.ActivityData = action.payload;
            })
            .addCase(fetchActivityData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetActivityData } = activityChartSlice.actions;

export default activityChartSlice.reducer;
