import { useState } from "react";
import { FaChevronDown, FaChevronRight } from 'react-icons/fa';
import styles from "./Tree.module.css";
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import {updateSelectedData} from "../../../service/reducers/okpdTreeSelected";
import {useDispatch} from "react-redux";
import {removeParam, setParam} from "../../../service/reducers/FilterSearchSlice";

export const CustomCheckbox = styled(Checkbox)(() => ({
    color: '#D0D4DA',
    padding: 0,
    '&.Mui-checked': {
        color: '#4A3DFD',
    },
    '& .MuiSvgIcon-root': {
        fontSize: '1.2rem',
    },
}));

export const TreeNode = ({ data, onSelectChange, limitReached, filter, hideLimit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren = data.children && data.children.length > 0;
    const [isChecked, setIsChecked] = useState(false);
    const dispatch = useDispatch();
    const renderHighlightedText = (text, highlight) => {
        if (!text || !highlight) return <span>{text}</span>;

        const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
        return <span> {parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span style={{ backgroundColor: 'yellow' }}>{part}</span> : part)} </span>;
    };

    const handleCheckboxChange = (e) => {
        const newIsChecked = !isChecked;
        setIsChecked(newIsChecked);
        onSelectChange(newIsChecked);
        dispatch(updateSelectedData({ okpd_code: data.okpd_code, okpd_name: data.okpd_name }));
        if (!limitReached) {
            if (newIsChecked) {
                dispatch(setParam({ key: 'selectedOkpdCodes', value: data.okpd_code }));
            } else {
                dispatch(removeParam({ key: 'selectedOkpdCodes', value: data.okpd_code }));
            }
        }
    };
    const handleIconClick = (e) => {
        e.stopPropagation();
        if (hasChildren) {
            setIsOpen(!isOpen);
        }
    };

    return (
        <li className={styles.treeNode}>
            <div className={styles.treeNodeHeader} style={{ color: limitReached ? '#8D96B2' : 'inherit' }}>
                <div
                    onClick={hasChildren ? handleIconClick : undefined}
                    className={styles.iconWrapper}
                >
                    {hasChildren ? (isOpen ? <FaChevronDown /> : <FaChevronRight />) : <span className={styles.placeholderIcon}></span>}
                </div>
                <CustomCheckbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    disabled={limitReached && !isChecked}
                />
                <span className={styles.okpdCode}>{data.okpd_code}</span> {renderHighlightedText(data.okpd_name, filter)}
            </div>
            {hasChildren && isOpen && (
                <ul className={styles.childrenList}>
                    {data.children.map(child => (
                        <TreeNode
                            key={child.okpd_code}
                            data={child}
                            onSelectChange={onSelectChange}
                            limitReached={limitReached}
                            filter={filter}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};
