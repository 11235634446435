import React, {useEffect, useState} from 'react';
import styles from './MainInputsContainer.module.css';
import DynamicForm from "../InputForm/InputForm";
import axios from "axios";
import TarrifContainer from "../Tarrifs/TarrifContainer";
import Circle from "../Circle/Circle";
import f2 from "../../../../common/HeaderPeach.png";
import stylesC from '../SegmentPart/Circle.module.css';
import f1 from "../../../../common/HeaderBlue.webp";
const MainInputsContainer = ({ selectedType }) => {
    const formType = selectedType === 'phiz' ? 'individual' : 'legal';
    const [tariffs, setTariffs] = useState([]);
    const [selectedTariffPrice, setSelectedTariffPrice] = useState(0);

    useEffect(() => {
        const fetchTariffs = async () => {
            try {
                const response = await axios.get('https://tariffs-goszakupki.agatha.pw/tariffs');
                setTariffs(response.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchTariffs();
    }, []);

    const handleTariffChange = (price) => {
        setSelectedTariffPrice(price);
    };
    return (
        <div className={styles.mainIputsContainer}>
            <div>
                <DynamicForm type={formType} tariffPrice={selectedTariffPrice} />
                <Circle className={`${stylesC.gradientPeach} ${stylesC.rightCircle}`} src={f2} alt='f2'/>
            </div>
            <div>
                <TarrifContainer data={tariffs.result} onTariffChange={handleTariffChange} />
                <Circle className={`${stylesC.gradientCircleBlue} ${stylesC.headerCircle}`} src={f1} alt='f1'/>
            </div>
        </div>
    );
};

export default MainInputsContainer;
