import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSwitch: 'sun',
};

const switcherSlice = createSlice({
    name: 'switcher',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeSwitch = action.payload;
        }
    }
});

export const { setActiveTab } = switcherSlice.actions;
export default switcherSlice.reducer;
