import React, {useState} from 'react';
import styles from './testPagePayment.module.css'
import HeaderT from "./HeaderT/HeaderT";
import CircleSelector from "./SegmentPart/Segment";
import MainInputsContainer from "./MainInputs/MainInputsContainer";
import FeedBackContainer from "./PaymentFeedBack/PaymentFeedback";
const TestP = () => {
    const [selectedType, setSelectedType] = useState('none');

    return (
        <div className={styles.appContainer}>
            <HeaderT/>
            <CircleSelector selected={selectedType} setSelected={setSelectedType}/>
            <MainInputsContainer selectedType={selectedType} />
            <FeedBackContainer/>
            <HeaderT/>
        </div>
    );
};

export default TestP;
