import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from '../../service/reducers/SwitcherSlice';
import styles from './Switcher.module.css';
import sun from "../../common/icons/sun.svg";
import moon from "../../common/icons/moon.svg";
import sunDark from "../../common/icons/sundark.svg";
import {useModal} from "../../hook/useModal";

const Switcher = ({ width }) => {
    const active = useSelector(state => state.switcher.activeSwitch);
    const dispatch = useDispatch();
    const { isModalVisible } = useModal();

    const handleTabChange = (newTab) => {
        dispatch(setActiveTab(newTab));
    };

    if (isModalVisible) {
        return null;
    }

    const sunIcon = active === 'moon' ? sunDark : sun;

    return (
        <div
            className={styles.switcherContainer}
            style={{ width: width }}
        >
            <div className={styles.innerContainer}
                 style={{ width: width }}>
                <div
                    onClick={() => handleTabChange('sun')}
                    className={`${styles.switcherItem} ${active === 'sun' ? styles.active : styles.inactive}`}
                >
                    <img src={sunIcon} alt="Sun" />
                </div>
                <div
                    onClick={() => handleTabChange('moon')}
                    className={`${styles.switcherItem} ${active === 'moon' ? styles.active : styles.inactive}`}
                >
                    <img src={moon} alt="Moon" />
                </div>
            </div>
        </div>
    );
};

export default Switcher;
