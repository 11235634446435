export const useVirtualTooltipSize = (className, textGenerator) => {
    const cache = new Map();

    const calculateTooltipSize = (text) => {
        if (!text || (Array.isArray(text) && text.length === 0)) {
            return { width: 0, height: 0, visibility: 'hidden' };
        }

        const textKey = JSON.stringify(text);
        if (cache.has(textKey)) {
            return cache.get(textKey);
        }

        const div = document.createElement("div");
        div.className = className;
        div.style.visibility = "hidden";
        div.style.position = "fixed";

        div.innerHTML = textGenerator(text);

        document.body.appendChild(div);

        const { offsetWidth, offsetHeight } = div;

        document.body.removeChild(div);

        const size = { width: offsetWidth, height: offsetHeight };
        cache.set(textKey, size);

        return size;
    };

    return calculateTooltipSize;
};
