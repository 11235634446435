import React, {useEffect, useMemo, useRef, useState} from 'react';
import * as d3 from 'd3';
import style from "../TesTreeD/TreeMapDiagramm.module.css";
import {useDispatch, useSelector} from "react-redux";
import {activeColors} from "../../../utils/colors";
import HeaderDiagram from "../HeaderD/HeaderDiagram";
import icons from "../../../common/icons/icons";
import Legend from "../../../components/DiagrammLegend/Legend";
import useResizeObserver from 'use-resize-observer';
import Spinner from "../../TestPages/Spinner";
import {
    fetchDonutRoleData,
    setRoleMode,
    setshowCountRespRole
} from "../../../service/reducers/DonutChart/DonutChartRole";
import styleTooltip from "../TestMapD/GeoChart.module.css";
import {formatCurrency} from "../../../utils/rubbleFunc";
import {addRoleChartName, toggleSegment} from "../../../service/reducers/DonutChart/DonutRoleSegmentSlice";
import {useVirtualTooltipSize} from "../../../hook/useVirtualTooltipSize";
import styles from "../TestMapD/GeoChart.module.css";
import {setActiveLegendItem} from "../../../service/reducers/legendItemsClick";
import {cancelAllPendingRequests} from "../../../api/api";
import {convertRangeDateToStartDTFormat} from "../../../utils/convertRangeDate";
import tooltipNames from "../../../utils/tooltipTitles.json"

const RoleDiagramm = ({ onZoomClick, zoomedDiagram }) => {
    const dispatch = useDispatch();
    const ref = useRef();
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const { width, height } = useResizeObserver({ ref });
    const relatedINNs = useSelector(state => state.organization.relatedINNs);
    const { RespRoleData, loading, showCountRespRole, roleMode} = useSelector((state) => state.respRole);
    const activeRegions = useSelector((state) => state.region.activeRegions);
    const slidePoz = useSelector(state => state.searchSwitcher.position);
    const searchOrgINNINNs = useSelector(state => state.organization.searchOrgINNINNs);

    const selectedOkpd = useSelector((state) => state.contractOkpd.selectedOkpd);
    const selectedProduct = useSelector((state) => state.productCode.selectedProduct);
    const trimCode = useSelector((state) => state.productCode.trimCode);
    const selectedCountryLine = useSelector((state) => state.ispOkpd.selectedOkpd);

    const searchSuppINNINNINNs = useSelector(state => state.organization.searchSuppINNINNINNs);
    const pieState = useSelector((state) => state.pie.selectedSlice) || [];
    const {selectedSegments} = useSelector((state) => state.treeMapSlice);
    const top = useSelector((state) => state.activitySlice);
    const { selectedMonth } = useSelector((state) => state.barLineChartMonth)
    const contractTrimCode = useSelector((state) => state.contractOkpd.trimCode);
    const activeTab = useSelector((state) => state.tabs.activeTab);
    const filterOkpd = useSelector((state) => state.okpdComboSelect.okpdComboData);
    const storedDates = localStorage.getItem('dateForPickers');
    const dates = JSON.parse(storedDates);
    const RangeDT = convertRangeDateToStartDTFormat(dates);
    const isLoadingMenu = useSelector(state => state.menu.isLoadingMenu);
    const dateChanger = useSelector(state => state.dateSlice.selectedDate);
    const topBody = {
        Advantages: top.Advantages,
        Restrictions: top.Restrictions,
        Requirements: top.Requirements,
    };
    const selectedDonutSegmetsV1 = useSelector(state => state.donutRolesSlice.selectedSegments);
    const { selectedContractMonth } = useSelector((state) => state.contractMonth1Slice);
    const selectedOrganization = useSelector(state => state.organization.selectedOrganization);
    const shouldShowChangeButton = selectedOrganization.type === 'okpd' || filterOkpd.length > 0;
    const selectedKbrSegments = useSelector(state => state.donutKbrSegmentSlice.selectedKbrSegments);
    const selectedZoomableSegment = useSelector(state => state.segmentNameSlice.currentSegmentName);
    const procedureRegNum = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const bubbleSegments = useSelector(state => state.bubbleSegmentSlice.bubbleSelectedSegments);
    const selectedTreeMapLabels = useMemo(() => {
        return selectedSegments.map(segment => segment.label);
    }, [selectedSegments]);

    const regNumArray = useMemo(() => {
        return bubbleSegments.map(segment => segment.regNum);
    }, [bubbleSegments]);
    const getHeaderTitle = (activeTab, showCountRespRole, selectedOrganizationType) => {
        if (activeTab === 'Контракты' || activeTab === 'Исполнение') {
            if (selectedOrganizationType === 'company_customer' || slidePoz === 'supplier') {
                return `Структура исполнителей по ОПФ от ${showCountRespRole === 'count' ? "(кол-ва)" : "(суммы)"}`;
            } else if (selectedOrganizationType === 'company_suppliers' || slidePoz === 'customer') {
                return `Структура заказчиков по ОПФ от ${showCountRespRole === 'count' ? "(кол-ва)" : "(суммы)"}`;
            }
        }
        switch (activeTab) {
            case 'Извещения':
                return `Структура ролей организации в закупках от ${showCountRespRole === 'count' ? "(кол-ва)" : "(суммы)"}`;
            case 'Контракты':
                return `Структура поставщиков по ОПФ от ${showCountRespRole === 'count' ? "(кол-ва)" : "(суммы)"}`;
            case 'Исполнение':
                return `Структура поставщиков по ОПФ от ${showCountRespRole === 'count' ? "(кол-ва)" : "(суммы)"}`;
            default:
                return 'Unknown Tab';
        }
    }

    const headerTitle = getHeaderTitle(activeTab, showCountRespRole, selectedOrganization.type);
    const headerIcons = [
        { name: 'zoom', icon: zoomedDiagram === undefined ? icons.zoom : icons.zoomOut, width: 20, height: 20, onClick: onZoomClick },
        ...(shouldShowChangeButton ? [{ name: 'change', icon: icons.change, width: 20, height: 20 }] : []),
        { name: 'menu', icon: icons.menu, width: 20, height: 20 },
    ];

    const headerWithThreeButtons = {
        title: headerTitle,
        icons: headerIcons
    };

    useEffect(() => {
        let requestData = {
            relatedINNs,
            selectedProduct,
            activeRegions,
            pieState,
            topBody,
            filterOkpd,
            trimCode,
            selectedTreeMapLabels,
            selectedMonth,
            activeTab,
            RangeDT,
            selectedOkpd,
            contractTrimCode,
            selectedContractMonth,
            selectedCountryLine,
            selectedKbrSegments,
            selectedZoomableSegment,
            regNumArray,
            ...(slidePoz === 'customer' ? { searchSuppINNINNINNs } : { searchOrgINNINNs })
        };

        if (shouldShowChangeButton && activeTab === 'Исполнение') {
            const isCustomer = roleMode === '' || roleMode === 'cust';
            requestData = { ...requestData, isCustomer };
        }

        if (selectedOrganization.type === 'company_customer' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedTreeMapLabels = selectedTreeMapLabels;
            requestData.activeRegions = activeRegions;
            requestData.selectedDonutSegmetsV1 = selectedDonutSegmetsV1;
        } else if (selectedOrganization.type === 'company_suppliers' && (activeTab === 'Контракты' || activeTab === 'Исполнение')) {
            requestData.selectedCustSegments = selectedTreeMapLabels;
            requestData.activeRegionsCust = activeRegions;
            requestData.selectedDonutCust = selectedDonutSegmetsV1;
        }

        dispatch(fetchDonutRoleData(requestData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [procedureRegNum,selectedZoomableSegment,selectedKbrSegments,filterOkpd,dateChanger,selectedProduct,roleMode,activeRegions, pieState, top, trimCode, selectedSegments, selectedMonth, relatedINNs, selectedOkpd, contractTrimCode, selectedContractMonth, selectedCountryLine, searchOrgINNINNs, searchSuppINNINNINNs, slidePoz]);


    useEffect(() => {
        if ( loading === 'successful' && width && height && RespRoleData) {
            createDonutChart(RespRoleData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height, RespRoleData, showCountRespRole, selectedDonutSegmetsV1]);

    const onSegmentClick = (event, d) => {
        cancelAllPendingRequests()
        onMouseOut();
        const segmentId = d.data.label;
        dispatch(toggleSegment(segmentId));
        dispatch(addRoleChartName(headerTitle));
        dispatch(setActiveLegendItem({ diagramId: headerTitle, activeItem: segmentId }));
    };

    const onLegendItemClick = (label) => {
        cancelAllPendingRequests()
        onMouseOut();
        dispatch(toggleSegment(label));
        dispatch(addRoleChartName(headerTitle));
    };

    const calculateTooltipSize = useVirtualTooltipSize(styles.tooltip, (text) => {
        return text.map(item => (
            `<div><strong>${item.label}</strong>: ${item.value}</div>`
        )).join('');
    });
    const onMouseMove = (event, d) => {
        const mode = showCountRespRole === 'count' ? 'count' : 'sum';
        const commonConfig = tooltipNames['DonutRolesNotification']['Tabs'][activeTab]['common'];
        const specificConfig = tooltipNames['DonutRolesNotification']['Tabs'][activeTab][mode];
        let tooltipText = [];
        tooltipText.push({ label: commonConfig['name'], value: d.data.label });
        Object.entries(specificConfig).forEach(([key, russianKey]) => {
            if (key === "volume") {
                tooltipText.push({ label: russianKey, value: formatCurrency(d.data.value) });
            } else if (key === "quantity") {
                const countInfo = d.data.extra.find(x => x.label === 'count');
                tooltipText.push({ label: russianKey, value: countInfo ? `${countInfo.value} шт` : 'Н/Д' });
            }
        });

        let tooltipSize = calculateTooltipSize(tooltipText.map(item => `<div><strong>${item.label}</strong>: ${item.value}</div>`));
        let x = event.pageX + 10;
        let y = event.pageY + 10;
        if (x + tooltipSize.width > window.innerWidth) {
            x = event.pageX - tooltipSize.width - 10;
        }
        if (y + tooltipSize.height > window.innerHeight) {
            y = event.pageY - tooltipSize.height - 10;
        }

        setTooltip({ x, y, text: tooltipText });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };
    const createDonutChart = (data) => {
        d3.select(ref.current).selectAll("svg").remove();
        const svg = d3.select(ref.current).append("svg")
            .attr("width", width)
            .attr("height", height);
        let sortedData;
        let totalValue;
        const padAngle = 0.01;

        if (showCountRespRole === 'count') {
            totalValue = data.nodes.reduce((acc, cur) => acc + ((cur.extra && cur.extra[0].value) || 0), 0);
            sortedData = [...data.nodes].map(d => ({
                ...d,
                value: (d.extra && d.extra[0].value) || 0
            })).sort((a, b) => b.value - a.value);
        } else {
            totalValue = data.total;
            sortedData = [...data.nodes].sort((a, b) => b.value - a.value);
        }
        const radius = Math.min(width, height) * 0.35;
        const color = d3.scaleOrdinal().domain(sortedData.map(d => d.label)).range(activeColors);

        const pie = d3.pie().value(d => d.value).padAngle(0.01);
        const arcData = pie(sortedData);

        const fontSizeScale = d3.scaleLinear()
            .domain([100, 600])
            .range([10, 24]);

        const fontSize = fontSizeScale(radius);

        const arcGenerator = d3.arc()
            .innerRadius(radius * 0.7)
            .outerRadius(radius);

        const arcs = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .selectAll("path")
            .data(arcData)
            .enter()
            .append("path")
            .attr("fill", d => color(d.data.label))
            .attr("stroke", d => selectedDonutSegmetsV1.includes(d.data.label) ? "black" : "none")
            .attr("stroke-width", d => selectedDonutSegmetsV1.includes(d.data.label) ? 0.5 : 0)
            .attr("opacity", d => selectedDonutSegmetsV1.length === 0 || selectedDonutSegmetsV1.includes(d.data.label) ? 1 : 0.3)
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut)
            .on('click', (event, d) => onSegmentClick(event, d));

        arcs.transition()
            .duration(1000)
            .attrTween("d", arcTween(arcGenerator, padAngle));

        function arcTween(arcGenerator, padAngle) {
            return function(d) {
                const minSize = padAngle * 2;
                const i = d3.interpolate(d.startAngle + padAngle, Math.max(d.endAngle - padAngle, d.startAngle + minSize));
                return function(t) {
                    d.endAngle = i(t);
                    return arcGenerator(d);
                };
            };
        }
        // eslint-disable-next-line no-unused-vars
        let overlappingTexts = [];

        // eslint-disable-next-line no-unused-vars
        const text = svg.append("g")
            .attr("transform", `translate(${width / 2}, ${height / 2})`)
            .selectAll("text")
            .data(arcData)
            .enter()
            .append("text")
            .attr("font-size", `${fontSize}px`)
            .attr("font-family", "Golos Regular")
            .attr("transform", function(d) {
                const [x, y] = arcGenerator.centroid(d);
                return `translate(${x}, ${y})`;
            })
            .attr("text-anchor", "middle")
            .attr("fill", "white")
            .text(function(d) {
                const percentage = ((d.data.value / totalValue) * 100).toFixed(1);
                return percentage >= 4 ? `${percentage}%` : "";
            })
            .each(function(d) {
                const thisText = d3.select(this);
                thisText.text(`${((d.data.value / totalValue) * 100).toFixed(1)}%`);
                const bbox = thisText.node().getBBox();
                const textWidth = bbox.width;
                const angleDifference = d.endAngle - d.startAngle;
                const segmentWidth = angleDifference * radius;
                if (textWidth > segmentWidth) {
                    const [x, y] = arcGenerator.centroid(d);
                    thisText
                        .attr("transform", `translate(${x}, ${y - 20})`)
                        .attr("fill", "black");

                    overlappingTexts.push({
                        textElement: thisText,
                        value: d.data.value
                    });
                }
            });
        if (overlappingTexts.length > 1) {
            const maxText = d3.max(overlappingTexts, d => d.value);
            overlappingTexts.forEach(d => {
                if (d.value !== maxText) {
                    d.textElement.attr("visibility", "hidden");
                }
            });
        }
    };

    return (
        <div className={`${style.container} ${selectedDonutSegmetsV1.length > 0 ? style.selected : ''} ${zoomedDiagram ? style.zoomed : ''} my-svg-diagram`} style={zoomedDiagram ? { height: "600px" } : {}}>
            <div className={style.header}>
                <HeaderDiagram
                    {...headerWithThreeButtons}
                    onZoomClick={onZoomClick}
                    activeMode={activeTab === 'Исполнение' ? (roleMode || 'cust') : showCountRespRole}
                    handleMenuItemClick={(mode) => {
                        if (activeTab === 'Исполнение') {
                            if (mode === 'customer?') {
                                dispatch(setRoleMode('cust'));
                            } else if (mode === 'supplier?') {
                                dispatch(setRoleMode('org'));
                            } else {
                                dispatch(setRoleMode(""));
                            }
                        } else {
                            if (mode === 'sum') {
                                dispatch(setshowCountRespRole('sum'));
                            } else if (mode === 'count') {
                                dispatch(setshowCountRespRole('count'));
                            }
                        }
                    }}
                    diagramType={activeTab === 'Исполнение' ? "roleIsp" : ""}
                />
            </div>
            {(loading === 'pending' || loading === 'failed' || isLoadingMenu) ? (
                <Spinner />
            ) : (
                <>
                    <div className={style.header}>
                        <Legend diagramId={headerTitle} data={RespRoleData.nodes} dynamicRadius={zoomedDiagram ? 150 : 75} activeColors={activeColors} onLegendItemClick={onLegendItemClick} />
                    </div>
                    {tooltip.text && (
                        <div
                            ref={tooltipRef}
                            className={styleTooltip.tooltip}
                            style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                        >
                            {tooltip.text.map(item => (
                                <div key={item.label}>
                                    <strong className={styleTooltip.labelName}>{item.label}: </strong>{item.value}
                                </div>
                            ))}
                        </div>
                    )}
                    <div ref={ref} className={`${style.svgContainer} ${style.large}`}/>
                </>
            )}
        </div>
    );
};

export default RoleDiagramm;
