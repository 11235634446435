import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchBarLine} from "../../../api/api";

const initialState = {
    BarLineData: [],
    loading: 'idle',
    error: null,
};

export const fetchBarLineData = createAsyncThunk(
    'barLine/fetchBarLineData',
    async (options) => {
        try {
            options.newOKPDCode = options.selectedProduct.map(item => item.productId);
            const response = await fetchBarLine(options);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result;
        } catch (error) {
            throw error;
        }
    }
);


const barWithLineChartSlice = createSlice({
    name: 'barWithLineChart',
    initialState,
    reducers: {
        resetBarWithLineData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchBarLineData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchBarLineData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.BarLineData = action.payload;
                }
            })
            .addCase(fetchBarLineData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetBarWithLineData } = barWithLineChartSlice.actions;

export default barWithLineChartSlice.reducer;
