import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isFormValid: false,
};

const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        setFormValidity: (state, action) => {
            state.isFormValid = action.payload;
        }
    }
});

export const { setFormValidity } = modalSlice.actions;
export default modalSlice.reducer;
