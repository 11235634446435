import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {testSearch} from "../../api/api";

const initialState = {
    searchData: [],
    searchDataloading: 'idle',
    error: null,
};

export const fetchSearchZData = createAsyncThunk(
    'searchZ/fetchSearchZData',
    async (queryParams) => {
        const response = await testSearch(queryParams);
        return response.data.result;
    }
);

const searchZSlice = createSlice({
    name: 'searchZ',
    initialState,
    reducers: {
        resetInfoData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearchZData.pending, (state) => {
                state.searchDataloading = 'pending';
            })
            .addCase(fetchSearchZData.fulfilled, (state, action) => {
                state.searchDataloading = 'successful';
                state.searchData = action.payload;
            })
            .addCase(fetchSearchZData.rejected, (state, action) => {
                state.searchDataloading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetInfoData } = searchZSlice.actions;

export default searchZSlice.reducer;
