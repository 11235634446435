import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const AccordionItem = ({ title, children, alwaysOpen, index }) => {
    const [expanded, setExpanded] = useState(alwaysOpen);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            sx={{
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                '&:before': {
                    display: 'none',
                },
                '&.Mui-expanded': {
                    margin: '0',
                },
                border: 'none',
                marginTop: '0.6em',
                borderRadius: '15px',
                overflow: 'hidden',
                '&.MuiPaper-root': {
                    borderRadius: '15px !important',
                }
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}a-content`}
                id={`panel${index}a-header`}
                sx={{
                    padding: '0',
                    '& .MuiAccordionSummary-content': {
                        margin: '0',
                        display: 'flex',
                        alignItems: 'center',
                    },
                    '& .MuiAccordionSummary-expandIconWrapper': {
                        marginRight: '0',
                        order: -1,
                    },
                    borderRadius: expanded ? '0px' : '15px',
                    '.MuiAccordionSummary-root': {
                        borderRadius: '15px !important',
                    }
                }}
            >
                <Typography sx={{ fontFamily: 'Golos Medium', fontSize: '16px', cursor: 'pointer', marginLeft: '8px' }}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '2em' }}>
                {children}
                </
                    AccordionDetails>
        </Accordion>
    );
};
