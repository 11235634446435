import React, {useEffect, useRef, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import style from './Accounts.module.css'
import trash from '../../../../../common/icons/trashIcon.svg'
import restorePass from '../../../../../common/icons/restorePass.svg'
import {useModal} from "../../../../../hook/useModal";
import CustomModalContent from "../../../../CustomModalChanger/CustomModal";
import UserEditForm from "../../../../EditFormGlobal/EditForm";
import plus from '../../../../../common/icons/HiPlus.svg';
import {useDispatch, useSelector} from "react-redux";
import {setFormValidity} from "../../../../../service/reducers/ModalCloseStatusSlice";
import {fetchUsersData} from "../../../../../service/reducers/UsersDataSlice";
import {resetFormData, selectFormData} from "../../../../../service/reducers/formDataEditForms";
import {addAccount, deleteAccount, resetPassword} from "../../../../../api/api";
import {selectPrivileges} from "../../../../../service/reducers/PrivelegiesSlice";
const Account = () => {
    const dispatch = useDispatch();
    const { setIsModalVisible, setModalContent, setIsCentered} = useModal();
    const usersData = useSelector((state) => state.usersData.usersData);
    const meData = useSelector((state) => state.meData.meData);
    const formData = useSelector(selectFormData);
    const formDataRef = useRef(formData);
    const privileges = useSelector(selectPrivileges);
    const [accountKey, setAccountKey] = useState(0);

    useEffect(() => {
        dispatch(fetchUsersData());
    }, [dispatch]);

    const filteredAccounts = usersData && meData
        ? usersData.filter(account => account.id !== meData.id)
        : [];

    const mappedAccounts = filteredAccounts.map(account => ({
        id: account.id,
        accountName: account.label,
        fullName: account.description,
        email: account.email,
        inn: account.inn,
        phone: account.phone
    }));

    const handleChangePassword = async (email, event) => {
        event.stopPropagation();
        try {
            await resetPassword(email);
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Внимание!"
                    content={`Новый пароль был выслан на email пользователя ${email}`}
                    buttons={[
                        {
                            text: "Ок",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );

            setIsCentered(true);
            setIsModalVisible(true);
        } catch (error) {
            console.error('Ошибка при сбросе пароля:', error);
        }
    };

    const confirmDeleteAccount = async (accountId) => {
        try {
            await deleteAccount(accountId);
            setIsModalVisible(false);
            dispatch(fetchUsersData());
        } catch (error) {
            console.error('Ошибка при удалении аккаунта:', error);
        }
    };
    const handleDeleteAccount = (accountId, accountName, event) => {
        event.stopPropagation();
        if (privileges.includes('users.remove')) {
            const deleteButtonStyle = {
                backgroundColor: 'red',
            };
            dispatch(setFormValidity(true));
            const confirmDeleteAndClose = () => {
                confirmDeleteAccount(accountId)
                setIsCentered(false);
                setIsModalVisible(false);
            };
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Подтвердите ваши действия!"
                    content={`Вы точно хотите удалить учетную запись: ${accountName} ?`}
                    buttons={[
                        {
                            text: "Да",
                            onClick: confirmDeleteAndClose,
                            style: deleteButtonStyle
                        },
                        {
                            text: "Отмена",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );

            setIsCentered(true);
            setIsModalVisible(true);
        }
       else {
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Ограничение доступа"
                    content="Вы не можете удалять пользователей."
                    buttons={[
                        {
                            text: "Ок",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
        }
    };


   /* const timeSinceLastActivity = (lastActivity) => {
        const now = new Date();
        const activityDate = new Date(lastActivity);
        now.setHours(0, 0, 0, 0);
        activityDate.setHours(0, 0, 0, 0);

        const diffTime = Math.abs(now - activityDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 0) {
            return 'сегодня';
        } else if (diffDays === 1) {
            return 'вчера';
        } else {
            return `${diffDays} дня(ей) назад`;
        }
    };

    const availableAccountsString = mockData.availableAccounts.map((account, index, array) => {
        const word = account.count === 1 ? 'запись' : 'записей';
        const countColor = account.count > 0 ? 'black' : 'red';

        return (
            <span key={account.role}>
            <span style={{ color: countColor }}> {account.count}</span> {word}
                <span style={{ color: 'black' }}> {account.role}</span>
                {index < array.length - 1 ? ', ' : ''}
        </span>
        );
    });*/

    /*const handleEditSave = () => {
        setModalContent(
            <CustomModalContent
                title="Успешно!"
                content="Аккаунт успешно отредактирован!"
                buttons={[
                    {
                        text: "Ок",
                        onClick: () => setIsModalVisible(false),
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
    };*/

   /* const handleAddSave = () => {
        setModalContent(
            <CustomModalContent
                title="Успешно!"
                content="Аккаунт успешно создан!"
                buttons={[
                    {
                        text: "Ок",
                        onClick: () => setIsModalVisible(false),
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
    };*/

    /*const handleRowClick = (accountData) => {
        const formFields = [
            { type: 'input', name: 'accountName', label: 'Название аккаунта', value: accountData.accountName },
            { type: 'input', name: 'fullName', label: 'ФИО', value: accountData.fullName },
            { type: 'input', name: 'email', label: 'Электронная почта', value: accountData.email },
            /!*{
                type: 'select',
                name: 'role',
                label: 'Роль',
                value: accountData.role,
                options: [{ value: 'Админ', label: 'Админ' }, { value: 'ЛОХ', label: 'ЛОХ' }, { value: 'ЛОХ+', label: 'ЛОХ+' }, { value: 'МАСТЕР', label: 'МАСТЕР' }]
            },*!/
           /!* { type: 'input', name: 'inn', label: 'ИНН', value: accountData.inn },*!/
            /!*{
                type: 'select',
                name: 'status',
                label: 'Статус',
                value: accountData.status,
                options: [{ value: 'active', label: 'Активный' }, { value: 'notactive', label: 'Неактивный' }]
            },*!/
        ];

        const updatedFormFields = formFields.map(field => ({
            ...field,
            value: accountData[field.name]
        }));

        setModalContent(
            <CustomModalContent
                title="Редактирование аккаунта"
                formComponent={<UserEditForm
                    fields={updatedFormFields}
                />}
                buttons={[
                    {
                        text: "Сохранить",
                        onClick: handleEditSave,
                    }
                ]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );

        setIsCentered(true);
        setIsModalVisible(true);
    };*/

    useEffect(() => {
        formDataRef.current = formData;
    }, [formData]);

    const handleAddSubmit = async () => {
        const currentFormData = formDataRef.current;
        try {
            const response = await addAccount(currentFormData);
            if (response.status === 201) {
                setModalContent(
                    <CustomModalContent
                        title="Успешно!"
                        content="Аккаунт успешно создан!"
                        buttons={[
                            {
                                text: "Ок",
                                onClick: () => {
                                    setIsModalVisible(false);
                                    setIsCentered(false);
                                    dispatch(resetFormData());
                                    dispatch(fetchUsersData());
                                }
                            }
                        ]}
                        onClose={() => {
                            setIsCentered(false);
                            setIsModalVisible(false);
                            dispatch(resetFormData());
                            dispatch(fetchUsersData());
                        }}
                    />
                );
                setIsCentered(true);
                setIsModalVisible(true);
            }
        } catch (error) {
            console.error('Ошибка при создании аккаунта:', error);
        }
    };

    const handleAddAccount = () => {
        setAccountKey(prevKey => prevKey + 1);
        if (privileges.includes('users.create')) {
            const formFields = [
                { type: 'input', name: 'label', label: 'Название аккаунта', value: '' },
                { type: 'input', name: 'description', label: 'Описание аккаунта', value: '' },
                { type: 'input', name: 'phone', label: 'Телефон', value: '' },
                { type: 'input', name: 'email', label: 'Почта', value: '' },
                { type: 'input', name: 'inn', label: 'ИНН', value: '' },
                {
                    type: 'select',
                    name: 'role',
                    label: 'Роль',
                    value: '',
                    options: [
                        { value: 'base_user', label: 'Базовая' },
                        { value: 'improved_user', label: 'Расширенная' },
                    ]
                },
            ];

            setModalContent(
                <CustomModalContent
                    key={accountKey}
                    title="Добавление нового аккаунта"
                    formComponent={<UserEditForm
                        fields={formFields}
                    />}
                    buttons={[
                        {
                            text: "Добавить",
                            onClick: handleAddSubmit,
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                        dispatch(resetFormData());
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
        }
        else {
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Ограничение доступа"
                    content="Вы не можете создавать новых пользователей."
                    buttons={[
                        {
                            text: "Ок",
                            onClick: cancelAndClose
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
        }
    };

    return (
        <>
            <div className={style.availableContainer}>
               {/* Вам доступно:&nbsp;{availableAccountsString}*/}
                <div className={style.plusContainer} onClick={handleAddAccount}>
                   <img className={style.plusIco} src={plus} alt="Добавить"/>
                    <span>
                        Добавить
                    </span>
                </div>
            </div>
            <div className={style.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={style.tableCellText}>Название аккаунта</TableCell>
                            <TableCell className={style.tableCellText}>Данные аккаунта</TableCell>
                            <TableCell className={style.tableCellText}>ИНН</TableCell>
                            <TableCell className={style.tableCellText}>Контактный телефон</TableCell>
                            <TableCell className={style.tableCellText}>Email</TableCell>
                            <TableCell className={`${style.tableCellText} ${style.column3}`}>Действия</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {mappedAccounts.map((row) => (
                            <TableRow key={row.id} /*onClick={() => handleRowClick(row)}*/>
                                <TableCell className={style.tableCellText}>{row.accountName}</TableCell>
                                <TableCell className={style.tableCellText}>{row.fullName}</TableCell>
                                <TableCell className={style.tableCellText}>{row.inn}</TableCell>
                                <TableCell className={style.tableCellText}>{row.phone}</TableCell>
                                <TableCell className={style.tableCellText}>{row.email}</TableCell>
                                <TableCell>
                                    <div className={style.icoContainer}>
                                        <div onClick={(e) => handleChangePassword(row.email, e)}>
                                            <img className={style.icoTest} src={restorePass} alt="restoreIco" />
                                        </div>
                                        <div onClick={(e) => handleDeleteAccount(row.id, row.accountName, e)}>
                                            <img className={style.icoTest} src={trash} alt="trashIco" />
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    );
}

export default Account
