import React, {useEffect, useMemo, useRef, useState} from 'react';
import style from './Legend.module.css';
import * as d3 from 'd3';
import useResizeObserver from 'use-resize-observer';
import {useDispatch, useSelector} from "react-redux";
import {addClickedDiagram, setActiveLegendItem} from "../../service/reducers/legendItemsClick";
import localStorageService from "../../service/localStorage/localStorageService";
import styleTooltip from "../../pages/DashPage/TestMapD/GeoChart.module.css";
import {findProductOKPDNameByCode} from "../../utils/findOKPDNameGlobal";
import {truncateText} from "../../utils/trunctateText";
import newOkpdData from "../../utils/newOkpdList.json";
const Legend = ({ diagramId, data, dynamicRadius, activeColors, onLegendItemClick }) => {
    const dispatch = useDispatch();
    const activeItemsFromState = useSelector(state => state.legendItems.activeLegends[diagramId]);
    const activeItems = useMemo(() => activeItemsFromState || [], [activeItemsFromState]);
    const legendRef = useRef();
    const legendItemSize = dynamicRadius * 0.13;
    const fontSize = legendItemSize;
    const colors = localStorageService.getItem('colors');
    const tooltipRef = useRef(null);
    const [tooltip, setTooltip] = useState({ x: 0, y: 0, text: '' });
    const onMouseMove = (event, d) => {
        let x = event.pageX + 10;
        let y = event.pageY + 10;
        let tooltipText = { label: '' };

        if (diagramId === 'Объемы поставленной продукции по ОКПД2' || diagramId === 'Объемы продукции по коду ОКПД2') {
            const okpdName = findProductOKPDNameByCode(d.label, newOkpdData);
            tooltipText.value = okpdName;
        } else {
            tooltipText.value = d.fullLabel ? d.fullLabel : d.label;
        }

        setTooltip({
            x,
            y,
            text: [tooltipText],
        });
    };

    const onMouseOut = () => {
        setTooltip({ x: 0, y: 0, text: '' });
    };
    const renderLegend = () => {
        if (!Array.isArray(data)) {
            return;
        }
        const legendContainer = d3.select(legendRef.current);
        legendContainer.selectAll('*').remove();
        const sortedData = [...data].sort((a, b) => {
            if (diagramId === 'Распределение стоимостей единиц продукции') {
                return b.count - a.count;
            } else {
                return b.value - a.value;
            }
        });

        const findLabelForShortName = (shortName) => {
            const colorEntry = colors.find(color => color.short_name === shortName);
            return colorEntry ? colorEntry.name : shortName;
        };

        const legendItems = legendContainer
            .selectAll('.legend-item')
            .data(sortedData)
            .enter()
            .append('div')
            .attr('class', style.legendItem)
            .style('display', 'flex')
            .style('align-items', 'center')
            .style('gap', '3px')
            .style('border', d => {
                const fullLabel = diagramId === 'Соотношение способов определения поставщиков (от кол-ва)' || diagramId === 'Соотношение способов определения поставщиков (от суммы)' ? findLabelForShortName(d.label) : d.label;
                return activeItems.includes(fullLabel) ? '1px solid blue' : 'none';
            })
            .style('border-radius', '8px')
            .style('padding-left', '2px')
            .style('padding-right', '2px')
            .style('padding-bottom', '1px')
            .style('padding-top', '1px')
            .style('opacity', d => {
                const fullLabel = diagramId === 'Соотношение способов определения поставщиков (от кол-ва)' || diagramId === 'Соотношение способов определения поставщиков (от суммы)' ? findLabelForShortName(d.label) : d.label;
                return activeItems.length === 0 ? '1' : (activeItems.includes(fullLabel) ? '1' : '0.5');
            });

        legendItems
            .on('click', function(_, d) {
                let labelToSend = d.label;
                if (diagramId === 'Соотношение способов определения поставщиков (от суммы)' || diagramId === 'Соотношение способов определения поставщиков (от кол-ва)') {
                    labelToSend = findLabelForShortName(d.label);
                }
                dispatch(setActiveLegendItem({ diagramId, activeItem: labelToSend }));

                if (onLegendItemClick) {
                    onLegendItemClick(labelToSend);
                }

                dispatch(addClickedDiagram(diagramId));
                onMouseOut()
            })
            .on('mousemove', (event, d) => onMouseMove(event, d))
            .on('mouseout', onMouseOut);

        legendItems
            .append('svg')
            .attr('width', legendItemSize)
            .attr('height', legendItemSize)
            .append('circle')
            .attr('cx', legendItemSize / 2)
            .attr('cy', legendItemSize / 2)
            .attr('r', legendItemSize / 2)
            .style('fill', (d, i) => activeColors === "" ? '#4B72FB' : activeColors[i])

        legendItems
            .append('span')
            .text((d) => {
                let displayText = d.label;
                if (diagramId === 'Структура закупок по кодам видов расходов (от суммы)' || diagramId === 'Структура закупок по кодам видов расходов (от кол-ва)') {
                    displayText = d.extraLabel ? d.extraLabel : d.label;
                }
                return truncateText(displayText, 25);
            })
            .style('font-size', `${fontSize}px`)
            .style('font-family', 'Golos regular');
    };

    useResizeObserver({
        ref: legendRef,
        onResize: () => {
            renderLegend();
        },
    });

    useEffect(() => {
        renderLegend();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dynamicRadius, activeColors,activeItems]);

    return (
        <div>
            <div ref={legendRef} className={style.legendContainer}></div>
            {tooltip.text && (
                <div
                    ref={tooltipRef}
                    className={styleTooltip.tooltip}
                    style={{ top: `${tooltip.y}px`, left: `${tooltip.x}px` }}
                >
                    {tooltip.text.map(item => (
                        <div key={item.label}>
                            {item.value}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
};

export default Legend;
