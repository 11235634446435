import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchKBR} from "../../../api/api";

const initialState = {
    KbrData: [],
    loading: 'idle',
    error: null,
    showCountKbr: 'sum',
};

export const fetchDonutKbrData = createAsyncThunk(
    'kbr/fetchKbrData',
    async (options) => {
        options.newOKPDCode = options.selectedProduct.map(item => item.productId);
        const response = await fetchKBR(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const donutKbrChartSlice = createSlice({
    name: 'donutKbrChart',
    initialState,
    reducers: {
        setShowCountKbr: (state, action) => {
            state.showCountKbr = action.payload;
        },
        resetDonutKbrData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDonutKbrData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchDonutKbrData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.KbrData = action.payload;
                }
            })
            .addCase(fetchDonutKbrData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { setShowCountKbr,resetDonutKbrData } = donutKbrChartSlice.actions;

export default donutKbrChartSlice.reducer;
