import React, {useEffect, useRef, useState} from 'react';
import searchIcon from '../../../common/icons/SearchIcon.svg';
import styled from '@emotion/styled';
import useLocalStorage from "../../../hook/useLocalStorage";
import {useDispatch, useSelector} from "react-redux";
import {setParam} from "../../../service/reducers/FilterSearchSlice";
import {fetchSearchZData} from "../../../service/reducers/SearchZSlice";
import xClose from '../../../common/icons/xButtonGrey.svg'
const SearchBox = styled.div`
  width: 90%;
  height: 40px;
  border: 1px solid ${props => (props.focused ? '#4A3DFD' : 'transparent')};
  border-radius: 4px;
  background: var(--headerCmg-bg-color, #FFF);
  transition: background-color 0.5s ease-in-out;
  margin-left: 2em;
  display: flex;
  align-items: center;
  position: relative; 
`;

const SearchButton = styled.button`
  padding: 8px 12px;
  margin-right: 0.2em;
  background-color: #4A3DFD;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: #3730A3;
  }
`;

const ClearIcon = styled.img`
  position: absolute;
  right: 4.4em;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const SuggestionsList = styled.ul`
  width: 302px;
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background: white;
  border-radius: 4px;
  overflow: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out, transform 0.2s ease-in-out;
  transform: translateY(-20px);
  opacity: 0;
  z-index: 9999;
  visibility: hidden;
  max-height: 80vh; 
  overflow-y: auto; 
  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }
`;

const SuggestionItem = styled.li`
  padding: 12px 16px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Golos Regular';
  border-bottom: 1px solid #f0f0f0; 
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: #f8f8f8; 
  }
`;

const SearchIconImg = styled.img`
  margin-left: 8px;
  transition: transform 0.3s, opacity 0.5s;
  transform: ${props => (props.focused ? 'scale(0)' : 'scale(1)')};
  width: ${props => (props.focused ? '0px' : 'auto')};
  opacity: ${props => (props.focused ? 0 : 1)};
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  background: var(--headerCmg-bg-color, #FFF);
  flex-grow: 1;
  padding: 8px;
  font-size: 14px;
  font-family: 'Golos Regular';
  transition: background-color 0.5s ease-in-out;
  color: #000;
  &::placeholder {
    color: ${props => (props.hidePlaceholder ? 'transparent' : '#8D96B2')};
    transition: color 0.4s;
  }
`;

const SearchZakComponent = () => {
    const [focused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useLocalStorage('searchSuggestions', []);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const searchBoxRef = useRef(null);
    const inputRef = useRef(null);
    const dispatch = useDispatch();
    const queryParams = useSelector(state => state.searchFiltersSlice.searchParams);

    const handleSearch = () => {
        dispatch(setParam({ key: 'searchText', value: inputValue.trim() }));
        dispatch(fetchSearchZData({...queryParams, searchText: inputValue.trim()}));
        setSuggestions(inputValue.trim());
        setShowSuggestions(false);
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter' && inputValue.trim()) {
            handleSearch();
        }
    };

    const handleClearSearch = () => {
        setInputValue('');
        dispatch(setParam({ key: 'searchText', value: '' }));
    };

    useEffect(() => {
        const handleOutsideAction = (event) => {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target)) {
                setShowSuggestions(false);
                if (inputRef.current) {
                    inputRef.current.blur();
                }
            }
        };

        document.addEventListener('mousedown', handleOutsideAction);
        document.addEventListener('scroll', handleOutsideAction, true);
        document.addEventListener('touchstart', handleOutsideAction);
        document.addEventListener('touchmove', handleOutsideAction);

        return () => {
            document.removeEventListener('mousedown', handleOutsideAction);
            document.removeEventListener('scroll', handleOutsideAction, true);
            document.removeEventListener('touchstart', handleOutsideAction);
            document.removeEventListener('touchmove', handleOutsideAction);
        };
    }, []);

    const handleBlur = () => {
        setTimeout(() => setShowSuggestions(false), 100);
        setFocused(false);
    };
    const handleFocus = () => {
        setShowSuggestions(true);
        setFocused(true);
    };

    const handleSelectSuggestion = (suggestion) => {
        setInputValue(suggestion);
        setShowSuggestions(false);
        dispatch(setParam({ key: 'searchText', value: suggestion }));
        dispatch(fetchSearchZData({...queryParams, searchText: suggestion}));
    };

    const handleChange = event => {
        const { value } = event.target;
        setInputValue(value);
        if (!value.trim()) {
            setShowSuggestions(false);
            dispatch(setParam({ key: 'searchText', value: '' }));
        } else {
            setShowSuggestions(true);
        }
    };

    return (
        <SearchBox ref={searchBoxRef} focused={focused}>
            <SearchIconImg src={searchIcon} alt="searchIcon" focused={focused} />
            <SearchInput
                ref={inputRef}
                placeholder="Поиск по предмету закупки, заказчику, товарным позициям, ключевым словам и кодам ОКПД"
                hidePlaceholder={inputValue.length > 0}
                value={inputValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
            />
            {inputValue && (
                <ClearIcon
                    src={xClose}
                    alt="Clear search"
                    onClick={handleClearSearch}
                />
            )}
            <SearchButton onClick={handleSearch}>Найти</SearchButton>
            <SuggestionsList className={showSuggestions ? 'show' : ''}>
                {suggestions.map((suggestion, index) => (
                    <SuggestionItem key={index} onClick={() => handleSelectSuggestion(suggestion)}>
                        {suggestion}
                    </SuggestionItem>
                ))}
            </SuggestionsList>
        </SearchBox>
    );
};

export default SearchZakComponent;
