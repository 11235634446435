import React, { createContext, useState, useContext } from 'react';

const UseModal = createContext();

export const ModalProvider = ({ children }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [isCentered, setIsCentered] = useState(false);
    const [isFullCentered, setIsFullCentered] = useState(false);
    const [isFullScreenAll, setIsFullScreenAll] = useState(false);

    return (
        <UseModal.Provider value={{
            isFullScreenAll, setIsFullScreenAll, isModalVisible, setIsModalVisible, isFullCentered,setIsFullCentered, modalContent, setModalContent, isFullScreen, setIsFullScreen, isCentered, setIsCentered}}>
            {children}
        </UseModal.Provider>
    );
};

export const useModal = () => {
    return useContext(UseModal);
};
