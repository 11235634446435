import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedKbrSegments: [],
    kbrChartName: [],
};

const donutKbrSegmentSlice = createSlice({
    name: 'donutKbrSegmentSlice',
    initialState,
    reducers: {
        addKbrChartName: (state, action) => {
            state.kbrChartName = [action.payload];
        },
        toggleSegment: (state, action) => {
            const index = state.selectedKbrSegments.indexOf(action.payload);
            if (index === -1) {
                state.selectedKbrSegments.push(action.payload);
            } else {
                state.selectedKbrSegments.splice(index, 1);
            }
        },
        removeSegmentKbr: (state, action) => {
            const index = state.selectedKbrSegments.indexOf(action.payload);
            if (index !== -1) {
                state.selectedKbrSegments.splice(index, 1);
            }
        },
        resetKbrDonutChart: () => {
            return initialState;
        },
    },
});

export const {addKbrChartName, toggleSegment, removeSegmentKbr,resetKbrDonutChart } = donutKbrSegmentSlice.actions;
export default donutKbrSegmentSlice.reducer;
