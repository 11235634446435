import React, { useRef } from 'react';
import {DragPreviewImage, useDrag, useDrop} from 'react-dnd';
import test from "../../../common/IcoBig.svg"

const DraggableDiagram = ({ type, Component, onZoomClick, moveDiagram }) => {
    const dragRef = useRef(null);

    const [, drag, preview] = useDrag({
        type: 'DIAGRAM',
        item: { type },
    });

    const [, drop] = useDrop({
        accept: 'DIAGRAM',
        drop: (item) => {
            moveDiagram(item.type, type);
        },
    });

    drag(dragRef);
    drop(dragRef);

    return (
        <>
            <DragPreviewImage connect={preview} src={test} />
            <div
                ref={dragRef}
            >
                <Component onZoomClick={() => onZoomClick(type)} />
            </div>
        </>
    );
};

export default DraggableDiagram;

