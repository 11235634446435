import style from "../Accounts/Accounts.module.css";
import plus from "../../../../../common/icons/HiPlus.svg";
import React, {useEffect, useState} from "react";
import {setFormValidity} from "../../../../../service/reducers/ModalCloseStatusSlice";
import CustomModalContent from "../../../../CustomModalChanger/CustomModal";
import {useDispatch, useSelector} from "react-redux";
import {selectPrivileges} from "../../../../../service/reducers/PrivelegiesSlice";
import {useModal} from "../../../../../hook/useModal";
import InnWizard from "../../../../Modal/AddInn/InnWizard";
import {fetchCompanyUserData} from "../../../../../service/reducers/soloCompanySlice";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import trash from "../../../../../common/icons/trashIcon.svg";
import CheckIcon from '@mui/icons-material/Check';
import {deleteSoloCompany} from "../../../../../api/api";

const InnList = () => {
    const dispatch = useDispatch();
    const { setIsModalVisible, setModalContent, setIsCentered} = useModal();
    const privileges = useSelector(selectPrivileges);
    const companyUserData = useSelector((state) => state.companyUserData.companyUserData);
    const [innWizardKey, setInnWizardKey] = useState(0);

    useEffect(() => {
        dispatch(fetchCompanyUserData());
    }, [dispatch]);

    const confirmDeleteInn = async (inn) => {
        try {
            await deleteSoloCompany(inn);
            dispatch(fetchCompanyUserData());
            setIsModalVisible(false);
        } catch (error) {
            console.error('Ошибка при удалении ОКПД:', error);
        }
    }
    const handleDeleteInn = (inn,event) => {
        event.stopPropagation();
        const deleteButtonStyle = {
            backgroundColor: 'red',
        };
        dispatch(setFormValidity(true));

        const confirmDeleteAndClose = () => {
            confirmDeleteInn(inn);
            setIsCentered(false);
            setIsModalVisible(false);
        };
        const cancelAndClose = () => {
            setIsCentered(false);
            setIsModalVisible(false);
        };
        setModalContent(
            <CustomModalContent
                title="Подтвердите ваши действия!"
                content={`Вы точно хотите удалить компанию с ИНН: ${inn} ?`}
                buttons={[
                    {
                        text: "Да",
                        onClick: confirmDeleteAndClose,
                        style: deleteButtonStyle
                    },
                    {
                        text: "Отмена",
                        onClick: cancelAndClose,
                    }
                ]}
                onClose={() => {
                    dispatch(fetchCompanyUserData());
                    setIsCentered(false);
                    setIsModalVisible(false);
                }}
            />
        );
        setIsCentered(true);
        setIsModalVisible(true);
    }
    const handleAddInnClick = () => {
        setInnWizardKey(prevKey => prevKey + 1);
        if (privileges.includes("groups.create")) {
            dispatch(setFormValidity(true));
            setModalContent(
                <CustomModalContent
                    title="Добавление новой компании"
                    formComponent={
                        <InnWizard
                            key={innWizardKey}
                            onClose={() => {
                                setIsCentered(false);
                                setIsModalVisible(false);
                                dispatch(fetchCompanyUserData());
                            }}
                        />
                    }
                    buttons={
                        [ {
                            text: "Добавить",
                            onClick: () => {
                                setIsModalVisible(false);
                                setIsCentered(false);
                                dispatch(fetchCompanyUserData());
                            }
                        }]
                    }
                    onClose={() => {
                        dispatch(fetchCompanyUserData());
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );

            setIsCentered(true);
            setIsModalVisible(true);
        }
        else {
            dispatch(setFormValidity(true));
            const cancelAndClose = () => {
                setIsCentered(false);
                setIsModalVisible(false);
            };
            setModalContent(
                <CustomModalContent
                    title="Ограничение доступа"
                    content="Вы не можете добавлять компанию."
                    buttons={[
                        {
                            text: "Ок",
                            onClick: cancelAndClose,
                        }
                    ]}
                    onClose={() => {
                        setIsCentered(false);
                        setIsModalVisible(false);
                    }}
                />
            );
            setIsCentered(true);
            setIsModalVisible(true);
        }
    }

    return (
        <>
            <div className={style.availableContainer}>
                <div className={style.plusContainer} onClick={handleAddInnClick}>
                    <img className={style.plusIco} src={plus} alt="Добавить"/>
                    <span>
                        Добавить
                    </span>
                </div>
            </div>
            {companyUserData && companyUserData.length > 0 ? (
                <div className={style.tableContainer}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className={style.tableCellText}>ИНН</TableCell>
                                <TableCell className={style.tableCellText}>Название компании</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.textCentered}`}>Заказчик</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.textCentered}`}>Подрядчик</TableCell>
                                <TableCell className={`${style.tableCellText} ${style.textCentered}`}>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {companyUserData.map((company, index) => (
                                <TableRow key={index}>
                                    <TableCell className={style.tableCellText}>{company.inn}</TableCell>
                                    <TableCell className={style.tableCellText}>{company.full_name}</TableCell>
                                    <TableCell className={`${style.tableCellText} ${style.textCentered}`}>
                                        {company.customer ? (
                                            <CheckIcon style={{ color: '#4A3DFD' }} />
                                        ) : (
                                            <CheckIcon style={{ color: '#8D96B2' }} />
                                        )}
                                    </TableCell>
                                    <TableCell className={`${style.tableCellText} ${style.textCentered}`}>
                                        {company.suppliers ? (
                                            <CheckIcon style={{ color: '#4A3DFD' }} />
                                        ) : (
                                            <CheckIcon style={{ color: '#8D96B2' }} />
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div className={`${style.icoContainer} ${style.soloIco}`}>
                                            <div onClick={(e) =>  handleDeleteInn(company.inn,e)}>
                                                <img
                                                    className={style.icoTest}
                                                    src={trash}
                                                    alt="Удалить"
                                                    style={{ cursor: 'pointer' }}
                                                />
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div className={style.centerContent}>
                    У вас еще нет добавленных компаний
                </div>
            )}
        </>
    );
}

export default InnList
