import React, {useEffect, useState} from 'react'
import styleTarrif from './TarrifsContainer.module.css'
import TarrifCard from "./TarrifCard/TarrifCard";
import styles from "../InputForm/DynamicForm.module.css";
const Tarrifs = ({ data, onTariffChange }) => {
    const [selectedOption, setSelectedOption] = useState('Оптимальный, 1 month');
    const [currentPrice, setCurrentPrice] = useState(0);

    useEffect(() => {
        if (!data || !Array.isArray(data)) {
            console.error("Expected 'data' to be an array, received:", data);
            return;
        }

        const selectedTariff = data.find(tariff => selectedOption.includes(tariff.label));
        if (!selectedTariff) {
            console.error("Selected tariff not found in the provided data");
            return;
        }

        const period = selectedOption.includes('12 months') ? '12 months' : '1 month';
        const price = getPrice(selectedTariff, period);
        setCurrentPrice(price);
        onTariffChange(price);
        // eslint-disable-next-line
    }, [selectedOption, data]);

    if (!Array.isArray(data)) {
        console.error("Expected 'data' to be an array, received:", data);
        return;
    }

    const prepareItemsForCard = (tariff) => {
        const items = [];
        if (!tariff) return { items: [], subTitle: '' };

        if (tariff.label === 'Оптимальный') {
            items.push({ label: 'Базовых пользователей', count: tariff.count_base_users });
        } else if (tariff.label === 'Продвинутый') {
            items.push({ label: 'Продвинутых пользователей', count: tariff.count_improved_users });
        }
        items.push({ label: 'Доступно ОКПД на выбор', count: tariff.count_okp_ds });
        items.push({ label: 'Доступно групп для объединения', count: tariff.count_groups });
        items.push({ label: 'Количество ОКПД в группе', count: tariff.count_okp_ds_in_group });
        items.push({ label: 'Количество компаний в группе', count: tariff.count_companies_in_group });

        let subTitle = '';
        if (tariff.label === 'Оптимальный') {
            subTitle = 'Для отслеживания самых необходимых классификаторов и компаний';
        } else if (tariff.label === 'Продвинутый') {
            subTitle = 'Для широкого охвата поставщиков или заказчиков в интересующей области государственных закупок';
        }

        return { items, subTitle };
    };

    const calculateYearlySavings = (priceMonth, priceYear) => {
        return (priceMonth * 12) - priceYear;
    };

    const getPrice = (tariff, period) => {
        return period === '1 month' ? tariff.price_month : tariff.price_year;
    };
    const selectedTariff = data.find(tariff => selectedOption.includes(tariff.label));
    const period = selectedOption.includes('12 months') ? '12 months' : '1 month';
    const { items, subTitle } = prepareItemsForCard(selectedTariff);

    const handleSelectChange = (e) => {
        setSelectedOption(e.target.value);
    };

    return (
        <>
            <div className={styles.formContainer}>
                <div className={styleTarrif.dropdownContainer}>
                    <select
                        className={styleTarrif.dropdown}
                        value={selectedOption}
                        onChange={handleSelectChange}
                    >
                        <option value="Оптимальный, 1 month">Оптимальный тариф на 1 месяц</option>
                        <option value="Оптимальный, 12 months">Оптимальный тариф на 12 месяцев</option>
                        <option value="Продвинутый, 1 month">Продвинутый тариф на 1 месяц</option>
                        <option value="Продвинутый, 12 months">Продвинутый тариф на 12 месяцев</option>
                    </select>
                </div>
                {selectedTariff && (
                    <TarrifCard
                        title={selectedTariff.label}
                        items={items}
                        price={currentPrice}
                        savings={period === '12 months' ? calculateYearlySavings(selectedTariff.price_month, selectedTariff.price_year) : 0}
                        subTitle={subTitle}
                        isSelectedYear={period === '12 months'}
                    />
                )}
            </div>
        </>
    )
}

export default Tarrifs
