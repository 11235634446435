import React, { useState } from 'react';
import styles from '../../DashPage/HeadContent/HeaderContent.module.css';

const HeadRatingContent = () => {
    const [activeTab, setActiveTab] = useState('supplier');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    return (
        <div className={styles.content}>
            <div className={styles.leftPart}>
                <div
                    className={`${styles.item} ${activeTab === 'supplier' ? styles.activeItem : ''}`}
                    onClick={() => handleTabClick('supplier')}
                >
                    Подрядчики
                </div>
                <div
                    className={`${styles.item} ${activeTab === 'customer' ? styles.activeItem : ''}`}
                    onClick={() => handleTabClick('customer')}
                >
                    Заказчики
                </div>
                <div
                    className={styles.slider}
                    style={{
                        width: activeTab === 'supplier' ? '90px' : '78px',
                        left: activeTab === 'supplier' ? '0' : '122px',
                    }}
                />
            </div>
        </div>
    );
};

export default HeadRatingContent;
