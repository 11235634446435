import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchOkpd } from '../../../api/api.js';

const initialState = {
    selectedProduct: [],
    stackedBarData: [],
    loading: 'idle',
    error: null,
    isInsideActive: false,
    trimCode: [],
    stackedName: [],
};


export const fetchStackedBarData = createAsyncThunk(
    'productCode/fetchStackedBarData',
    async (options) => {
        const response = await fetchOkpd(options);
        if ((response.status === 204 && response.data === "") || response.data.result === null) {
            return [];
        }
        return response.data.result;
    }
);

const productCodeChartSlice = createSlice({
    name: 'productCode',
    initialState,
    reducers: {
        addStackedName: (state, action) => {
            state.stackedName = [action.payload];
        },
        setSelectedProduct: (state, action) => {
            const { productId } = action.payload;
            if (productId === "") {
                state.selectedProduct = [];
                return;
            }
            const existingProduct = state.selectedProduct.find(p => p.productId === productId);
            const productData = state.stackedBarData.find(p => p.label === productId);
            const allSegments = productData ? productData.labels : [];

            if (existingProduct) {
                existingProduct.segments = allSegments;
            } else {
                state.selectedProduct.push({ productId, segments: allSegments });
            }
        },
        removeProduct: (state, action) => {
            const { productId } = action.payload;
            state.selectedProduct = state.selectedProduct.filter(p => p.productId !== productId);
        },
        clearSelectedProduct: (state) => {
            state.selectedProduct = [];
        },
        resetStore: (state) => {
            const { isInsideActive } = state;
            return { ...initialState, isInsideActive };
        },
        toggleInsideActive: (state) => {
            state.isInsideActive = !state.isInsideActive;
        },
        resetInsideActive: (state) => {
            state.isInsideActive = false;
        },
        setTrimCode: (state, action) => {
            state.trimCode = action.payload;
        },
        clearTrimCode: (state) => {
            state.trimCode = [];
        },
        resetOkpd: () => initialState
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStackedBarData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchStackedBarData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.stackedBarData = action.payload;
                }
            })
            .addCase(fetchStackedBarData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    }
});


export const {addStackedName,resetOkpd, setTrimCode, clearTrimCode,resetInsideActive,toggleInsideActive,removeProduct, setSelectedProduct, clearSelectedProduct, resetStore } = productCodeChartSlice.actions;
export default productCodeChartSlice.reducer;
