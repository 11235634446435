import React from 'react';
import style from './CustomModal.module.css';
import {useSelector} from "react-redux";

const CustomModalContent = ({ title, content, buttons, onClose, formComponent }) => {
    const canCloseModal = useSelector(state => state.modalStatus.isFormValid);

    return (
        <div className={style.customModal}>
            <div className={style.customModalHeader}>
                <h2 className={style.customModalTitle}>{title}</h2>
                <button onClick={onClose} className={style.customModalCloseButton}>&times;</button>
            </div>
            <div className={style.customModalContent}>
                {content}
                {formComponent}
            </div>
            <div className={style.customModalFooter}>
                {buttons.map((button, index) => (
                    <button
                        key={index}
                        onClick={button.onClick}
                        disabled={!canCloseModal}
                        style={{
                            ...button.style,
                            opacity: canCloseModal ? 1 : 0.5
                        }}
                        className={`${style.customModalButton} ${buttons.length === 1 ? style.singleButton : ''}`}
                    >
                        {button.text}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default CustomModalContent;
