import React, { useState } from 'react';
import styles from './Partisipiatns.module.css';

export const Participants = ({ employers }) => {
    const [showAll, setShowAll] = useState(false);
    const visibleEmployers = showAll ? employers : employers.slice(0, 3);
    const hiddenCount = employers.length - visibleEmployers.length;
    const handleShowMoreClick = () => {
        setShowAll(true);
    };

    const handleParticipantClick = (name) => {
        console.log(name);
    };

    return (
        <div className={styles.blockPart}>
            <div className={styles.participantsTitle}>Возможные участники:</div>
            <ul className={styles.participantsList}>
                {visibleEmployers.map((employer, index) => (
                    <li key={index}
                        className={styles.participant}
                        onClick={() => handleParticipantClick(employer.name)}>
                        {employer.name}
                    </li>
                ))}
            </ul>
            {!showAll && hiddenCount > 0 && (
                <div className={styles.more} onClick={handleShowMoreClick}>
                    и еще {hiddenCount}...
                </div>
            )}
        </div>
    );
};
