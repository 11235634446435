import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchSunBurst} from "../../api/api";

const initialState = {
    SunBurstData: [],
    loading: 'idle',
    error: null,
};

export const fetchSunBurstData = createAsyncThunk(
    'sunBurst/fetchSunBurstData',
    async (options) => {
        try {
            options.newOKPDCode = options.selectedProduct.map(item => item.productId);
            const response = await fetchSunBurst(options);
            if (response.status === 204 || response.data.result === null) {
                return { data: null, message: 'No penalties or fines found.' };
            }
            return { data: response.data.result };
        } catch (error) {
            throw error;
        }
    }
);


const sunBurstChartSlice = createSlice({
    name: 'sunBurstChart',
    initialState,
    reducers: {
        resetSunBurstData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSunBurstData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchSunBurstData.fulfilled, (state, action) => {
                if (!action.payload.data) {
                    state.loading = 'failed';
                    state.message = action.payload.message;
                } else {
                    state.loading = 'successful';
                    state.SunBurstData = action.payload.data;
                }
            })
            .addCase(fetchSunBurstData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetSunBurstData } = sunBurstChartSlice.actions;

export default sunBurstChartSlice.reducer;
