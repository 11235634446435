import styles from "../Layout.module.css";
import {Outlet} from "react-router-dom";
import CommonHeader from "../../Header/ComonHeader";

const MasterPersonal = () => {
    return (
        <div className={styles.mainContainer}>
            <CommonHeader title="Личный Кабинет"/>
            <Outlet/>
        </div>
        )
}

export default MasterPersonal
