import React, {useEffect, useRef, useState} from 'react';
import style from '../Tarrifs/Tariffs.module.css';
import {useDispatch, useSelector} from "react-redux";
import {fetchMeData} from "../../../service/reducers/MeSlice";
import {useModal} from "../../../hook/useModal";
import CustomModalContent from "../../CustomModalChanger/CustomModal";
import CropImage from "../../ImageCrop/ImageCrop";
const PersonalInfo = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [textareaHeight, setTextareaHeight] = useState('auto');
    const textareaRef = useRef(null);
    const dispatch = useDispatch();
    const meData = useSelector((state) => state.meData.meData);
    const { setIsModalVisible, setModalContent, setIsCentered} = useModal();
    const [cropImageKey, setCropImageKey] = useState(0);
    const [avatarUrl, setAvatarUrl] = useState(localStorage.getItem('avatar'));
    const hasAvatar = Boolean(avatarUrl);
    const initials = meData.label ? meData.label.substring(0, 2).toUpperCase() : '';

    useEffect(() => {
        setTextareaHeight(`${textareaRef.current.scrollHeight + 5}px`);
        dispatch(fetchMeData())
    }, [dispatch]);

    useEffect(() => {
        if (meData && meData.avatar_url) {
            const storedAvatarUrl = localStorage.getItem('avatarUrl');
            if (storedAvatarUrl !== meData.avatar_url) {
                fetchAndStoreImage(meData.avatar_url);
            }
        }
    }, [meData]);

    useEffect(() => {
        setAvatarUrl(localStorage.getItem('avatarUrl'));
        // eslint-disable-next-line
    }, [localStorage.getItem('avatarUrl')]);

    useEffect(() => {
        return () => {
            setCropImageKey(0);
        };
    }, []);

    const fetchAndStoreImage = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                localStorage.setItem('avatar', reader.result);
                localStorage.setItem('avatarUrl', imageUrl);
                setAvatarUrl(reader.result);
            };
        } catch (error) {
            console.error('Ошибка при загрузке аватарки:', error);
        }
    };
    const toggleEditing = () => {
        setIsEditing(!isEditing);
    };

    const handleDataChange = (key, value) => {

    };
    const testAvatar = () => {
        setModalContent(
            <CustomModalContent
                title="Добавление аватарки"
                formComponent={<CropImage key={cropImageKey} />}
                buttons={[]}
                onClose={() => {
                    setIsCentered(false);
                    setIsModalVisible(false);
                    setCropImageKey(prevKey => prevKey + 1);
                }}
            />
        );
        setIsCentered(true);
        setIsModalVisible(true);
    };

    return (
        <div className={style.container}>
            <div className={style.tariffHeader}>
                <span>Общая информация</span>
                <div className={style.changeTariffButton} onClick={toggleEditing}>
                    {isEditing ? 'Сохранить' : 'Редактировать'}
                </div>
            </div>
            <div className={style.infoBlock}>
                <div className={style.iconContainer}>
                    {hasAvatar ? (
                        <img src={avatarUrl} alt="Иконка профиля" className={style.profileIcon} />
                    ) : (
                        <div className={style.initialsAvatar}>
                            {initials}
                        </div>
                    )}
                    <div className={style.uploadButton} onClick={testAvatar}>Загрузить</div>
                </div>
                <div className={style.content}>
                    <div className={style.field}>
                        <span className={style.fieldName}>Наименование</span>
                        <textarea
                            ref={textareaRef}
                            className={`${style.fieldValue} ${!isEditing ? style.nonEditable : ''}`}
                            value={meData.label}
                            readOnly={!isEditing}
                            style={{ height: textareaHeight }}
                            onChange={(e) => handleDataChange('label', e.target.value)}
                        />
                    </div>
                    <div className={style.field}>
                        <span className={style.fieldName}>ИНН</span>
                        <input
                            className={`${style.fieldValue} ${!isEditing ? style.nonEditable : ''}`}
                            value={meData.inn}
                            readOnly={!isEditing}
                            onChange={(e) => handleDataChange('inn', e.target.value)}
                        />
                    </div>
                    <div className={style.field}>
                        <span className={style.fieldName}>Телефон</span>
                        <input
                            className={`${style.fieldValue} ${!isEditing ? style.nonEditable : ''}`}
                            value={meData.phone}
                            readOnly={!isEditing}
                            onChange={(e) => handleDataChange('phone', e.target.value)}
                        />
                    </div>
                    <div className={style.field}>
                        <span className={style.fieldName}>Email</span>
                        <input
                            className={`${style.fieldValue} ${!isEditing ? style.nonEditable : ''}`}
                            value={meData.email}
                            readOnly={!isEditing}
                            onChange={(e) => handleDataChange('email', e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonalInfo;
