import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveSettingsTab } from "../../../../service/reducers/SettingsTabsSlice";
import styles from './../PersonalSettings.module.css'
const PersonalSettingsTab = ({ availableTabs }) => {
    const [sliderStyle, setSliderStyle] = useState({});
    const itemsRef = useRef([]);
    const leftPartRef = useRef(null);
    const dispatch = useDispatch();
    const activeSettingsTab = useSelector((state) => state.settingsTabs.activeSettingsTab);

    const setSliderPosition = (index) => {
        const { left: itemLeft, width } = itemsRef.current[index].getBoundingClientRect();
        const { left: parentLeft } = leftPartRef.current.getBoundingClientRect();
        const left = itemLeft - parentLeft;
        setSliderStyle({ width, left });
    };

    useEffect(() => {
        if (!availableTabs.includes(activeSettingsTab)) {
            const firstAvailableTab = availableTabs[0];
            dispatch(setActiveSettingsTab(firstAvailableTab));
        }
    }, [availableTabs, activeSettingsTab, dispatch]);

    useEffect(() => {
        const activeIndex = availableTabs.indexOf(activeSettingsTab);
        if (activeIndex !== -1) {
            setSliderPosition(activeIndex);
        }
    }, [activeSettingsTab, availableTabs]);

    const handleClick = (index, tabName) => {
        if (activeSettingsTab !== tabName) {
            dispatch(setActiveSettingsTab(tabName));
        }
        setSliderPosition(index);
    };

    return (
        <div className={styles.leftPart} ref={leftPartRef}>
            {availableTabs.map((item, index) => (
                <div
                    ref={(el) => (itemsRef.current[index] = el)}
                    className={`${styles.item} ${activeSettingsTab === item ? styles.activeItem : ''}`}
                    onClick={() => handleClick(index, item)}
                    key={index}
                >
                    {item}
                </div>
            ))}
            <div className={styles.slider} style={sliderStyle} />
        </div>
    );
}

export default PersonalSettingsTab;

