import React from 'react';
import styles from '../../DashPage/HeadContent/HeaderContent.module.css';
import SearchZakComponent from "../../../components/SearchComponent/SearchZak/SearchZak";
import SearchFilterIco from '../../../common/icons/SearchIcons/SearchFilterIco.svg';
import SearchHeartIco from '../../../common/icons/SearchIcons/SearchHeartIco.svg';
import SearchLikeIco from '../../../common/icons/SearchIcons/SearchLikeIco.svg';
import {SettingsContainer} from "../SearchSettingsMain/SettingsContainer";
import {useSearchModal} from "../../../hook/useSearchModal";
import Modal from "../../../components/Modal/ModalComponent/Modal";

const HeadSearchContent = () => {
    const { isModalVisible, modalContent, setIsModalVisible, setModalContent } = useSearchModal();
    const handleFilterClick = () => {
            setModalContent(
               <SettingsContainer
                   onClose={() => {
                       setIsModalVisible(false);
                   }}
               />
            );
            setIsModalVisible(true);
    };

    return (
        <>
            <Modal isVisible={isModalVisible} onClose={() => setIsModalVisible(false)}>
                {modalContent}
            </Modal>
            <div className={styles.content}>
                <SearchZakComponent />
                <div className={styles.searchIconsContainer}>
                    <img src={SearchFilterIco} alt="Filter" className={styles.searchIcon} onClick={handleFilterClick}/>
                    <img src={SearchLikeIco} alt="Likes" className={styles.searchIcon} />
                    <img src={SearchHeartIco} alt="Favorites" className={`${styles.searchIcon} ${styles.customMrIco}`}/>
                </div>
            </div>
        </>
    );
};

export default HeadSearchContent;
