import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchColors} from "../../api/api";

export const fetchColorsData = createAsyncThunk(
    'colors/fetchColorsData',
    async () => {
        const response = await fetchColors();
        return response;
    }
);

const initialState = {
    colors: [],
    loadingColors: 'idle',
    error: null,
};

const colorsSlice = createSlice({
    name: 'colors',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchColorsData.pending, (state) => {
                state.loadingColors = 'pending';
            })
            .addCase(fetchColorsData.fulfilled, (state, action) => {
                state.loadingColors = 'successful';
                state.colors = action.payload;
            })
            .addCase(fetchColorsData.rejected, (state, action) => {
                state.loadingColors = 'failed';
                state.error = action.error.message;
            });
    },
});
export const colorsData = (state) => state.colorsData.colors;
export default colorsSlice.reducer;
