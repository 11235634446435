
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    selectedData: {},
};

export const okpdSelectedDataSlice = createSlice({
    name: 'okpdSelectedData',
    initialState,
    reducers: {
        updateSelectedData: (state, action) => {
            const { okpd_code, okpd_name } = action.payload;
            if (state.selectedData[okpd_code]) {
                delete state.selectedData[okpd_code];
            } else {
                state.selectedData[okpd_code] = okpd_name;
            }
        },
        resetOkpdSelecteData: (state, action) => {
            state.selectedData = {}
        }
    },
});

export const { resetOkpdSelecteData,updateSelectedData } = okpdSelectedDataSlice.actions;
export default okpdSelectedDataSlice.reducer;
