import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedContractMonth: [],
    currentActiveButton: 'month',
    selectedTimePeriods: [],
    barLineV2Name: [],
};

const contractBarWithLineSegmentSlice = createSlice({
    name: 'contractBarWithLineSegment',
    initialState,
    reducers: {
        addBarLineV2Name: (state, action) => {
            state.barLineV2Name = [action.payload];
        },
        toggleSelectedMonthsGroup: (state, action) => {
            const months = action.payload.months;
            const allMonthsSelected = months.every(month => state.selectedContractMonth.includes(month));
            const timePeriod = action.payload.timePeriod;

            if (allMonthsSelected) {
                state.selectedContractMonth = state.selectedContractMonth.filter(month => !months.includes(month));
                if (timePeriod) {
                    state.selectedTimePeriods = state.selectedTimePeriods.filter(period => period !== timePeriod);
                }
            } else {
                months.forEach(month => {
                    if (!state.selectedContractMonth.includes(month)) {
                        state.selectedContractMonth.push(month);
                    }
                });
                if (timePeriod) {
                    state.selectedTimePeriods.push(timePeriod);
                }
            }
        },
        removeSpecificMonthV1: (state, action) => {
            state.selectedContractMonth = state.selectedContractMonth.filter(
                month => !action.payload.includes(month)
            );
        },
        clearContractSelectedMonth: (state) => {
            state.selectedContractMonth = [];
        },
        updateSelectedTimePeriodsV1: (state, action) => {
            state.selectedTimePeriods = action.payload;
        },
        clearSelectedPeriod: (state) => {
            state.selectedTimePeriods = [];
        },
        setCurrentActiveButton: (state, action) => {
            state.currentActiveButton = action.payload;
        },
        resetMonthContractStore: () => initialState
    },
});

export const {
    addBarLineV2Name,
    clearSelectedPeriod,
    updateSelectedTimePeriodsV1,
    setCurrentActiveButton,
    removeSpecificMonthV1,
    clearContractSelectedMonth,
    toggleSelectedMonthsGroup,
    resetMonthContractStore
} = contractBarWithLineSegmentSlice.actions;

export default contractBarWithLineSegmentSlice.reducer;
