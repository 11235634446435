import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {fetchIndicator} from "../../api/api";

const initialState = {
    IndicatorData: [],
    loading: 'idle',
    error: null,
};

export const fetchIndicatorData = createAsyncThunk(
    'indicator/fetchIndicatorData',
    async (options) => {
        try {
            options.newOKPDCode = options.selectedProduct.map(item => item.productId);
            const response = await fetchIndicator(options);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result;
        } catch (error) {
            throw error;
        }
    }
);


const indicatorChartSlice = createSlice({
    name: 'indicatorChart',
    initialState,
    reducers: {
        resetIndicatorData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchIndicatorData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchIndicatorData.fulfilled, (state, action) => {
                if (Array.isArray(action.payload) && action.payload.length === 0) {
                    state.loading = 'failed';
                } else {
                    state.loading = 'successful';
                    state.IndicatorData = action.payload;
                }
            })
            .addCase(fetchIndicatorData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { resetIndicatorData } = indicatorChartSlice.actions;

export default indicatorChartSlice.reducer;
