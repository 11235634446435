import menuIcon from './DotsIcon.svg';
import changeIcon from './ChangeIcon.svg';
import goInside from './moreIn.svg';
import goInsideActive from './moreInActive.svg'
import zoomin from './ZoomIn.svg'
import zoomOut from './ZoomOut.svg'

const icons = {
    menu: menuIcon,
    zoom: zoomin,
    zoomOut: zoomOut,
    change: changeIcon,
    inside: goInside,
    insideActive: goInsideActive
};

export default icons;
