import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showInnWizard: false,
    showAreaWizard: false,
    showOkpdWizard: false,
    showISOWizard: false,
    showPlacingWays: false
};

export const wizardVisibilitySlice = createSlice({
    name: 'wizardVisibility',
    initialState,
    reducers: {
        setShowInnWizard: (state, action) => {
            state.showInnWizard = action.payload;
        },
        setShowAreaWizard: (state, action) => {
            state.showAreaWizard = action.payload;
        },
        setShowOkpdWizard: (state, action) => {
            state.showOkpdWizard = action.payload;
        },
        setShowISOWizard: (state, action) => {
            state.showISOWizard = action.payload;
        },
        setShowPlacingWays: (state, action) => {
            state.showPlacingWays = action.payload;
        },
        resetWizardVisibility: () => initialState
    }
});

export const {setShowPlacingWays,resetWizardVisibility, setShowInnWizard, setShowAreaWizard, setShowOkpdWizard, setShowISOWizard } = wizardVisibilitySlice.actions;

export default wizardVisibilitySlice.reducer;
