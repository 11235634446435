
import {createSlice} from "@reduxjs/toolkit";

const searchInitialState = {
    searchData: [],
    isLoadingSearch: false,
    searchError: null,
};

const searchSlice = createSlice({
    name: 'search',
    initialState: searchInitialState,
    reducers: {
        setSearchLoading: (state, action) => {
            state.isLoadingSearch = action.payload;
        },
        setSearchData: (state, action) => {
            state.searchData = action.payload;
        },
        setSearchError: (state, action) => {
            state.searchError = action.payload;
        },
    },
});

export const { setSearchLoading, setSearchData, setSearchError } = searchSlice.actions;
export default searchSlice.reducer;
