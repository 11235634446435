import React, { useEffect, useState } from 'react';
import styles from './Modal.module.css';
import {useModal} from "../../../hook/useModal";

const Modal = ({ isVisible, onClose, children }) => {
    const [visible, setVisible] = useState(false);
    const [closing, setClosing] = useState(false);
    const { isFullScreen } = useModal();
    const { isFullScreenAll } = useModal();
    const { isCentered } = useModal();
    const { isFullCentered } = useModal();
    const maxContentWidth = isFullCentered || isFullScreen ? '100%' : '35%';

    useEffect(() => {
        if (isVisible) {
            setVisible(true);
        } else if (visible) {
            setClosing(true);
            setTimeout(() => {
                setVisible(false);
                setClosing(false);
            }, 300);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible]);

    useEffect(() => {
        if (closing) {
            setTimeout(onClose, 300);
        }
    }, [closing, onClose]);

    return (
        <div className={`${styles.modalOverlay} ${isVisible ? styles.visible : ''} ${isFullScreen || isFullScreenAll ? styles.fullScreen : ''} ${isCentered ? styles.isCentered : ''} ${isFullScreenAll ? styles.fullScreenAll : ''}`}>
            <div className={styles.modalContent}>
                <div className={styles.modalDarkArea}></div>
                <div className={`${styles.modalInfoArea} ${isVisible ? styles.visible : ''} ${isFullScreen || isFullScreenAll ? styles.fullScreenContent : ''} ${isCentered ? styles.isCenteredContent : ''}`} style={{ maxWidth: maxContentWidth, width: isFullScreenAll ? '100vw' : 'auto', height: isFullScreenAll ? '100vh' : 'auto' }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;

