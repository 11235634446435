import React from "react";
import styles from "../../components/Layout/Layout.module.css";
import CommonHeader from "../../components/Header/ComonHeader";
import HeadSearchContent from "./HeadSearchContent/HeadSearchContent";
import SearchResults from "./SearchResult/SearchResult";
import {SearchModalProvider} from "../../hook/useSearchModal";
export const TestSearch = () => {
    return(
        <div className={styles.mainContainer}>
            <SearchModalProvider>
                <CommonHeader title="Поиск Закупок"/>
                <HeadSearchContent/>
                <SearchResults/>
            </SearchModalProvider>
        </div>
    )
};
