import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchGraphSummary } from '../../../api/api';

const initialState = {
    PieData: [],
    showCount: 'sum',
    loading: 'idle',
    error: null,
};

export const fetchPieData = createAsyncThunk(
    'pieChart/fetchPieData',
    async (options, { rejectWithValue }) => {
        try {
            const newOKPDCode = options.selectedProduct.map(item => item.productId);
            const PlacingWayName = options.selectedProduct.flatMap(item => item.segments.map(segment => segment.label));
            const extendedOptions = { ...options, newOKPDCode, pieState: PlacingWayName };
            const response = await fetchGraphSummary(extendedOptions);
            if ((response.status === 204 && response.data === "") || response.data.result === null) {
                return [];
            }
            return response.data.result.nodes;
        } catch (error) {
            if (error.message === 'unauthorized') {
                return rejectWithValue({ message: 'unauthorized' });
            }
            return rejectWithValue(error.response ? error.response.data : { message: 'Unknown error' });
        }
    }
);

const pieChartSlice = createSlice({
    name: 'pieChart',
    initialState,
    reducers: {
        setShowCount: (state, action) => {
            state.showCount = action.payload;
        },
        resetPieData: (state) => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPieData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchPieData.fulfilled, (state, action) => {
                state.loading = 'successful';
                state.PieData = action.payload;
            })
            .addCase(fetchPieData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message;
            })
    },
});

export const { setShowCount,resetPieData } = pieChartSlice.actions;
export default pieChartSlice.reducer;
