import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from "./LoagindPage.module.css";

const LoadingScreen = () => {
    return (
        <div className={styles.loadingScreen}>
            <CircularProgress
                size={80}
                className={styles.customCircularProgress}
            />
            <p className={styles.loadingText}>Сейчас вы будете перенаправлены на сайт</p>
        </div>
    );
};

export default LoadingScreen;
