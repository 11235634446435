import styles from '../../SearchSettingsMain/SettingsContainer.module.css'
export const PriceInput = ({ label, value, onChange, placeholder }) => (
    <div className={styles.priceInputContainer}>
        <span className={styles.priceLabel}>{label}</span>
        <input
            type="text"
            value={value}
            onChange={onChange}
            className={styles.priceInput}
            placeholder={placeholder}
            pattern="\d*"
        />
    </div>
);
