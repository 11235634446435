import React, {useState} from 'react';
import style from '../OkpdTree/OkpdTree.module.css'
import arenasData from '../../utils/areas.json'
import AreaItem from "./AreaItem";

const AreaList = () => {
    // eslint-disable-next-line
    const [filter, setFilter] = useState('');
    const filteredData = filter
        ? arenasData.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()))
        : arenasData;

    return (
        <div className={`${style.areaContainer} ${style.okpdContainer}`}>
            <div className={style.scrollableList}>
                <ul>
                    {filteredData.map(arena => (
                        <AreaItem key={arena.name} data={arena} />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default AreaList;
