
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getUserOkpd} from "../../api/api";

const usersDataInitialState = {
    okpdUserData: [],
    isLoadingokpdUserData: false,
    okpdUserDataInfoError: null,
};

export const fetchOkpdUserData = createAsyncThunk(
    'okpdUserData/fetchOrganizationData',
    async () => {
        const response = await getUserOkpd();
        return response.result;
    }
);

const okpdUserData = createSlice({
    name: 'okpdUserData',
    initialState: usersDataInitialState,
    reducers: {
        clearOkpdUserData: (state) => {
            state.okpdUserData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOkpdUserData.pending, (state) => {
                state.isLoadingokpdUserData = 'pending';
            })
            .addCase(fetchOkpdUserData.fulfilled, (state, action) => {
                state.isLoadingokpdUserData = 'successful';
                state.okpdUserData = action.payload;
            })
            .addCase(fetchOkpdUserData.rejected, (state, action) => {
                state.isLoadingokpdUserData = 'failed';
                state.okpdUserDataInfoError = action.error.message;
            })
    },
});

export const { clearOkpdUserData } = okpdUserData.actions;

export default okpdUserData.reducer;
