import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    position: '',
    isLocked: false,
    isToggleVisible: false,
};

const searchSwitcher = createSlice({
    name: 'toggle',
    initialState,
    reducers: {
        setTogglePosition: (state, action) => {
            state.position = action.payload;
        },
        setToggleLock: (state, action) => {
            state.isLocked = action.payload;
        },
        setToggleVisibility: (state, action) => {
            state.isToggleVisible = action.payload;
        },
        clearPosition: (state, action) => {
            state.position = '';
        }
    }
});

export const {clearPosition, setToggleVisibility, setTogglePosition, setToggleLock } = searchSwitcher.actions;

export default searchSwitcher.reducer;
