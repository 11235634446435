
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchMe} from "../../api/api";

const usersDataInitialState = {
    meData: [],
    isLoadingMe: false,
    meError: null,
};

export const fetchMeData = createAsyncThunk(
    'meData/fetchMeData',
    async () => {
        const response = await fetchMe();
        return response.data;
    }
);

const meData = createSlice({
    name: 'meData',
    initialState: usersDataInitialState,
    reducers: {
        clearMe: (state) => {
            state.meData = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMeData.pending, (state) => {
                state.isLoadingMe = 'pending';
            })
            .addCase(fetchMeData.fulfilled, (state, action) => {
                state.isLoadingMe = 'successful';
                state.meData = action.payload;
            })
            .addCase(fetchMeData.rejected, (state, action) => {
                state.isLoadingMe = 'failed';
                state.meError = action.error.message;
            })
    },
});

export const { clearMe } = meData.actions;

export default meData.reducer;
