import React from 'react';
import styles from './TestTable.module.css'

const TestTable = ({ data = [], columnHeaders = [] }) => {
    return (
        <div className={styles.tableContainer}>
            <table className={styles.table}>
                <thead>
                <tr>
                    <th className={`${styles.thTd} ${styles.stickyColumn} ${styles.stickyHeader}`}>ID</th>
                    {columnHeaders.map((header, index) => (
                        <th key={index} className={`${styles.thTd} ${styles.stickyHeader}`}>{header.label}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((item) => (
                    <tr key={item.id}>
                        <td className={`${styles.thTd} ${styles.stickyColumn}`}>
                            <div className={styles.fColumnDiv}>
                                <input type="checkbox" />
                                {item.id}
                            </div>
                        </td>
                        <td className={styles.thTd}>{item.name}</td>
                        <td className={styles.thTd}>{item.sumCompleted}</td>
                        <td className={styles.thTd}>{item.sumIsp}</td>
                        <td className={styles.thTd}>{item.sumRejected}</td>
                        <td className={styles.thTd}>{item.countGK}</td>
                        <td className={styles.thTd}>{item.countGKIsp}</td>
                        <td className={styles.thTd}>{item.countGKPending}</td>
                        <td className={styles.thTd}>{item.countGKRejected}</td>
                        <td className={styles.thTd}>{item.countCustomer}</td>
                        <td className={styles.thTd}>{item.amountPenalty}</td>
                        <td className={styles.thTd}>{item.amountPennies}</td>
                        <td className={styles.thTd}>{item.countISO}</td>
                        <td className={styles.thTd}>{item.ndsAmount}</td>
                        <td className={styles.thTd}>{item.amountSubCustomer}</td>
                        <td className={styles.thTd}>{item.executability}</td>
                        <td className={styles.thTd}>{item.trustworthiness}</td>
                        <td className={styles.thTd}>{item.versatility}</td>
                        <td className={styles.thTd}>{item.locality}</td>
                        <td className={styles.thTd}>{item.eteRating}</td>
                        <td className={styles.thTd}>{item.smmToxity}</td>
                        <td className={styles.thTd}>{item.mainIndicators}</td>
                        <td className={styles.thTd}>{item.averageDecline}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default TestTable;


