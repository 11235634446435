import React from 'react';
import styles from './MasterUserPage.module.css'
import Tariffs from "../../../components/PersonalSettings/Tarrifs/Tariffs";
import PersonalInfo from "../../../components/PersonalSettings/PersonalInfo/PersonalInfo";
import PersonalSettingsContainer from "../../../components/PersonalSettings/Settings/PersonalSettingsContainer";
import {Helmet} from "react-helmet";
import {updatePageTitle} from "../../../utils/getTitleName";
const PersonalSettings = () => {

    return (
        <div>
            <Helmet>
                <title className={styles.personalHeader}>{updatePageTitle('Личный кабинет')}</title>
            </Helmet>
            <div className={styles.settingsGrid}>
                <div className={styles.tariffs}>
                    <Tariffs/>
                </div>
                <div className={styles.info}>
                    <PersonalInfo/>
                </div>
                <div className={styles.settings}>
                    <PersonalSettingsContainer/>
                </div>
            </div>
        </div>

    );
}

export default PersonalSettings;

