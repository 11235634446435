export const formatNumber = (num) => {
    if (num !== undefined) {
        if (num >= 1000000000) {
            return (num / 1000000000).toFixed(0) + ' млрд';
        } else if (num >= 1000000) {
            return (num / 1000000).toFixed(0) + ' млн';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(0) + ' тыс';
        } else {
            return num.toString();
        }
    } else {
        return '';
    }
}

export const formatNumberWithDecimal = (num) => {
    const format = (value, label) => {
        const formattedValue = Number.isInteger(value) ? value.toFixed(0) : value.toFixed(1);
        return `${formattedValue} ${label}`;
    };

    if (num === 0) {
        return "0";
    } else if (num >= 1000000000) {
        return format(num / 1000000000, 'млрд');
    } else if (num >= 1000000) {
        return format(num / 1000000, 'млн');
    } else if (num >= 1000) {
        return format(num / 1000, 'тыс');
    } else {
        return Number.isInteger(num) ? num.toFixed(0) : num.toFixed(1);
    }
};

