import styles from '../../SearchSettingsMain/SettingsContainer.module.css'
import {CustomCheckbox} from "../../../../components/OkpdTree/RecurseTree/testTree";
export const CheckboxGroup = ({ options }) => {
    return (
        <>
            {options.map((option) => (
                <div key={option.key} className={styles.checkBoxContent}>
                    <CustomCheckbox
                        checked={option.checked}
                        onChange={() => option.onChange()}
                    />
                    <span>{option.label}</span>
                </div>
            ))}
        </>
    );
};
