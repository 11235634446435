import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from '../../../service/reducers/SwitcherSlice';
import styles from './SwitcherCircle.module.css';
import moonIcon from "../../../common/icons/moon.svg";
import sunIcon from "../../../common/icons/sundark.svg";
import {useModal} from "../../../hook/useModal";

const SwitcherCircle = (showSwitcherCircle) => {
    const active = useSelector(state => state.switcher.activeSwitch);
    const dispatch = useDispatch();
    const { isModalVisible } = useModal();
    const handleTabChange = () => {
        const newTab = active === 'sun' ? 'moon' : 'sun';
        dispatch(setActiveTab(newTab));
    };

    if (isModalVisible) {
        return null;
    }
    const icon = active === 'sun' ? moonIcon : sunIcon;
    const altText = active === 'sun' ? "Moon" : "Sun";
    const switcherClass = showSwitcherCircle ?  styles.switcherCircleEnterActive : styles.switcherCircleEnter;

    return (
        <div className={styles.switcherContainer} style={{switcherClass}}>
            <div className={styles.innerContainer}>
                { !isModalVisible && (
                    <div
                        onClick={handleTabChange}
                        className={`${styles.switcherItem} ${active === 'sun' ? styles.active : ''}`}
                    >
                        <img src={icon} alt={altText} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default SwitcherCircle;
