import React from 'react';
import styles from './Circle.module.css';
const CircleWithLabel = ({ label, filled, onClick }) => {
    return (
        <div className={styles.circleLabelContainer} onClick={onClick}>
            <div className={`${styles.circle} ${filled ? styles.filled : ''}`}></div>
            <span className={styles.label}>{label}</span>
        </div>
    );
};

const CircleSelector = ({ selected, setSelected }) => {
    const handleClick = (type) => () => {
        setSelected(type);

    };
    return (
        <>
            <div className={styles.circleContainer}>
                <CircleWithLabel label="Юридическое лицо" filled={selected === 'legal'} onClick={handleClick('legal')} />
                <CircleWithLabel label="ИП" filled={selected === 'individual'} onClick={handleClick('individual')} />
                <CircleWithLabel label="Физическое лицо" filled={selected === 'phiz'} onClick={handleClick('phiz')} />
                <CircleWithLabel label="Государственное учреждение" filled={selected === 'gov'} onClick={handleClick('gov')} />
            </div></>
    );
};

export default CircleSelector;
