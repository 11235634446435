import React from 'react'
import style from './CustomInpurField.module.css'
const InputField = ({ type, placeholder, name, value, onChange, onBlur, className, isError }) => {
    const errorClass = isError ? style.inputError : ''
    const combinedClassName = `${className} ${errorClass}`

    return (
        <input
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className={combinedClassName}
            onBlur={onBlur}
        />
    )
}

export default InputField
